import React, { useState } from "react";

import styles from "./CreateSubscriptionForm.module.css";
import { useMutation } from "@tanstack/react-query";
import { axiosConfig } from "../../../../axios/axiosConfig";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";

const CreateSubscriptionForm = ({
  formType = "",
  activePlan,
  setAllPlanDetails,
  setOpenSubscriptionForm,
}) => {
  const { user } = useSelector((state) => state.auth);
  const USERID = user?.userDetails?.id;

  const initialPlanInputState = {
    name: formType === "edit" ? activePlan?.name : "",
    code: formType === "edit" ? activePlan?.plan_code : "",
    description: formType === "edit" ? activePlan?.description : "",
    features: formType === "edit" ? activePlan?.plan_description : "",
    price: formType === "edit" ? activePlan?.price : "",
    alertCount: formType === "edit" ? activePlan?.alert : "",
    interval: formType === "edit" ? activePlan?.interval : "",
    isPlanActive: formType === "edit" ? activePlan?.active : true,
    intervalUnit: "months",
  };

  // planInput state will be initialized with activePlan if "editing" or updating subscription form
  const [planInput, setPlanInput] = useState(initialPlanInputState);

  const {
    name,
    code,
    description,
    features,
    price,
    alertCount,
    interval,
    intervalUnit,
  } = planInput;

  // onchange handler for all inputs
  const inputOnChange = (event) => {
    setPlanInput((prevState) => {
      if (event.target.name === "isPlanActive") {
        return {
          ...prevState,
          [event.target.name]: event.target.checked,
        };
      }
      return {
        ...prevState,
        [event.target.name]: event.target.value,
      };
    });
  };

  const METHOD_TYPE = formType === "edit" ? "patch" : "post";

  // CREATE NEW or update subscription plan
  const postSubPlan = useMutation({
    mutationFn: async () =>
      mutatePlan(METHOD_TYPE, planInput, USERID, activePlan?.id),
    onSuccess: (data) => {
      // console.log("plans data: ", data);
      if (formType === "edit") {
        setAllPlanDetails((prevPlans) => {
          return prevPlans.map((plan) => {
            if (plan.id === activePlan?.id) {
              return data.response;
            } else {
              return plan;
            }
          });
        });
      } else if (formType === "create") {
        setAllPlanDetails((prevPlans) => {
          return [...prevPlans, data.response];
        });
      }

      // reseting planInputs to null
      setPlanInput(initialPlanInputState);

      window.alert("Plan updated/created successfully!");
    },
    onError: (error) => {
      toast.error(error.message || "Something went wrong");
    },
  });

  // HANDLE CREATE / POST NEW MARQUEE
  const handleMutatePlan = (event) => {
    // event.preventDefault();

    const consent = window.confirm(
      `*** PLAN SUMMARY! ***\nPLAN NAME: ${name} | PLAN CODE: ${code}\nFEATURES: ${features}\nPRICE: ${price} | ALERTS: ${alertCount} | PLAN PERIOD: ${interval} ${intervalUnit}\nAre you sure you want to ${formType} Plan?`
    );

    if (!consent) {
      return;
    }

    postSubPlan.mutate();
    setOpenSubscriptionForm(false);
  };

  // reset button
  const handleReset = (event) => {
    event.preventDefault();
    setPlanInput(initialPlanInputState);
  }

  const disableCreateBtn =
    !name ||
    name.trim() === "" ||
    !code ||
    code.trim() === "" ||
    !description ||
    description.trim() === "" ||
    !features ||
    features.trim() === "" ||
    !price ||
    price.trim() === "" ||
    !interval ||
    !intervalUnit ||
    intervalUnit.trim() === "" ||
    !alertCount ||
    +alertCount < 1;

  return (
    <form action="" className={styles.subscriptionFormContainer}>
      <h2 className={styles.formTitle}>
        {formType === "edit" ? "Update Plan" : "Create Plan"}
      </h2>

      <div className={styles.formGroup}>
        <label htmlFor="name">Plan Name</label>
        <input
          type="text"
          className={styles.formControl}
          id="name"
          name="name"
          value={planInput.name}
          onChange={inputOnChange}
        />
      </div>

      <div className={styles.formGroup}>
        <label htmlFor="code">Plan Code</label>
        <input
          disabled={formType === "edit"}
          type="text"
          placeholder="Eg. PREMIUM349912M"
          className={styles.formControl}
          id="code"
          name="code"
          value={planInput.code}
          onChange={inputOnChange}
        />
      </div>
      <div className={styles.formGroup}>
        <label htmlFor="description">Description</label>
        <input
          type="text"
          className={styles.formControl}
          id="description"
          name="description"
          value={planInput.description}
          onChange={inputOnChange}
        />
      </div>

      {/* Plan Features/Description (plan_description) */}
      <div className={styles.formGroup}>
        <label htmlFor="features">Features</label>
        <input
          type="text"
          placeholder="feature1, feature2, feature3"
          className={styles.formControl}
          id="features"
          name="features"
          value={planInput.features}
          onChange={inputOnChange}
        />
      </div>
      <div className={styles.formGroup}>
        <label htmlFor="price">Price</label>
        <input
          type="number"
          className={styles.formControl}
          id="price"
          name="price"
          value={planInput.price}
          onChange={inputOnChange}
          placeholder="Enter subscription amount in rupees"
        />
      </div>
      <div className={styles.formGroup}>
        <label htmlFor="interval">Interval</label>
        <input
          type="number"
          min="1"
          max="12"
          placeholder="Number of months, years, etc."
          className={styles.formControl}
          id="interval"
          name="interval"
          value={planInput.interval}
          onChange={inputOnChange}
        />
      </div>

      {/* Interval Units */}
      <div className={styles.formGroup}>
        <label htmlFor="intervalUnit">Interval Unit</label>
        <select
          // className={styles.selectScreenInput}
          className={styles.formControl}
          id="intervalUnit"
          name="intervalUnit"
          value={planInput.intervalUnit}
          onChange={inputOnChange}
        >
          {intervalOptions.map((item) => {
            return (
              <option key={item.value} value={item.value}>
                {item.label}
              </option>
            );
          })}
        </select>
      </div>

      {/* ALERT COUNT */}
      <div className={styles.formGroup}>
        <label htmlFor="alertCount">Alert Count</label>
        <input
          type="number"
          min="1"
          max="5000"
          placeholder="Number of Alerts"
          className={styles.formControl}
          id="alertCount"
          name="alertCount"
          value={planInput.alertCount}
          onChange={inputOnChange}
        />
      </div>

      {/* PLAN ACTIVE? */}
      <div className={styles.formGroup}>
        <label htmlFor="isPlanActive">Active?</label>
        <input
          type="checkbox"
          name="isPlanActive"
          id="isPlanActive"
          // className={styles.formControl}
          value={planInput.isPlanActive}
          onChange={inputOnChange}
          checked={planInput.isPlanActive}
        />
      </div>

      {/* reset button */}
      <div className={styles.resetBtnContainer}>
        <button className={styles.resetBtn} onClick={handleReset} >clear</button>
      </div>

      {/* button container */}
      <div className={styles.btnContainer}>
        {formType === "edit" ? (
          <button
            // disabled={disableCreateBtn}
            // className="btn editPlanBtn"
            className={`${styles.btn} ${styles.editPlanBtn}`}
            onClick={handleMutatePlan}
          >
            update
          </button>
        ) : (
          <button
            type="submit"
            disabled={disableCreateBtn}
            // className="btn createPlanBtn"
            className={`${styles.btn} ${styles.createPlanBtn}`}
            onClick={handleMutatePlan}
          >
            Create Plan
          </button>
        )}
      </div>
    </form>
  );
};

export default CreateSubscriptionForm;

const intervalOptions = [
  { id: 1, value: "years", label: "years" },
  { id: 2, value: "months", label: "months" },
  { id: 3, value: "weeks", label: "weeks" },
  // { id: 4, value: "days", label: "days" },
];

// MUTATE (CREATE & UPDATE) subscription plan
export const mutatePlan = async (method, plan_input, USERID, plan_id) => {
  if (!USERID) return;
  const {
    name,
    code,
    description,
    features,
    price,
    interval,
    intervalUnit,
    alertCount,
    isPlanActive,
  } = plan_input;

  let apiURL = "/subscription/subscription_plan"; // for post method only
  apiURL =
    method === "patch"
      ? `/subscription/subscription_plan?plan_id=${plan_id}`
      : apiURL;

  const res = await axiosConfig({
    method: method,
    url: apiURL,
    data: {
      userid: USERID,
      name: name,
      plan_code: code,
      description: description,
      plan_description: features,
      price: price,
      interval: interval,
      interval_unit: intervalUnit,
      alert: alertCount,
      active: isPlanActive,
    },
  });
  return res.data;
};
