import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box, Stack } from "@mui/material";
import dateFormat, { masks } from "dateformat";

import NorthIcon from "@mui/icons-material/North";
import SouthIcon from "@mui/icons-material/South";

// import styles from "./HistoricalRecommendationTable.module.css";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    padding: "0.5rem",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: "0.5rem",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function HistoricalRecommendationTable({ rows, symbol }) {
  const [recomData, setRecomData] = useState(rows);
  const [activeCall, setActiveCall] = useState("buy");

  const buyCallsData = rows
    .filter((item) => item.recommendation === "Buy")
    .map((item) => {
      return {
        buyprice: item.buyprice,
        buy_date: item.date,
        high: item.high,
        buy_pct: item.pct,
        buy_recommendation: item.recommendation,
      };
    });
  const sellCallsData = rows
    .filter((item) => item.recommendation === "Sell")
    .map((item) => {
      return {
        sellprice: item.sellprice,
        sell_date: item.date,
        low: item.low,
        sell_pct: item.pct,
        sell_recommendation: item.recommendation,
      };
    });

  const buyCallsArray = [];
  const sellCallsArray = [];

  // useEffect to change/filter sells/buys calls
  useEffect(() => {
    const filterCalls = () => {
      if (activeCall === "buy") {
        for (let i = 0; i < rows.length / 2; i++) {
          buyCallsArray.push({ ...buyCallsData[i], ...sellCallsData[i] });
        }
        setRecomData(buyCallsArray);
      } else if (activeCall === "sell") {
        for (let i = 0; i < rows.length / 2; i++) {
          sellCallsArray.push({ ...sellCallsData[i], ...buyCallsData[i] });
        }
        setRecomData(sellCallsArray);
      }
    };

    filterCalls();
  }, [activeCall, rows.length]);

  return (
    <>
      {/* BUY AND SELL CALLS BUTTON */}
      {/* <div className={styles.calls_btn_container}>
        <div
          className={styles.buy_calls_btn}
          style={{
            backgroundColor: activeCall === "buy" ? "#339502" : "",
            border: activeCall === "buy" ? "2px solid orange" : "",
          }}
          onClick={() => setActiveCall("buy")}
        >
          BUY CALLS
        </div>
        <div
          className={styles.sell_calls_btn}
          style={{
            backgroundColor: activeCall === "sell" ? "#339502" : "",
            border: activeCall === "sell" ? "2px solid orange" : "",
          }}
          onClick={() => setActiveCall("sell")}
        >
          SELL CALLS
        </div>
      </div> */}

      <TableContainer
        component={Paper}
        sx={{ minWidth: 850, maxWidth: 1100, mb: 8 }}
      >
        <Table
          sx={{ minWidth: 850, maxWidth: 1100, p: 4 }}
          aria-label="customized table"
        >
          <TableHead>
            <TableRow>
              <StyledTableCell align="center">
                <span>RECOMMENDATION</span>
              </StyledTableCell>

              <StyledTableCell align="right">
                <Stack direction="column">
                  <span>PRICE</span>
                  <span>DATE</span>
                </Stack>
              </StyledTableCell>

              <StyledTableCell align="right">
                <Stack direction="column">
                  <span>HIGH / LOW</span>
                </Stack>
              </StyledTableCell>

              <StyledTableCell align="right">
                <Stack direction="column">
                  <span>GAIN / SAVE %</span>
                </Stack>
              </StyledTableCell>

              <StyledTableCell align="center">
                <span>RECOMMENDATION</span>
              </StyledTableCell>

              <StyledTableCell align="right">
                <Stack direction="column">
                  <p>PRICE</p>
                  <p>DATE</p>
                </Stack>
              </StyledTableCell>

              <StyledTableCell align="right">
                <Stack direction="column">
                  <span>HIGH / LOW</span>
                </Stack>
              </StyledTableCell>

              <StyledTableCell align="right">
                <Stack direction="column">
                  <span>GAIN / SAVE %</span>
                </Stack>
              </StyledTableCell>

              <StyledTableCell>
                <Stack direction="column">
                  <span>% CHANGE</span>
                </Stack>
              </StyledTableCell>
            </TableRow>
          </TableHead>

          {/* TABLE BODY HERE */}
          <TableBody>
            {rows.map((row, index) => (
              <StyledTableRow key={index}>
                {/* OBJECT2 COLUMNS STARTS HERE */}
                <StyledTableCell align="center" component="th" scope="row">
                  <Stack>
                    <Box
                      sx={{
                        fontWeight: "bold",
                        color: row?.object2?.color,
                      }}
                    >
                      <span>{row?.object2?.recommendation.toUpperCase()}</span>
                    </Box>
                  </Stack>
                </StyledTableCell>

                {/* OBJECT2 - PRICE & DATE */}
                <StyledTableCell align="right">
                  <Box
                    sx={{
                      fontWeight: "bold",
                      // fontSize: "12px",
                      color:
                        row?.object2?.recommendation === "Buy"
                          ? "rgb(3, 166, 109)"
                          : "rgb(207, 48, 74)",
                    }}
                  >
                    <span>
                      {row?.object2?.buyprice || row?.object2?.sellprice}
                    </span>
                  </Box>
                  <span style={{ fontSize: "12px", fontWeight: "bold" }}>
                    {dateFormat(row?.object2?.date, masks.mediumDate)}
                  </span>
                </StyledTableCell>

                {/* OBJECT2 HIGH / LOW */}
                <StyledTableCell align="right">
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                      gap: "2px",
                    }}
                  >
                    <span>
                      {row?.object2?.recommendation === "Buy" ? (
                        <NorthIcon color="success" fontSize="14px" />
                      ) : (
                        <SouthIcon color="error" fontSize="14px" />
                      )}
                    </span>
                    <span style={{ fontWeight: "bold" }}>
                      {row?.object2?.high || row?.object2?.low}
                    </span>
                  </Box>
                </StyledTableCell>

                {/* OBJECT2 - HIGH/LOW % GAIN/SAVE */}
                <StyledTableCell align="right">
                  <span>
                    {row?.object2?.recommendation === "Buy" ? (
                      <NorthIcon color="success" fontSize="14px" />
                    ) : (
                      <SouthIcon color="error" fontSize="14px" />
                    )}
                  </span>
                  <span style={{ fontWeight: "bold" }}>
                    {row?.object2?.pct}
                  </span>
                </StyledTableCell>

                {/* OBJECT1 COLUMNS STARTS HERE */}
                {/* OBJECT1 - RECOMMENDATION */}
                <StyledTableCell align="center">
                  <Stack>
                    <Box
                      sx={{
                        fontWeight: "bold",
                        color: row?.object1?.color,
                        // borderLeft: '1px solid'
                      }}
                    >
                      <span>{row?.object1?.recommendation.toUpperCase()}</span>
                    </Box>
                  </Stack>
                </StyledTableCell>

                {/* OBJECT1 - PRICE/DATE */}
                <StyledTableCell
                  cellcolor="palette.secondary.light"
                  align="right"
                >
                  <Box
                    sx={{
                      fontWeight: "bold",
                      color:
                        row?.object1?.recommendation === "Buy"
                          ? "rgb(3, 166, 109)"
                          : "rgb(207, 48, 74)",
                    }}
                  >
                    <span>
                      {row?.object1?.buyprice || row?.object1?.sellprice}
                    </span>
                  </Box>
                  <span style={{ fontSize: "12px", fontWeight: "bold" }}>
                    {dateFormat(row?.object1?.date, masks.mediumDate)}
                  </span>
                  {/* <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <span
                      style={{
                        // backgroundColor: row?.color,
                        color: "rgb(3, 166, 109)",
                        fontSize: "12px",
                        fontWeight: "bold",
                      }}
                    >
                      {activeCall === "buy" ? (
                        <NorthIcon color="success" fontSize="14px" />
                      ) : (
                        <SouthIcon color="error" fontSize="14px" />
                      )}
                      
                      {activeCall === "buy" ? row?.buy_pct : row?.sell_pct}
                    </span>
                  </div> */}
                </StyledTableCell>

                {/* OBJECT1 - HIGH/LOW */}
                <StyledTableCell align="right">
                  <span>
                    {row?.object1?.recommendation === "Buy" ? (
                      <NorthIcon color="success" fontSize="14px" />
                    ) : (
                      <SouthIcon color="error" fontSize="14px" />
                    )}
                  </span>
                  <span style={{ fontWeight: "bold" }}>
                    {row?.object1?.high || row?.object1?.low}
                  </span>
                </StyledTableCell>

                {/* OBJECT1 - HIGH/LOW % GAIN/SAVE */}
                <StyledTableCell align="right">
                  <span>
                    {row?.object1?.recommendation === "Buy" ? (
                      <NorthIcon color="success" fontSize="inherit" />
                    ) : (
                      <SouthIcon color="error" fontSize="inherit" />
                    )}
                  </span>
                  <span style={{ fontWeight: "bold" }}>
                    {row?.object1?.pct}
                  </span>
                </StyledTableCell>

                {/* PERCENTAGE CHANGE - MIDDLE COLUMN */}
                <StyledTableCell
                  align="center"
                  sx={{ backgroundColor: "#0FFF50" }}
                >
                  <span style={{ fontWeight: "bold", color: "#333" }}>
                    {row?.pcnt_chang.toFixed(2)}%
                  </span>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
