import {
  Stack,
  Typography,
  Box,
  Button,
  CircularProgress,
} from "@mui/material";
import React, { useState } from "react";
// import MuiAutocomplete from "../../../components/muiComps/MuiAutocomplete";

import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Chip from "@mui/material/Chip";
import LinearProgress from "@mui/material/LinearProgress";

import axiosConfig from "../../../axios/axiosConfig";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { fetchSymbolsArray } from "../../../api/optionChain";
import MuiAlertToast from "../../../components/muiComps/MuiAlertToast";
import { toast } from "react-hot-toast";
import ReactHotToaster from "../../../components/common/ReactHotToaster";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    fontSize: 14,
    padding: "4px 6px",
    border: "1px solid",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    color: "#555",
    padding: "4px 6px",
    // margin: 0
    border: "1px solid #ccc",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    // backgroundColor: theme.palette.action.hover,
    backgroundColor: "#FFF5EE",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    // border: 0,
  },
}));

const columns = [
  // CALLS PART
  {
    id: "open_interest",
    uniqueID: 1,
    label: "OI",
    description: "Open Interest",
    align: "center",
    // minWidth: 50,
    optionType: "calls",
  },
  {
    id: "previous_open_interest",
    uniqueID: 2,
    label: "Previous OI",
    description: "Previous Open Interest",
    align: "center",
    // minWidth: 50,
    optionType: "calls",
  },

  {
    id: "ltp",
    uniqueID: 3,
    label: "LTP",
    // minWidth: 50,
    description: "Last Price",
    align: "center",
    optionType: "calls",
    // format: (value) => value.toFixed(2),
  },
  {
    id: "striker_price",
    uniqueID: 4,
    label: "Strike",
    // minWidth: 50,
    description: "Strike Price",
    align: "center",
    optionType: "calls",
    // format: (value) => value.toFixed(2),
  },
  {
    id: "volume",
    uniqueID: 5,
    label: "VOL",
    // minWidth: 50,
    description: "Volume",
    align: "center",
    optionType: "calls",
    // format: (value) => value.toFixed(2),
  },
  {
    id: "chart",
    uniqueID: 6,
    label: "CHART",
    // minWidth: 50,
    description: "Chart",
    align: "center",
    optionType: "calls",
  },

  // STIKE PRICE AND EXPIRY DATE
  {
    id: "open_interest",
    uniqueID: 7,
    label: "OI",
    description: "Open Interest",
    align: "center",
    // minWidth: 50,
    optionType: "puts",
  },
  {
    id: "previous_open_interest",
    uniqueID: 8,
    label: "Previous OI",
    description: "Previous Open Interest",
    align: "center",
    // minWidth: 50,
    optionType: "puts",
  },

  {
    id: "ltp",
    uniqueID: 9,
    label: "LTP",
    // minWidth: 50,
    description: "Last Price",
    align: "center",
    optionType: "puts",
    // format: (value) => value.toFixed(2),
  },
  {
    id: "striker_price",
    uniqueID: 10,
    label: "Strike",
    // minWidth: 50,
    description: "Strike Price",
    align: "center",
    optionType: "puts",
    // format: (value) => value.toFixed(2),
  },
  {
    id: "volume",
    uniqueID: 11,
    label: "VOL",
    // minWidth: 50,
    description: "Volume",
    align: "center",
    optionType: "puts",
    // format: (value) => value.toFixed(2),
  },
  {
    id: "chart",
    uniqueID: 12,
    label: "CHART",
    // minWidth: 50,
    description: "Chart",
    align: "center",
    optionType: "puts",
  },
];

// const CHART_BASE_URL =
//   process.env.NODE_ENV === "development"
//     ? "http://localhost:3001/charts"
//     : "https://tnibro.com/charts";



const SingleOptionChainItem = () => {
  const navigate = useNavigate();
  const indicesList = ["NIFTY", "FINNIFTY", "BANKNIFTY"];
  const [symbolsListOptions, setSymbolsListOptions] = useState([]);

  // Mui Toast Alert Notification
  const [showAlert, setShowAlert] = useState({
    isVisible: false,
    msg: "",
    severity: "",
  });

  // all options chain data including symbols, nifty, expirydates and strikePrices
  // const [allOptionsData, setAllOptionsData] = useState({});
  // const expiryDatesList = allOptionsData?.records?.expiryDates || [];
  // const intStrikePricesList = allOptionsData?.records?.strikePrices || []; // Integer price List
  // const strikePricesList = intStrikePricesList.map((item) => item.toString()); // converted to string array

  // NEW STATES AFTER CHANGES IN OPTIONCHAIN API
  const [expiryDatesList, setExpiryDatesList] = useState([]);
  const [topStrikerPrices, setTopStrikerPrices] = useState([]);
  const [loading, setLoading] = useState(false);
  const [recomData, setRecomData] = useState({
    color: "gray",
    recommendation: "",
  });

  // Data after filtering by 'expiry Date' or 'strike Price'. This state should be used to map Table Rows.
  // 'allOptionsData' is the source for filtering. To be used for Table Rows Data
  const [filteredData, setFilteredData] = useState([]);

  // const [searchParams] = useSearchParams();
  // const querySymbol = searchParams.get("symbol");

  const { option_symbol } = useParams();
  const formatted_symbol = option_symbol.trim().toUpperCase();
  const isOptionIndex = indicesList.includes(formatted_symbol);

  const initialSelectedOptions = {
    // stockIndex: !querySymbol ? "NIFTY" : "",
    stockIndex: isOptionIndex ? formatted_symbol : "",
    selectedSymbol: !isOptionIndex ? formatted_symbol : "",
    selectedExpiryDate: "",
    selectedStrikePrice: "",
  };

  const [selectedOptions, setSelectedOptions] = useState(
    initialSelectedOptions
  );
  const {
    stockIndex,
    selectedSymbol,
    selectedExpiryDate,
    selectedStrikePrice,
  } = selectedOptions;

  // Filtering Table Headers Array (to choose b/w Strike Price and Expiry Date)
  const filteredColumnHeaders = columns.filter((col) => {
    // if selectedExpiryDate is Truthy
    if (!!selectedExpiryDate || selectedExpiryDate !== "") {
      return col.id !== "expiryDate";
    }

    // if selectedStrikePrice is Truthy
    if (!!selectedStrikePrice || selectedStrikePrice !== "") {
      return col.id !== "strikePrice";
    }

    return col;
  });

  // SORTING FILTERDATA ARRAY BY DATE
  // const sortedFilteredData = filteredData.sort(function compare(a, b) {
  //   const dateA = new Date(a.expiryDate);
  //   const dateB = new Date(b.expiryDate);
  //   return dateA - dateB;
  // });

  // useQuery to fetch Symbols Array for Autocomplete List, (symbols list might be different on different days)
  const optionSymbolsQuery = useQuery({
    queryKey: ["option-symbols"],
    queryFn: () => fetchSymbolsArray(),
    onSuccess: (data) => {
      // getting Array of objects from backend, so mapped to list of symbols Array
      const tempSymbolsArray = data.response.map((item) => item.name);
      setSymbolsListOptions(tempSymbolsArray);
      // setShowAlert({ isVisible: true, msg: "Symbols fetched!!", severity: "success"})
    },
    onError: (error) => {
      toast.error(error.response?.data.response || "Something went wrong");
    },
  });

  // NOT USING USEQUERY HERE, AS NEED TO FETCH EVERYTIME selectedSymbol or stockIndex is changed.

  const symbolsDataQuery = useQuery({
    retry: false,
    refetchOnWindowFocus: false, // Prevent refetch on window focus
    refetchInterval: false, // Disable automatic refetching
    queryKey: ["option-symbols-data", selectedSymbol, stockIndex],
    queryFn: () => fetchSymbolsData(selectedSymbol, stockIndex),
    onSuccess: (data) => {
      // data.response is Expiry Dates Array, send specific Expiry Date again
      // to get data for that expiry date
      setExpiryDatesList(data.response);

          // INITIALISE STATES FROM DATA RETURNED
          const INITIAL_EXPIRY_DATE = data.response[0];
          setSelectedOptions((prev) => {
            return { ...prev, selectedExpiryDate: INITIAL_EXPIRY_DATE };
          });

          fetchSymbolDatabyExpiryDate(INITIAL_EXPIRY_DATE);
    },
    onError: (error) => {
      toast.error(error?.response?.data?.response || "Something went wrong");
    },
  })


  // Fetching Symbols data / Nifty data
  // VERY IMPORTANT FUNCTION, FETCHING initial data ie stocks data, initial expiry data.
  // useEffect(() => {
  //   const fetchSymbolsData = async () => {
  //     if (selectedSymbol === "" && stockIndex === "") {
  //       return;
  //     }

  //     setLoading(true);
  //     try {
  //       const res = await axiosConfig({
  //         method: "post",
  //         url: "/optionchain/expirydate",
  //         data: { symbol: selectedSymbol || stockIndex },
  //       });

  //       // res.data.response is Expiry Dates Array, send specific Expiry Date again
  //       // to get data for that expiry date
  //       if (res.statusText === "OK" || res.status === 200) {
  //         setExpiryDatesList(res.data.response);

  //         // INITIALISE STATES FROM DATA RETURNED
  //         const INITIAL_EXPIRY_DATE = res.data.response[0];
  //         setSelectedOptions((prev) => {
  //           return { ...prev, selectedExpiryDate: INITIAL_EXPIRY_DATE };
  //         });

  //         fetchSymbolDatabyExpiryDate(INITIAL_EXPIRY_DATE);
  //       }
  //     } catch (error) {
  //       console.log(error);
  //       toast.error(error.response.data.response || "Something went wrong");
  //     } finally {
  //       setLoading(false);
  //     }
  //   };

  //   fetchSymbolsData();
  // }, [selectedSymbol, stockIndex]);

  // FETCH SYMBOLS DATA BY EXPIRY DATE
  async function fetchSymbolDatabyExpiryDate(expiry_date) {
    if (!expiry_date || expiry_date === "") {
      toast.error("Expiry date can't be null, No Data Available!");
      return;
    }

    setLoading(true);
    try {
      const expiryDateRes = await axiosConfig({
        method: "post",
        url: "/optionchain",
        data: {
          symbol: selectedSymbol || stockIndex,
          expirytime: expiry_date,
        },
      });

      if (expiryDateRes.status === 200 || expiryDateRes.statusText === "OK") {
        const top_striker_prices = expiryDateRes.data.response?.striker_price_gain ? JSON.parse(
          expiryDateRes.data.response?.striker_price_gain
        ) : null;
        // if top_striker_prices is not undefined (top striker prices not available for indices like NIFTY, BANKNIFTY, etc.)
        if (top_striker_prices) {
          setTopStrikerPrices(top_striker_prices);
        }
        setFilteredData(expiryDateRes.data.response.option_data);
        setRecomData(expiryDateRes.data.response?.recommendation);
      }
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.response || "Something went wrong");
    } finally {
      setLoading(false);
    }
  }

  // onChange Handler for Expiry Dates
  const expiryDateOnChange = (event, newValue) => {
    setSelectedOptions((prev) => {
      return {
        ...prev,
        selectedStrikePrice: "",
        selectedExpiryDate: newValue,
      };
    });

    // NEW CODE AFTER OPTIONCHAIN API CHANGE
    fetchSymbolDatabyExpiryDate(newValue);

    // Filtering according to 'Expiry Dates'
    // const newFilterData = allOptionsData?.records.data.filter((dataItem) => {
    //   return dataItem.expiryDate === newValue;
    // });
    // setFilteredData(newFilterData);
  };

  // onChange Handler for Expiry Dates
  const strikePriceOnChange = (event, newValue) => {
    setSelectedOptions((prev) => {
      return {
        ...prev,
        selectedStrikePrice: newValue,
        selectedExpiryDate: "",
      };
    });

    // Filtering according to 'Expiry Dates'
    // const newFilterData = allOptionsData?.records.data.filter((dataItem) => {
    //   return dataItem.strikePrice === +newValue; // converted newValue to Int
    // });
    // setFilteredData(newFilterData);
  };

  return (
    <div>
      <h1>Option Chain</h1>

      {/* AUTOCOMPLETE COMP CONTAINER */}
      <Stack direction="row" spacing={2} sx={{ m: "2rem 0" }}>
        {/* COMMENTED OUT AS DATA NOT AVAILABLE */}
        <CustomMuiAutocomplete
          options={indicesList}
          label="NIFTY, BANKNIFTY"
          stateName="stockIndex"
          value={stockIndex}
          onChange={(event, newValue) => {
            setFilteredData([]);
            setSelectedOptions((prev) => {
              return {
                stockIndex: newValue,
                selectedSymbol: "",
                selectedExpiryDate: "",
                selectedStrikePrice: "",
              };
            });

            navigate(`/nse-option-chain/${newValue}`);
          }}
        />

        <Typography variant="h6">OR</Typography>

        <CustomMuiAutocomplete
          options={symbolsListOptions}
          label="Select Symbol"
          stateName="selectedSymbol"
          value={selectedSymbol}
          onChange={(event, newValue) => {
            setFilteredData([]);
            setSelectedOptions((prev) => {
              return {
                selectedSymbol: newValue,
                stockIndex: "",
                selectedExpiryDate: "",
                selectedStrikePrice: "",
              };
            });

            navigate(`/nse-option-chain/${newValue}`)
          }}
        />

        {/* EXPIRY DATE AND STRIKE PRICE */}
        {/* expiryDatesList?.length !== 0 */}
        {!optionSymbolsQuery.isLoading ? (
          <>
            <CustomMuiAutocomplete
              options={expiryDatesList}
              // options={['one', 'two']}
              label="Expiry Date"
              stateName="selectedExpiryDate"
              defaultValue={expiryDatesList[0]}
              value={selectedExpiryDate}
              onChange={expiryDateOnChange}
            />

            {/* DISABLED AND COMMENTED OUT STRIKE PRICE AUTOLIST FOR NOW */}
            {/* <Typography variant="h6">OR</Typography> */}

            {/* <CustomMuiAutocomplete
              options={strikePricesList}
              // options={['three', 'two']}
              label="Strike Price"
              stateName="selectedStrikePrice"
              value={selectedStrikePrice}
              onChange={strikePriceOnChange}
            /> */}
          </>
        ) : null}

        {/* BUY CALLS - BUY PUTS */}
        {recomData?.color === "" ? (
          <CircularProgress />
        ) : (
          <Box
            variant="contained"
            size="small"
            // color={recomData.color === "green" ? "success" : "error"}
            sx={{
              cursor: "default",
              backgroundColor: recomData?.color,
              padding: 1,
              borderRadius: 2,
            }}
          >
            <Typography sx={{
              color: "#fff",
              fontSize: 14,
              fontWeight: 200,
            }} >
              BUY {recomData?.color === "green" ? "CALLS" : "PUTS"}
            </Typography>
            
          </Box>
        )}

        
      </Stack>

      <Link to='/nse-option-chain'>
      <Button size="small" variant="contained" sx={{
        mb: 2,
        color: 'white'
      }}>
        back
      </Button>
      </Link>

      {/* THE TABLE */}
      {symbolsDataQuery?.isLoading ? (
        <Stack spacing={2} sx={{ flex: 1, m: 4 }}>
          <LinearProgress size="sm" />
          <LinearProgress size="sm" />
          <LinearProgress size="sm" />
        </Stack>
      ) : (
        <TableContainer component={Paper} sx={{ maxWidth: 1000, mb: 2 }}>
          <Table
            size="small"
            sx={{ minWidth: 500 }}
            aria-label="customized table"
          >
            <TableHead>
              <TableRow>
                <StyledTableCell align="center" colSpan={6}>
                  CALLS
                </StyledTableCell>
                <StyledTableCell align="center" colSpan={6}>
                  PUTS
                </StyledTableCell>
              </TableRow>

              <TableRow>
                {columns.map((column) => (
                  <StyledTableCell
                    key={column.uniqueID}
                    align={column.align}
                    style={{ top: 57, minWidth: column.minWidth }}
                  >
                    {column.label}
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>

            {/* TABLE BODY STARTS HERE */}
            <TableBody>
              {/* {rows.map((row) => (
              <StyledTableRow key={row.name}>
                <StyledTableCell component="th" scope="row">
                  {row.name}
                </StyledTableCell>
                <StyledTableCell align="right">{row.calories}</StyledTableCell>
                
              </StyledTableRow>
            ))} */}

              {filteredData?.map((row, rowIndex) => {
                return (
                  <StyledTableRow
                    hover
                    role="checkbox"
                    key={rowIndex}
                    tabIndex={-1}
                  >
                    {columns.map((column) => {
                      let ce_value = row?.CE[column.id]
                        ? row?.CE[column.id]
                        : "-";
                      let pe_value = row?.PE[column.id]
                        ? row?.PE[column.id]
                        : "-";

                      // code to filter out top 3 striker prices, to make background green
                      const strike_price = row?.CE?.striker_price;
                      const isTopSrikerPrice = topStrikerPrices?.map(
                        (item) => item.striker_price
                      );
                      const isTop =
                        isTopSrikerPrice?.includes(strike_price) &&
                        row?.CE[column.id] === strike_price;

                      // Return ce 'calls' data for 'calls'
                      if (column.optionType === "calls") {
                        // need format 'chart' column manually, it's not coming from backend.
                        if (column.id === "chart") {
                          // selectedSymbol
                           
                          ce_value = formatTradingViewOptionSymbol(
                            selectedSymbol || stockIndex,
                            selectedExpiryDate,
                            row?.CE?.striker_price,
                            "C"
                          );
                        }

                        return (
                          <StyledTableCell
                            key={column.uniqueID}
                            align={column.align}
                            style={{
                              backgroundColor: isTop && recomData?.recommendation === "Buy" ? "#00A36C" : "",
                              color: isTop && recomData?.recommendation === "Buy" ? "white" : "#555",
                            }}
                          >
                            {/* {column.format && typeof ce_value === "number"
                              ? column.format(ce_value)
                              : ce_value} */}

                            {/* DISPLAY CHIP CHART LINK FOR 'CHART' ELSE NORMAL */}
                            {column.id === "chart" && row?.CE?.striker_price ? (
                              <Chip
                                color="info"
                                size="small"
                                label="Chart"
                                component="a"
                                // href={`/charts?symbol=${ce_value}:optionchain`}
                                href={`https://in.tradingview.com/chart/?symbol=NSE%3A${ce_value}`}
                                clickable
                                // target="popup"
                                // onClick={() => {
                                //   window.open(
                                //     `${CHART_BASE_URL}?symbol=${ce_value}:optionchain`,
                                //     "popup",
                                //     "width=1000,height=600"
                                //   );
                                //   return false;
                                // }}
                                target="_blank"
                                // sx={chipStyles}
                              />
                            ) : column.format &&
                              typeof ce_value === "number" ? (
                              column.format(ce_value)
                            ) : (
                              ce_value
                            )}
                          </StyledTableCell>
                        );
                      }

                      // Return pe 'puts' data for 'puts'
                      if (column.optionType === "puts") {
                        if (column.id === "chart") {
                          pe_value = formatTradingViewOptionSymbol(
                            selectedSymbol || stockIndex,
                            selectedExpiryDate,
                            row?.PE?.striker_price,
                            "P"
                          );
                        }

                        return (
                          <StyledTableCell
                            key={column.uniqueID}
                            align={column.align}
                            style={{
                              backgroundColor: isTop && recomData?.recommendation === "Sell" ? "#00A36C" : "",
                              color: isTop && recomData?.recommendation === "Sell" ? "white" : "#555",
                            }}
                          >
                            {/* {column.format && typeof pe_value === "number"
                              ? column.format(pe_value)
                              : pe_value} */}

                            {/* DISPLAY CHIP CHART LINK FOR 'CHART' ELSE NORMAL */}
                            {column.id === "chart" && row?.PE?.striker_price ? (
                              <Chip
                                color="info"
                                size="small"
                                label="Chart"
                                component="a"
                                // href={`/charts?symbol=${pe_value}:optionchain`}
                                href={`https://in.tradingview.com/chart/?symbol=NSE%3A${pe_value}`}
                                clickable
                                // target="popup"
                                // onClick={() => {
                                //   window.open(
                                //     `/charts?symbol=${pe_value}:optionchain`,
                                //     "popup",
                                //     "width=1000,height=600"
                                //   );
                                //   return false;
                                // }}
                                target="_blank"
                                // sx={chipStyles}
                              />
                            ) : column.format &&
                              typeof pe_value === "number" ? (
                              column.format(pe_value)
                            ) : (
                              pe_value
                            )}
                          </StyledTableCell>
                        );
                      }
                    })}
                  </StyledTableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {/* {!loading && (!filteredData || filteredData?.length === 0) ? (
        <Box sx={{ m: 4 }}>
          <Typography variant="h4">No Results Found</Typography>
          <Typography variant="h6">
            We couldn't find what you're searching for. Please try again.
          </Typography>
        </Box>
      ) : null} */}

      {/* ALERT MESSAGE TOAST */}
      {showAlert.isVisible && (
        <MuiAlertToast
          severity={showAlert.severity}
          msg={showAlert.msg}
          setShowAlert={setShowAlert}
        />
      )}

      {/* REACT HOT TOAST */}
      <ReactHotToaster />
    </div>
  );
};

export default SingleOptionChainItem;

// CUSTOM AUTOCOMPLATE COMPONENT
function CustomMuiAutocomplete({
  label,
  options,
  value,
  onChange,
  stateName,
  defaultValue,
}) {
  // const [value, setValue] = useState(options[0]);  // actual state that is selected
  const [inputValue, setInputValue] = useState(""); // what user types manually

  return (
    <Autocomplete
      size="small"
      value={value}
      onChange={onChange}
      inputValue={inputValue}
      defaultValue={defaultValue}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      id="mui-autocomplete"
      options={options}
      sx={{ width: 200 }}
      renderInput={(params) => <TextField {...params} label={label} />}
    />
  );
}

// UTILITY FUNCTIONS
function formatOptionSymbol(SYMBOL, EXPIRY_DATE, STRIKE_PRICE, TYPE) {
  if (!STRIKE_PRICE) return "-";

  const expiry_date_format = EXPIRY_DATE?.slice(2).split("-").join("");

  return `${SYMBOL}${expiry_date_format}${STRIKE_PRICE.toString()}${TYPE}`;
}

// tradingView Options symbol format: RELIANCE240530C3000
function formatTradingViewOptionSymbol(SYMBOL, EXPIRY_DATE, STRIKE_PRICE, TYPE) {
  if (!STRIKE_PRICE) return "-";

  const expiry_date_format = EXPIRY_DATE?.slice(2).split("-").join("");

  return `${SYMBOL}${expiry_date_format}${TYPE}${STRIKE_PRICE.toString()}`;
}



// fetch symbols 
async function fetchSymbolsData (selectedSymbol, stockIndex) {
  if (selectedSymbol === "" && stockIndex === "") {
    return;
  }

  const res = await axiosConfig({
    method: "post",
    url: "/optionchain/expirydate",
    data: { symbol: selectedSymbol || stockIndex },
  });

  return res.data;

};