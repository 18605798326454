import React, { useState, useEffect } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import { CSVLink } from "react-csv";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import DownloadIcon from "@mui/icons-material/Download";
import CancelIcon from "@mui/icons-material/Cancel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useSelector } from "react-redux";
import { useMutation } from "@tanstack/react-query";
import { Button, Stack, Typography } from "@mui/material";

import EditableTable from "./EditableTable";
import Loader from "../components/Loading/Loading";
import BasicPagination from "../components/Pagination/Pagination";
import AccessDenied from "../components/common/AccessDenied";
import ReactHotToaster from "../components/common/ReactHotToaster";
import { countUserTypes } from "../utils/common/countUserTypes";
import { axiosConfig } from "../axios/axiosConfig";
import styles from "./UserData.module.css";
import { fetchUserTypeList } from "../components/common/UploadBannerForm";

const AUTH_TOKEN = JSON.parse(localStorage.getItem("user"))?.access_token;
const baseURL = process.env.REACT_APP_SERVER_URL;

const UserData = () => {
  const {
    user: { userDetails },
  } = useSelector((store) => store.auth);

  const USERID = userDetails?.id;

  const [userTypeForCSV, setUserTypeForCSV] = useState("All");
  const [userData, setUserData] = useState([]);
  const [searchedUserData, setSearchedUserData] = useState([]);
  const [searchText, setSearchText] = useState("");
  // search user Input
  const [searchInput, setSearchInput] = useState("");
  const [sortingOrder, setSortingOrder] = useState(true);
  const [userTypeCount, setUserTypeCount] = useState({}); // to show userType count
  const [total_count, set_total_count] = useState(0);
  const [userTypes, setUserTypes] = useState([]);
  // ALL JOINING DATES ARRAY, including
  const joinedDates = userData?.map((user) => user.date_joined);
  // COUNTRY & USER TYPE FILTERING (As of filtering of Dates if from frontend, so joinDate not needed)
  const [userInfo, setUserInfo] = useState({
    country: undefined,
    userType: undefined,
    userStatus: undefined,
    // joinDate: undefined,
    fromDate: undefined,
    toDate: undefined,
  });

  const { country, userType, userStatus } = userInfo;
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(10);
  const [itemsLimit, setItemsLimit] = useState(50);
  const [userDataLoading, setUserDataLoading] = useState(false);

  const calculatedOffset = (currentPage - 1) * itemsLimit;

  const limit = [
    {
      label: "10",
      value: 10,
    },
    {
      label: "20",
      value: 20,
    },
    {
      label: "50",
      value: 50,
    },
    {
      label: "100",
      value: 100,
    },
    {
      label: "200",
      value: 200,
    },
  ];

  // Search / Filter Users by username, email, phone number, referral code
  const handleSearchUser = () => {
    if (!searchText || searchText.trim() === "") {
      setSearchedUserData(userData);
      return;
    }

    const formattedSearchText = searchText.toLowerCase();

    const newUserData = userData?.filter((userItem) => {
      const formattedUserName = userItem?.username?.toLowerCase();
      const searchByUserName =
        !!formattedUserName &&
        formattedUserName?.search(formattedSearchText) !== -1;

      const formattedEmail = userItem?.email?.toLowerCase();
      const searchByEmail =
        !!formattedEmail && formattedEmail?.search(formattedSearchText) !== -1;

      const formattedPhone = userItem?.phoneno?.toLowerCase();
      const searchByPhone =
        !!formattedPhone && formattedPhone?.search(formattedSearchText) !== -1;

      const formattedCountry = userItem?.country?.toLowerCase();
      const searchByCountry =
        !!formattedCountry &&
        formattedCountry?.search(formattedSearchText) !== -1;

      const formattedUserType = userItem?.user_type?.toLowerCase();
      const searchByUserType =
        !!formattedUserType &&
        formattedUserType?.search(formattedSearchText) !== -1;

      const formattedReferralCode = userItem?.referralcode?.toLowerCase();
      const searchByReferralCode =
        !!formattedReferralCode &&
        formattedReferralCode?.search(formattedSearchText) !== -1;

      // const formattedUserStatus = userItem?.is_active;
      // const searchByUserStatus =
      //   !!formattedUserStatus &&
      //   formattedUserStatus?.search(formattedSearchText) !== -1;

      return (
        searchByUserName ||
        searchByEmail ||
        searchByPhone ||
        searchByCountry ||
        searchByUserType ||
        searchByReferralCode
      );
    });

    setSearchedUserData(newUserData); // 2nd render due to this
  };

  // useEffect(() => {
  //   handleSearchUser();
  // }, [searchText]);

  const handleEditedField = (user) => {
    axios
      .patch(`/tnibroadmin/admin?id=${user.id}`, {
        ...user,
      })
      .then((response) => {
        toast.success(response.data.response);
        fetchUserData();
      })
      .catch((error) => {
        console.log(error);
        toast.error(error?.response?.data?.response || "Something went wrong");
      });
  };

  const fetchUserData = () => {
    setUserDataLoading(true);

    // to send undefined to backend (empty string causing auth error)
    if (userInfo.fromDate === "") {
      userInfo.fromDate = undefined;
    }
    if (userInfo.toDate === "") {
      userInfo.toDate = undefined;
    }

    const params = {
      limit: itemsLimit,
      // offset: (currentPage - 1) * itemsLimit,
      offset: calculatedOffset,
      country: userInfo.country?.toLowerCase(),
      user_type: userInfo.userType,
      from_date_joined: userInfo.fromDate,
      to_date_joined: userInfo.toDate,
      is_active: userInfo.userStatus,
      // date_joined: userInfo.joinDate
    };

    if (searchText !== "") {
      params.search = searchText;
    }

    axios
      .get("/tnibroadmin/admin", {
        params: {
          userid: USERID,
          ...params,
        },
      })
      .then((response) => {
        setUserData(response.data.response);
        set_total_count(response.data?.total_count);
        setSearchedUserData(response.data.response);

        const totalPage = Math.ceil(response.data?.total_count / itemsLimit);
        setTotalPages(totalPage);
      })
      .catch((error) => {
        console.log(error);
        toast.error(error?.response?.data?.response || "Something went wrong");
      })
      .finally(() => setUserDataLoading(false));
  };

  // DELETE USER
  const handleDeleteUser = (user) => {
    axios
      .delete(`/tnibroadmin/admin?userid=${user.id}&admin_id=${userDetails.id}`)
      .then((response) => {
        toast.success(response.data.response);

        setSearchedUserData((prevState) => {
          const newUserData = prevState.filter(
            (userItem) => userItem.id !== user.id
          );
          return newUserData;
        });
      })
      .catch((error) => {
        console.log(error);
        toast.error(error?.response?.data?.response || "Something went wrong");
      });
  };

  // const handleSearchText = (event) => {
  //   setSearchText(event.target.value);
  // };

  const handlePagechange = (val) => {
    setCurrentPage(val);
  };

  const handleItemsLimitChange = (event) => {
    setItemsLimit(event.target.value);
    setCurrentPage(1);
  };

  // below function is irrelevant
  // const handleSearchOnEnter = () => {
  //   setCurrentPage(1);
  //   fetchUserData();
  // };

  useEffect(() => {
    fetchUserData();
  }, [currentPage, itemsLimit, country, userType, userStatus]);

  // FILTER DATES FUNCTION: check if joined date falls between two dates
  // function filterDates() {
  //   const newUserData = userData.filter((user) => {
  //     const user_joined_date = new Date(user.date_joined);
  //     return (
  //       user_joined_date >= new Date(userInfo.fromDate) &&
  //       user_joined_date <= new Date(userInfo.toDate)
  //     );
  //   });

  //   setUserData(newUserData);
  // }

  useEffect(() => {
    const getUserTypeList = async () => {
      try {
        const data = await fetchUserTypeList();
        const modified_data = (data.response.user_type =
          data.response.user_type.map((type, index) => ({
            id: index,
            type: type,
          })));

        setUserTypes(modified_data);
      } catch (error) {}
    };
    getUserTypeList(); // FETCH User Type
  }, []);

  useEffect(() => {
    // function countUserTypes() {
    //   if (!userData) {
    //     return;
    //   }

    //   const userTypeCounts = {
    //     Free: 0,
    //     Trial: 0,
    //     Admin: 0,
    //     Premium: 0
    //   };

    //   for (const user of userData) {
    //     const userType = user.user_type;
    //     if (userTypeCounts.hasOwnProperty(userType)) {
    //       userTypeCounts[userType]++;
    //     }
    //   }

    //   setUserTypeCount(userTypeCounts)
    //   // return userTypeCounts;
    // }

    // countUserTypes();
    const userTypeCounts = countUserTypes(userData);
    setUserTypeCount(userTypeCounts);
  }, [userData]);

  // SORT USERDATA
  const sortUserData = (sortBy) => {
    setSortingOrder((prev) => !prev);
    const newUserData = [...searchedUserData];

    newUserData.sort(function (a, b) {
      const current =
        typeof a[sortBy] === "string" ? a[sortBy].toLowerCase() : a[sortBy];
      const next =
        typeof b[sortBy] === "string" ? b[sortBy].toLowerCase() : b[sortBy];

      // if order is true, sort a-z
      // if (sortingOrder) {
      //   if (current < next) {
      //     return -1;
      //   }
      //   if (current > next) {
      //     return 1;
      //   }
      // } else {
      //   if (current < next) {
      //     return 1;
      //   }
      //   if (current > next) {
      //     return -1;
      //   }
      // }

      // Handle undefined, null, and empty strings first
      if (!current) {
        if (next) {
          return 1; // Undefined/null/empty string comes after others
        }
      } else if (!next) {
        return -1; // Others come before undefined/null/empty string
      } else {
        // Normal comparison with sorting order check
        if (sortingOrder) {
          return current < next ? -1 : current > next ? 1 : 0; // Ascending order
        } else {
          return current > next ? -1 : current < next ? 1 : 0; // Descending order
        }
      }
    });

    setSearchedUserData(newUserData);
  };

  const searchUserMutation = useMutation({
    mutationFn: async () => {
      if (!searchInput) {
        toast.error("Please enter a search term");
        return;
      }
      const res = await axiosConfig({
        method: "get",
        // url: `/tnibroadmin/admin?userid=${USERID}&limit=${itemsLimit}&offset=${calculatedOffset}&search=${searchInput}`,
        url: `/tnibroadmin/admin?userid=${USERID}&limit=10&offset=0&search=${searchInput}`,
        data: {},
      });
      return res.data;
    },
    onSuccess: (data) => {
      setSearchedUserData(data.response);
    },
    onError: (error) => {
      toast.error(error.message || "Something went wrong");
    },
  });

  // HANDLE NEW USER SEARCH (SEARCH API FROM BACKEND)
  const handleUserSearch = () => {
    searchUserMutation.mutate();
  };

  // CLEAR SEARCH BUTTON HANDLER, when user/admin clicks on close button
  const clearSearch = () => {
    setSearchInput("");
    setSearchedUserData(userData);
  };

  // COMMENTED OUT AS NOT BEING USED
  // sending details for csv download
  // const getCSVDataMutation = useMutation({
  //   mutationFn: async () => {
  //     const res = await axiosConfig({
  //       method: "get",
  //       url: `/tnibroadmin/export/contacts?type=${userTypeForCSV}`,
  //       data: {},
  //     });
  //     return res.data;
  //   },
  //   onSuccess: (data) => {
  //     // console.log("csv data: ", data);
  //   },
  //   onError: (error) => {
  //     toast.error(error.message || "Something went wrong");
  //   },
  // });

  // DOWNLOAD CSV HANDLER
  // const downloadCSV = () => {
  //   getCSVDataMutation.mutate();
  // };

  // ACCESS DENIED, NOT AUTHORIZED FOR NON-ADMIN
  if (userDetails?.user_type !== "Admin") {
    return <AccessDenied />;
  }

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          overflow: "hidden",
        }}
      >
        {/* USER TYPE COUNT */}
        <Box
          sx={{
            marginTop: "2rem",
            marginLeft: "2rem",
            padding: "1rem",
            borderRadius: "8px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            backgroundColor: "#fff",
            maxWidth: 1500,
          }}
        >
          <h2 className={styles.mainContainer}>User Types Count</h2>

          <Stack direction="row" spacing={2} marginTop={2}>
            <Typography variant="subtitle">
              Trial: {userTypeCount?.Trial}
            </Typography>
            <Typography variant="subtitle">
              Guest: {userTypeCount?.Guest}
            </Typography>
            <Typography variant="subtitle">
              Premium: {userTypeCount?.Premium}
            </Typography>
            <Typography variant="subtitle">
              Admin: {userTypeCount?.Admin}
            </Typography>
            <Typography variant="subtitle">
              Advisor: {userTypeCount?.Advisor}
            </Typography>
            <Typography variant="subtitle">
              Total Count: {total_count}
            </Typography>
          </Stack>
        </Box>

        <div
          style={{
            maxWidth: 1500,
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              marginTop: "2rem",
              marginLeft: "2rem",
              padding: "1rem",
              borderRadius: "8px",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              backgroundColor: "#fff",
            }}
            className={styles.csvDownloadContainer}
          >
            <div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <h2 style={{ marginRight: "50px" }}>Download Users :</h2>
                <select
                  style={{ width: "120px", padding: "8px 10px" }}
                  name="userTypeForCSV"
                  id="userTypeForCSV"
                  value={userTypeForCSV}
                  onChange={(e) => setUserTypeForCSV(e.target.value)}
                  className={styles.selectDropdown}
                >
                  <option value="All">All</option>
                  {userTypes.map(({ id, type }) => (
                    <option key={id} value={type}>
                      {type}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div
              style={{
                marginTop: "10px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
              }}
            >
              <a
                className={styles.downloadBtn}
                href={`${baseURL}/tnibroadmin/export/contacts?type=${userTypeForCSV}&token=${AUTH_TOKEN}`}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "10px",
                  }}
                >
                  {"Download"}
                  <DownloadIcon style={{ marginLeft: "10px" }} />
                </div>
              </a>
            </div>
          </div>
          <div
            style={{
              marginTop: "25px",
            }}
          >
            <a
              className={styles.downloadBtn}
              href={`${baseURL}/tnibroadmin/export/payments/cancel?token=${AUTH_TOKEN}`}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "10px",
                }}
              >
                {"Unsuccessful Payments"}
                <DownloadIcon style={{ marginLeft: "10px" }} />
              </div>
            </a>
          </div>
        </div>

        {!userDataLoading ? (
          <div>
            <div
              style={{
                display: "flex",
                gap: "1rem",
                margin: "1rem 2rem",
              }}
            >
              {/* <TextField
                id="search-field"
                size="small"
                label="Search User"
                type="search"
                value={searchText}
                onChange={(event) => setSearchText(event.target.value.trim())}
              /> */}

              <div className={styles.searchContainer}>
                <div className={styles.searchInputBox}>
                  <input
                    className={styles.searchInput}
                    type="text"
                    placeholder="Search User"
                    value={searchInput}
                    onChange={(event) => setSearchInput(event.target.value)}
                  />

                  <CancelIcon
                    className={styles.cancelIcon}
                    onClick={clearSearch}
                  />
                </div>

                <div className={styles.searchBtnContainer}>
                  <button
                    className={styles.searchBtn}
                    onClick={handleUserSearch}
                  >
                    Search
                  </button>
                </div>
              </div>

              <Box>
                {searchedUserData?.length > 0 ? (
                  <CSVLink
                    data={searchedUserData || []}
                    filename="USERDATA.csv"
                  >
                    <Button
                      variant="contained"
                      size="small"
                      startIcon={<DownloadIcon />}
                    >
                      DOWNLOAD CSV BELOW TABLE
                    </Button>
                  </CSVLink>
                ) : null}
              </Box>
            </div>

            <div>
              <EditableTable
                userData={searchedUserData}
                editedFieldData={handleEditedField}
                deleteUser={handleDeleteUser}
                userInfo={userInfo}
                setUserInfo={setUserInfo}
                joinedDates={joinedDates}
                fetchUserData={fetchUserData}
                sortUserData={sortUserData}
              />

              <div
                style={{
                  display: "flex",
                  margin: "20px",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <BasicPagination
                  count={totalPages}
                  currentPage={currentPage}
                  handlePagechange={handlePagechange}
                />
                <Box sx={{ minWidth: 120 }}>
                  <FormControl fullWidth size="small">
                    <InputLabel id="demo-simple-select-label">Limit</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={itemsLimit}
                      label="Limit"
                      onChange={handleItemsLimitChange}
                    >
                      {limit.map((num) => (
                        <MenuItem key={num.value} value={num.value}>
                          {num.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              </div>
            </div>
          </div>
        ) : (
          <div
            style={{
              width: "800px",
              marginLeft: "20px",
            }}
          >
            <Loader />
          </div>
        )}
      </div>

      <ReactHotToaster />
    </>
  );
};

export default UserData;
