import React from "react";
import styles from "./AdvisorUserSupport.module.css";
import AllUsersTicketHistoryTable from "../../AdminDashboard/UserSupportPanel/AllUsersTicketHistoryTable";
import BackButton from "../../common/BackButton";

const AdvisorUserSupport = () => {
  return (
    <div className={styles.mainContainer}>
      <BackButton />
      {/* <h2 className="topHeadingUnderline" >Advisor Support Panel</h2> */}

      <AllUsersTicketHistoryTable
        title="Advisor Support Panel"
        userType="advisor"
      />
    </div>
  );
};

export default AdvisorUserSupport;
