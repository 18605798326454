import React from "react";

import DashboardIcon from "@mui/icons-material/Dashboard";
import PaymentIcon from "@mui/icons-material/Payment";
// import ShowChartIcon from "@mui/icons-material/ShowChart";
import Diversity3Icon from "@mui/icons-material/Diversity3";
import EmailIcon from "@mui/icons-material/Email";
import DevicesOtherIcon from "@mui/icons-material/DevicesOther";
import ReceiptIcon from "@mui/icons-material/Receipt";
import SettingsIcon from "@mui/icons-material/Settings";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

import DashboardLayout from "../components/common/DashboardLayout";

// const drawerWidth = 240;

const mainListItemsArray = [
  {
    id: 1,
    label: "Profile",
    link: "/user-dashboard/profile",
    icon: <AccountCircleIcon />,
  },
  {
    id: 2,
    label: "My Devices",
    link: "/user-dashboard/devices",
    icon: <DevicesOtherIcon />,
  },
  {
    id: 3,
    label: "My Subscription",
    link: "/user-dashboard/subscription",
    icon: <ReceiptIcon />,
  },
  {
    id: 4,
    label: "Payment History",
    link: "/user-dashboard/payments",
    icon: <PaymentIcon />,
  },
  // {
  //   id: 4,
  //   label: "Trading",
  //   link: "/user-dashboard/trading",
  //   icon: <ShowChartIcon />,
  // },
  {
    id: 5,
    label: "Referrals",
    link: "/user-dashboard/referrals",
    icon: <Diversity3Icon />,
  },

  {
    id: 6,
    label: "Support",
    link: "/user-dashboard/support",
    icon: <EmailIcon />,
  },
  {
    id: 7,
    label: "Settings",
    link: "/user-dashboard/settings",
    icon: <SettingsIcon />,
  },
  {
    id: 8,
    label: "Refund",
    link: "/user-dashboard/refund",
    icon: <AccountBalanceWalletIcon />,
  },
];

export default function UserDashboardLayout() {
  return <DashboardLayout mainListItems={mainListItemsArray} />;
}
