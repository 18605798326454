import React, { useState } from "react";
import UserPaymentsDashboardTable from "./UserPaymentsDashboardTable";
import { axiosConfig } from "../../../axios/axiosConfig";
import { useQuery } from "@tanstack/react-query";
import toast from "react-hot-toast";
import ReactHotToaster from "../../common/ReactHotToaster";

const styles = {
  pageContainer: {
    margin: "2rem",
  },
  pageTitle: {
    marginBottom: "2rem",
  },
};

const PaymentsDashboard = () => {
  // const [paidUsersData, setPaidUsersData] = useState();
  const [allUserPaymentDetails, setAllUserPaymentDetails] = useState([]);

  const allUserPaymentsQuery = useQuery({
    retry: false,
    refetchOnWindowFocus: false, // Prevent refetch on window focus
    refetchInterval: false, // Disable automatic refetching
    queryKey: ["user-payments"], // dependency
    queryFn: () => fetchAllUserPayments(),
    onSuccess: (data) => {
      setAllUserPaymentDetails(data.response);
    },
    onError: (error) => {
      // console.log("payments error: ", error);
      toast.error(error?.response?.data?.response || "Something went wrong");
    },
  });

  return (
    <div style={styles.pageContainer}>
      <h1 style={styles.pageTitle}>Payments Dashboard</h1>

      {/* USER PAYMENTS DASHBOARD TABLE */}
      {allUserPaymentsQuery.isLoading ? (
        <h1>Loading..</h1>
      ) : (
        <UserPaymentsDashboardTable
          allUserPaymentDetails={allUserPaymentDetails}
          setAllUserPaymentDetails={setAllUserPaymentDetails}
        />
      )}

      {/* REACT HOT TOAST */}
      <ReactHotToaster />
    </div>
  );
};

export default PaymentsDashboard;

export const fetchAllUserPayments = async () => {
  const res = await axiosConfig({
    method: "get",
    url: "/payment/payments",
    data: {},
  });

  return res.data;
};
