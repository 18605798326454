// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.SearchBox_searchListContainer__lZtFk {
  /* width: 100%; */
  height: 300px;
  /* border: 1px solid blue; */
  position: absolute;
  margin-top: 4rem;
  z-index: 999;
}

/* Search List menu */
.SearchBox_searchList__Y7AXX {
  list-style: none;
  /* margin: 16px;
  padding: 8px 0; */
  /* box-shadow: 2px 2px 5px 1px #888; */
  background-color: white;
  

  /* position: absolute; */
  max-height: 300px;
  min-width: 300px;
  overflow: auto;
  /* margin-top: 4rem; */
  border: 1px solid #ccc;
  border-radius: 4px;
  /* z-index: 999; */
}

.SearchBox_searchListItem__BwGDc {
  cursor: pointer;
  color: #555;
  padding: 2px 12px;

  /* background-color: #4dcb0f; */
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.SearchBox_searchListItem__BwGDc:hover {
  background-color: #555;
  color: white;
}
.SearchBox_searchListItem__BwGDc:hover span {
  color: white !important;
}

/* DATE */
.SearchBox_date_container__FDDRD {
  /* width: 100%; */
  padding: 0.5rem 1rem;
  border: 3px solid #4dcb0f;
  border-radius: 1.5rem;
  /* height: 40px; */
  margin-left: 0.5rem;
  
}

.SearchBox_date_input__Err4Z {
  /* visibility: hidden; */
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
}`, "",{"version":3,"sources":["webpack://./src/components/SearchBox/SearchBox.module.css"],"names":[],"mappings":";AACA;EACE,iBAAiB;EACjB,aAAa;EACb,4BAA4B;EAC5B,kBAAkB;EAClB,gBAAgB;EAChB,YAAY;AACd;;AAEA,qBAAqB;AACrB;EACE,gBAAgB;EAChB;mBACiB;EACjB,sCAAsC;EACtC,uBAAuB;;;EAGvB,wBAAwB;EACxB,iBAAiB;EACjB,gBAAgB;EAChB,cAAc;EACd,sBAAsB;EACtB,sBAAsB;EACtB,kBAAkB;EAClB,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,WAAW;EACX,iBAAiB;;EAEjB,+BAA+B;EAC/B,aAAa;EACb,mBAAmB;EACnB,8BAA8B;AAChC;;AAEA;EACE,sBAAsB;EACtB,YAAY;AACd;AACA;EACE,uBAAuB;AACzB;;AAEA,SAAS;AACT;EACE,iBAAiB;EACjB,oBAAoB;EACpB,yBAAyB;EACzB,qBAAqB;EACrB,kBAAkB;EAClB,mBAAmB;;AAErB;;AAEA;EACE,wBAAwB;EACxB,WAAW;EACX,YAAY;EACZ,YAAY;EACZ,aAAa;AACf","sourcesContent":["\n.searchListContainer {\n  /* width: 100%; */\n  height: 300px;\n  /* border: 1px solid blue; */\n  position: absolute;\n  margin-top: 4rem;\n  z-index: 999;\n}\n\n/* Search List menu */\n.searchList {\n  list-style: none;\n  /* margin: 16px;\n  padding: 8px 0; */\n  /* box-shadow: 2px 2px 5px 1px #888; */\n  background-color: white;\n  \n\n  /* position: absolute; */\n  max-height: 300px;\n  min-width: 300px;\n  overflow: auto;\n  /* margin-top: 4rem; */\n  border: 1px solid #ccc;\n  border-radius: 4px;\n  /* z-index: 999; */\n}\n\n.searchListItem {\n  cursor: pointer;\n  color: #555;\n  padding: 2px 12px;\n\n  /* background-color: #4dcb0f; */\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n}\n\n.searchListItem:hover {\n  background-color: #555;\n  color: white;\n}\n.searchListItem:hover span {\n  color: white !important;\n}\n\n/* DATE */\n.date_container {\n  /* width: 100%; */\n  padding: 0.5rem 1rem;\n  border: 3px solid #4dcb0f;\n  border-radius: 1.5rem;\n  /* height: 40px; */\n  margin-left: 0.5rem;\n  \n}\n\n.date_input {\n  /* visibility: hidden; */\n  width: 100%;\n  height: 100%;\n  border: none;\n  outline: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"searchListContainer": `SearchBox_searchListContainer__lZtFk`,
	"searchList": `SearchBox_searchList__Y7AXX`,
	"searchListItem": `SearchBox_searchListItem__BwGDc`,
	"date_container": `SearchBox_date_container__FDDRD`,
	"date_input": `SearchBox_date_input__Err4Z`
};
export default ___CSS_LOADER_EXPORT___;
