// Function being used in SingleUserReferralTable & UserData components

export function countUserTypes(user_referral_data) {
  if (!user_referral_data) {
    return;
  }

  const userTypeCounts = {
    Free: 0,
    Trial: 0,
    Admin: 0,
    Premium: 0,
    Guest: 0,
    Advisor: 0,
  };

  for (const user of user_referral_data) {
    const userType = user.user_type;
    if (userTypeCounts.hasOwnProperty(userType)) {
      userTypeCounts[userType]++;
    }
  }

  return userTypeCounts;
}
