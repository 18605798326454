// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FilterByStockCategories_select_Input__YEFsv {
  padding: 2px;
  text-transform: uppercase;
  font-weight: 300;
  border: 1px solid #999;
  border-radius: 4px;
  outline: none;
  /* color: #fff;
  background-color: #666; */
}

.FilterByStockCategories_select_Input__YEFsv option {
  font-weight: 300;
  
}


.FilterByStockCategories_blinking_border__hsVF5 {
  border: 2px #ff0000 solid;
  border-radius: 4px;
  animation: FilterByStockCategories_blink__1f8sT 1s;
  animation-iteration-count: infinite;
}

@keyframes FilterByStockCategories_blink__1f8sT {
  50% {
      border-color: #fff;
    }
}
`, "",{"version":3,"sources":["webpack://./src/components/common/FilterByStockCategories/FilterByStockCategories.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,yBAAyB;EACzB,gBAAgB;EAChB,sBAAsB;EACtB,kBAAkB;EAClB,aAAa;EACb;2BACyB;AAC3B;;AAEA;EACE,gBAAgB;;AAElB;;;AAGA;EACE,yBAAyB;EACzB,kBAAkB;EAClB,kDAAmB;EACnB,mCAAmC;AACrC;;AAEA;EACE;MACI,kBAAkB;IACpB;AACJ","sourcesContent":[".select_Input {\n  padding: 2px;\n  text-transform: uppercase;\n  font-weight: 300;\n  border: 1px solid #999;\n  border-radius: 4px;\n  outline: none;\n  /* color: #fff;\n  background-color: #666; */\n}\n\n.select_Input option {\n  font-weight: 300;\n  \n}\n\n\n.blinking_border {\n  border: 2px #ff0000 solid;\n  border-radius: 4px;\n  animation: blink 1s;\n  animation-iteration-count: infinite;\n}\n\n@keyframes blink {\n  50% {\n      border-color: #fff;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"select_Input": `FilterByStockCategories_select_Input__YEFsv`,
	"blinking_border": `FilterByStockCategories_blinking_border__hsVF5`,
	"blink": `FilterByStockCategories_blink__1f8sT`
};
export default ___CSS_LOADER_EXPORT___;
