import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  SET_MESSAGE,
  // STORE_USER_DETAILS,
} from "./types";

import AuthService from "../../services/auth.service";
import { sendDeviceID, sendToken } from "../../utils/notifications";

export const register =
  (username, email, password, phoneno, country, REFERRAL_CODE) =>
  (dispatch) => {
    return AuthService.register(
      username,
      email,
      password,
      phoneno,
      country,
      REFERRAL_CODE
    ).then(
      async (response) => {
        dispatch({
          type: REGISTER_SUCCESS,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: response.data.message,
        });

        const USERID = response.data.response.id;
        sendDeviceID(USERID);  // sending fcm token to server for browser push notifications

        return Promise.resolve(response);
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        dispatch({
          type: REGISTER_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });

        return Promise.reject(error);
      }
    );
  };

export const login = (email, password, phone) => (dispatch) => {
  return AuthService.login(email, password, phone).then(
    async (data) => {
      // console.log("data in Authservice.login: ", data)
      const USERID = data.data.response.id;
      await sendToken(USERID)
        // sendDeviceID(USERID);  // sending fcm token to server for browser push notifications
      
      dispatch({
        type: LOGIN_SUCCESS,
        payload: { user: data },
      });

      if (data.data.access_token) {
        const allUserDetails = {
          ...data.data,
          userDetails: data.data.response,
        };
        
        

        localStorage.setItem("user", JSON.stringify(allUserDetails));

        // window.location.reload();
      }
      // const user = JSON.parse(localStorage.getItem("user"));
      // localStorage.setItem(
      //   "user",
      //   JSON.stringify({ ...user, userDetails: data.data })
      // );

      // AuthService.getUserDetails(data.access_token)
      //   .then((data) => {
      //     // console.log(data);
      //     const user = JSON.parse(localStorage.getItem("user"));
      //     localStorage.setItem(
      //       "user",
      //       JSON.stringify({ ...user, userDetails: data.data })
      //     );
      //     dispatch({ type: STORE_USER_DETAILS, payload: data.data });
      //   })
      //   .catch((err) => console.log(err));

      return Promise.resolve();
    },
    (error) => {
      // console.log(error.response.data.response)
      const message =
        error.response.data.response ||
        error.response.data.message ||
        error.message;

      dispatch({
        type: LOGIN_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      // throw new Error(JSON.stringify(message));
      return Promise.reject(new Error(JSON.stringify(message)));
    }
  );
};
export const googleLogin = (email) => (dispatch) => {
  return AuthService.googleLogin(email).then(
    async (data) => {
      dispatch({
        type: LOGIN_SUCCESS,
        payload: { user: data },
      });

      
      const USERID = data;

      sendDeviceID(USERID);

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: LOGIN_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      // return Promise.reject();
      return Promise.reject(new Error(JSON.stringify(message)));
    }
  );
};

export const logout = () => (dispatch) => {
  AuthService.logout();

  dispatch({
    type: LOGOUT,
  });
};
