// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.SocialShare_outer_container__Uy8Mg {
  max-width: 220px;
  /* margin: 1rem 0; */
  border-radius: 6px;
  border: 1.5px solid #E2E8F0;
}

.SocialShare_container__KCOCu {
  /* max-width: 220px; */
  /* margin: 0.5rem 0; */
  
  padding: 2px;
  display: flex;
  align-items: center;
  gap: 0.2rem;
  justify-content: space-evenly;
  padding-top: 0.2rem;
  /* background: rgba( 51, 149, 2, 0.25 ); */
/* box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 ); */
backdrop-filter: blur( 4px );
-webkit-backdrop-filter: blur( 4px );
border-radius: 10px;
/* border: 1px solid rgba( 255, 255, 255, 0.18 ); */
}



.SocialShare_share_text__3ajAL {
  font-weight: 400;
  text-transform: capitalize;
  text-align: center;

  /* max-width: 220px; */
  /* width: 100%; */
  padding: 4px ;
  border-radius: 10px;
  /* background: rgba( 51, 149, 2, 0.25 ); */
}

`, "",{"version":3,"sources":["webpack://./src/components/common/SocialShare/SocialShare.module.css"],"names":[],"mappings":";AACA;EACE,gBAAgB;EAChB,oBAAoB;EACpB,kBAAkB;EAClB,2BAA2B;AAC7B;;AAEA;EACE,sBAAsB;EACtB,sBAAsB;;EAEtB,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,WAAW;EACX,6BAA6B;EAC7B,mBAAmB;EACnB,0CAA0C;AAC5C,wDAAwD;AACxD,4BAA4B;AAC5B,oCAAoC;AACpC,mBAAmB;AACnB,mDAAmD;AACnD;;;;AAIA;EACE,gBAAgB;EAChB,0BAA0B;EAC1B,kBAAkB;;EAElB,sBAAsB;EACtB,iBAAiB;EACjB,aAAa;EACb,mBAAmB;EACnB,0CAA0C;AAC5C","sourcesContent":["\n.outer_container {\n  max-width: 220px;\n  /* margin: 1rem 0; */\n  border-radius: 6px;\n  border: 1.5px solid #E2E8F0;\n}\n\n.container {\n  /* max-width: 220px; */\n  /* margin: 0.5rem 0; */\n  \n  padding: 2px;\n  display: flex;\n  align-items: center;\n  gap: 0.2rem;\n  justify-content: space-evenly;\n  padding-top: 0.2rem;\n  /* background: rgba( 51, 149, 2, 0.25 ); */\n/* box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 ); */\nbackdrop-filter: blur( 4px );\n-webkit-backdrop-filter: blur( 4px );\nborder-radius: 10px;\n/* border: 1px solid rgba( 255, 255, 255, 0.18 ); */\n}\n\n\n\n.share_text {\n  font-weight: 400;\n  text-transform: capitalize;\n  text-align: center;\n\n  /* max-width: 220px; */\n  /* width: 100%; */\n  padding: 4px ;\n  border-radius: 10px;\n  /* background: rgba( 51, 149, 2, 0.25 ); */\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"outer_container": `SocialShare_outer_container__Uy8Mg`,
	"container": `SocialShare_container__KCOCu`,
	"share_text": `SocialShare_share_text__3ajAL`
};
export default ___CSS_LOADER_EXPORT___;
