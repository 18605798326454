// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UnderConstruction_body__CBgii {
  font-family: Arial, sans-serif;
  background-color: #f2f2f2;
  text-align: center;
  margin: 0;
  padding: 0;
  margin-bottom: 7rem;
}

.UnderConstruction_container__JY6bk {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  padding: 20px;
  margin: 50px auto;
  max-width: 400px;
}

.UnderConstruction_title__PP\\+aa {
  color: #e74c3c;
}

.UnderConstruction_text__x1JVq {
  color: #333;
}`, "",{"version":3,"sources":["webpack://./src/components/UnderConstruction/UnderConstruction.module.css"],"names":[],"mappings":"AAAA;EACE,8BAA8B;EAC9B,yBAAyB;EACzB,kBAAkB;EAClB,SAAS;EACT,UAAU;EACV,mBAAmB;AACrB;;AAEA;EACE,sBAAsB;EACtB,mBAAmB;EACnB,uCAAuC;EACvC,aAAa;EACb,iBAAiB;EACjB,gBAAgB;AAClB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,WAAW;AACb","sourcesContent":[".body {\n  font-family: Arial, sans-serif;\n  background-color: #f2f2f2;\n  text-align: center;\n  margin: 0;\n  padding: 0;\n  margin-bottom: 7rem;\n}\n\n.container {\n  background-color: #fff;\n  border-radius: 10px;\n  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);\n  padding: 20px;\n  margin: 50px auto;\n  max-width: 400px;\n}\n\n.title {\n  color: #e74c3c;\n}\n\n.text {\n  color: #333;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"body": `UnderConstruction_body__CBgii`,
	"container": `UnderConstruction_container__JY6bk`,
	"title": `UnderConstruction_title__PP+aa`,
	"text": `UnderConstruction_text__x1JVq`
};
export default ___CSS_LOADER_EXPORT___;
