// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* TAble styling */

.SubscriptionPlansTable_outerContainer__WaMXr {
  margin: 2rem;
}

.SubscriptionPlansTable_tableTitle__hTLwA {
  text-align: center;
  margin-bottom: 1rem;
}

.SubscriptionPlansTable_tableContainer__o8k3\\+ {
  width: 100%;
  max-width: 900px;
  border-collapse: collapse;
  border: 1px solid #ccc;
  /* background-color: red; */
}

.SubscriptionPlansTable_tableContainer__o8k3\\+ th,
.SubscriptionPlansTable_tableContainer__o8k3\\+ td {
  padding: 8px;
  text-align: left;
  border: 1px solid #ccc;
}

.SubscriptionPlansTable_tableContainer__o8k3\\+ th {
  background-color: #f2f2f2;
}

.SubscriptionPlansTable_tableBodyContainer__xeG57 tr:nth-child(even) {
  background-color: #f2f2f2;
}

.SubscriptionPlansTable_btn__N7oD6 {
  outline: none;
  border: none;
  padding: 0.3rem 0.6rem;
  border-radius: 0.2rem;
  /* font-size: 1.2rem; */
  text-transform: uppercase;
  background-color: #333;
  color: #fff;
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/components/AdminDashboard/SubscriptionsPlans/SubscriptionPlansTable/SubscriptionPlansTable.module.css"],"names":[],"mappings":"AAAA,kBAAkB;;AAElB;EACE,YAAY;AACd;;AAEA;EACE,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,gBAAgB;EAChB,yBAAyB;EACzB,sBAAsB;EACtB,2BAA2B;AAC7B;;AAEA;;EAEE,YAAY;EACZ,gBAAgB;EAChB,sBAAsB;AACxB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,aAAa;EACb,YAAY;EACZ,sBAAsB;EACtB,qBAAqB;EACrB,uBAAuB;EACvB,yBAAyB;EACzB,sBAAsB;EACtB,WAAW;EACX,eAAe;AACjB","sourcesContent":["/* TAble styling */\n\n.outerContainer {\n  margin: 2rem;\n}\n\n.tableTitle {\n  text-align: center;\n  margin-bottom: 1rem;\n}\n\n.tableContainer {\n  width: 100%;\n  max-width: 900px;\n  border-collapse: collapse;\n  border: 1px solid #ccc;\n  /* background-color: red; */\n}\n\n.tableContainer th,\n.tableContainer td {\n  padding: 8px;\n  text-align: left;\n  border: 1px solid #ccc;\n}\n\n.tableContainer th {\n  background-color: #f2f2f2;\n}\n\n.tableBodyContainer tr:nth-child(even) {\n  background-color: #f2f2f2;\n}\n\n.btn {\n  outline: none;\n  border: none;\n  padding: 0.3rem 0.6rem;\n  border-radius: 0.2rem;\n  /* font-size: 1.2rem; */\n  text-transform: uppercase;\n  background-color: #333;\n  color: #fff;\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"outerContainer": `SubscriptionPlansTable_outerContainer__WaMXr`,
	"tableTitle": `SubscriptionPlansTable_tableTitle__hTLwA`,
	"tableContainer": `SubscriptionPlansTable_tableContainer__o8k3+`,
	"tableBodyContainer": `SubscriptionPlansTable_tableBodyContainer__xeG57`,
	"btn": `SubscriptionPlansTable_btn__N7oD6`
};
export default ___CSS_LOADER_EXPORT___;
