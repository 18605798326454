// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.access-denied {
    text-align: center;
    background-color: #f2dede;
    padding: 30px;
    margin-top: 50px;
    border-radius: 5px;
}

.access-denied__title {
    font-size: 2em;
    color: #a94442;
    margin-bottom: 20px;
}

.access-denied__message {
    font-size: 1.5em;
    color: #a94442;
}

.backBtn {
    margin-top: 1rem;
    padding: 0.2rem 1rem;
    background-color: #a94442;
    color: #fff;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    font-size: 1.1rem;
    /* font-weight: 300; */
    border: 1px solid #999;
    border-radius: 0.3rem;
    cursor: pointer;
}

.backBtn:hover {
    background-color: #4e0b0a;
}`, "",{"version":3,"sources":["webpack://./src/components/common/AccessDenied/AccessDenied.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,yBAAyB;IACzB,aAAa;IACb,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI,cAAc;IACd,cAAc;IACd,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;IAChB,cAAc;AAClB;;AAEA;IACI,gBAAgB;IAChB,oBAAoB;IACpB,yBAAyB;IACzB,WAAW;IACX,yBAAyB;IACzB,qBAAqB;IACrB,iBAAiB;IACjB,sBAAsB;IACtB,sBAAsB;IACtB,qBAAqB;IACrB,eAAe;AACnB;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":[".access-denied {\n    text-align: center;\n    background-color: #f2dede;\n    padding: 30px;\n    margin-top: 50px;\n    border-radius: 5px;\n}\n\n.access-denied__title {\n    font-size: 2em;\n    color: #a94442;\n    margin-bottom: 20px;\n}\n\n.access-denied__message {\n    font-size: 1.5em;\n    color: #a94442;\n}\n\n.backBtn {\n    margin-top: 1rem;\n    padding: 0.2rem 1rem;\n    background-color: #a94442;\n    color: #fff;\n    text-transform: uppercase;\n    letter-spacing: 1.5px;\n    font-size: 1.1rem;\n    /* font-weight: 300; */\n    border: 1px solid #999;\n    border-radius: 0.3rem;\n    cursor: pointer;\n}\n\n.backBtn:hover {\n    background-color: #4e0b0a;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
