import React from "react";

import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import PanoramaIcon from "@mui/icons-material/Panorama";
import Support from "@mui/icons-material/Support";
import Payments from "@mui/icons-material/Payments";
import NotesIcon from "@mui/icons-material/Notes";

import DashboardLayout from "../components/common/DashboardLayout";

const mainListItemsArray = [
  {
    id: 1,
    label: "Advisory",
    link: "/advisor/advisory-form",
    icon: <Support />,
  },
  {
    id: 2,
    label: "Trades (Cash & Futures)",
    link: "/advisor/trade-calls?activeTab=cash",
    icon: <Payments />,
  },
  {
    id: 3,
    label: "User Support",
    link: "/advisor/user-support",
    icon: <SupportAgentIcon />,
  },
  {
    id: 4,
    label: "Add Banner",
    link: "/advisor/banner",
    icon: <PanoramaIcon />,
  },
  {
    id: 5,
    label: "Create Ticker",
    link: "/advisor/create-ticker",
    icon: <NotesIcon />,
  },
];

export default function AdvisorDashboardLayout() {
  return <DashboardLayout mainListItems={mainListItemsArray} />;
}
