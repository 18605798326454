import React from "react";
import Banner from "../../AdminDashboard/HomeScreenContent/Banner";

const AdvisorHomeBanner = () => {
  return (
    <div>
      <div style={{ margin: "15px", marginLeft: "30px" }}>
        <h2>Advisor Home Banner</h2>
      </div>

      {/* Banner Component */}
      <Banner />
    </div>
  );
};

export default AdvisorHomeBanner;
