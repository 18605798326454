// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BannerImageTable_mainContainer__1pbk6 {
    margin: 2rem;
}

.BannerImageTable_tableTitle__5MwV8 {
    text-align: center;
    margin-bottom: 1rem;
  }
  
  .BannerImageTable_tableContainer__5IfGh {
    width: 100%;
    max-width: 1400px;
    border-collapse: collapse;
    border: 1px solid #ccc;
    /* background-color: red; */
  }
  
  thead th {
    text-transform: uppercase;
  }
  
  .BannerImageTable_tableContainer__5IfGh th,
  .BannerImageTable_tableContainer__5IfGh td {
    padding: 8px;
    text-align: left;
    border: 1px solid #ccc;
  }
  
  .BannerImageTable_tableContainer__5IfGh th {
    background-color: #f2f2f2;
  }
  
  .BannerImageTable_tableBodyContainer__HHJxw tr:nth-child(even) {
    /* background-color: #f2f2f2; */
  }

  .BannerImageTable_deleteBtnContainer__YbkY7 {
    /* border: 1px solid red; */
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .BannerImageTable_deleteIcon__U9vUZ {
    cursor: pointer;
  }`, "",{"version":3,"sources":["webpack://./src/components/AdminDashboard/HomeScreenContent/Banner/BannerImageTable/BannerImageTable.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;AAChB;;AAEA;IACI,kBAAkB;IAClB,mBAAmB;EACrB;;EAEA;IACE,WAAW;IACX,iBAAiB;IACjB,yBAAyB;IACzB,sBAAsB;IACtB,2BAA2B;EAC7B;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;;IAEE,YAAY;IACZ,gBAAgB;IAChB,sBAAsB;EACxB;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,+BAA+B;EACjC;;EAEA;IACE,2BAA2B;IAC3B,aAAa;IACb,mBAAmB;IACnB,uBAAuB;EACzB;;EAEA;IACE,eAAe;EACjB","sourcesContent":[".mainContainer {\n    margin: 2rem;\n}\n\n.tableTitle {\n    text-align: center;\n    margin-bottom: 1rem;\n  }\n  \n  .tableContainer {\n    width: 100%;\n    max-width: 1400px;\n    border-collapse: collapse;\n    border: 1px solid #ccc;\n    /* background-color: red; */\n  }\n  \n  thead th {\n    text-transform: uppercase;\n  }\n  \n  .tableContainer th,\n  .tableContainer td {\n    padding: 8px;\n    text-align: left;\n    border: 1px solid #ccc;\n  }\n  \n  .tableContainer th {\n    background-color: #f2f2f2;\n  }\n  \n  .tableBodyContainer tr:nth-child(even) {\n    /* background-color: #f2f2f2; */\n  }\n\n  .deleteBtnContainer {\n    /* border: 1px solid red; */\n    display: flex;\n    align-items: center;\n    justify-content: center;\n  }\n\n  .deleteIcon {\n    cursor: pointer;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mainContainer": `BannerImageTable_mainContainer__1pbk6`,
	"tableTitle": `BannerImageTable_tableTitle__5MwV8`,
	"tableContainer": `BannerImageTable_tableContainer__5IfGh`,
	"tableBodyContainer": `BannerImageTable_tableBodyContainer__HHJxw`,
	"deleteBtnContainer": `BannerImageTable_deleteBtnContainer__YbkY7`,
	"deleteIcon": `BannerImageTable_deleteIcon__U9vUZ`
};
export default ___CSS_LOADER_EXPORT___;
