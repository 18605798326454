import React, { useState } from "react";
import CustomHTMLTable from "../../htmlComps/CustomHTMLTable";
import { useMutation, useQuery } from "@tanstack/react-query";
import { axiosConfig } from "../../../axios/axiosConfig";
import { useSelector } from "react-redux";

const rewardListOptions = [
  { id: 1, label: "Subscription", value: "subscription" },
  { id: 2, label: "Alert", value: "alert" },
  { id: 3, label: "Watchlist", value: "watchlist" },
];

const rewardsListApiFromBackend = [
  { id: 1, label: "Reward1", value: "reward1" },
  { id: 2, label: "Reward2", value: "reward2" },
  { id: 3, label: "Reward3", value: "reward3" },
];


// const rewardTableHeadings = [
//   { id: "id", name: "ID" },
//   { id: "name", name: "Reward Name" },
//   { id: "type", name: "Reward Type" },
//   { id: "value", name: "Value" },
// ];
const rewardTableHeadings = [
  { col_id: "id", name: "ID" },
  { col_id: "name", name: "Reward Name" },
  { col_id: "reward_type", name: "Reward Type" },
  { col_id: "reward_value", name: "Value" },
  { col_id: "created_at", name: "Created On" },
  { col_id: "actions", name: "Actions" },
];

// const referralTableHeadings = [
//   { col_id: "id", name: "ID" },
//   { col_id: "name", name: "Reward Name" },
//   { col_id: "reward_type", name: "Reward Type" },
//   { col_id: "reward_value", name: "Value" },
//   { col_id: "created_at", name: "Created On" },
//   { col_id: "actions", name: "Actions" },
// ];

const ReferralRewardProgram = () => {
  const userState = useSelector((store) => store.auth);
  const USERID = userState?.user?.userDetails.id;

  // INPUT STATES FOR REWARD FORM
  const [rewardFormInputs, setRewardFormInputs] = useState({
    rewardName: "",
    rewardType: "subscription",
    rewardValue: "",
  });
  const { rewardName, rewardType, rewardValue } = rewardFormInputs;
  const [rewardsList, setRewardsList] = useState([]);

  // INPUT STATES FOR REFERRAL FORM
  const [referralFormInputs, setReferralFormInputs] = useState({
    referralName: "",
    referralDesc: "",
    referralCount: "",
    rewardItem: "",
    referralActive: true,
  });
  const {
    referralName,
    referralDesc,
    referralCount,
    rewardItem,
    referralActive,
  } = referralFormInputs;
  const [referralsDataList, setReferralsDataList] = useState([])
  

  // onChange handler for rewardFormInputs
  const rewardInputsOnChange = (event) => {
    setRewardFormInputs((prevState) => {
      return {
        ...prevState,
        [event.target.name]: event.target.value,
      };
    });
  };

  // FETCH REWARDS LIST
  const fetchRewardsList = useQuery({
    queryKey: ["rewards-list"], // dependency
    queryFn: () => fetchRewardsData(USERID),
    onSuccess: (data) => {
      setRewardsList(data.response);
    },
    onError: (error) => {
      // console.log("rewards fetch error: ", error);
    },
  });

  // POST REWARD FORM DETAILS
  const postRewardFormDetails = useMutation({
    mutationFn: async () => {
      // if (!currentAdminAccessToken || currentAdminAccessToken.trim() === "") {
      //   return;
      // }

      const res = await axiosConfig({
        method: "post",
        url: "referral/referral_reward",
        data: {
          userid: USERID,
          name: rewardName,
          reward_type: rewardType,
          reward_value: rewardValue,
        },
      });
      return res.data;
    },
    onSuccess: (data) => {
      setRewardsList((prevState) => {
        return [...prevState, data.response];
      });
    },
    onError: (error) => {
      // console.log("error in admin_access api...", error);
      // window.alert(error?.response?.data?.response || "Something went wrong");
    },
  });

  // submit handler for reward form
  const submitRewardForm = () => {
    postRewardFormDetails.mutate();
  };

  // DELETE REWARD ITEM FROM REWARDS TABLE
  const deleteRewardItem = async (reward_id) => {
    try {
      const rewardResponse = await axiosConfig({
        method: "delete",
        url: `referral/referral_reward?referral_reward_id=${reward_id}`,
        data: {},
      });

      setRewardsList((prevState) => {
        return prevState.filter((item) => item.id !== reward_id);
      });
    } catch (error) {
      // console.log("delete reward error: ", error);
    }
  };

  // ***********************  REFERRAL FORM UTILITIES ******************
  const referralInputsOnChange = (event) => {
    // checkbox state vs the rest
    if (event.target.name === "referralActive") {
      setReferralFormInputs(prevState => {
        return {
          ...prevState,
          referralActive: event.target.checked
        }
      })
    } else {
      setReferralFormInputs(prevState => {
        return {
          ...prevState,
          [event.target.name]: event.target.value
        }
      })
    }
  };

  // FETCH REWARDS LIST
  const fetchReferralsList = useQuery({
    queryKey: ["referrals-list"],
    queryFn: () => fetchReferralsData(USERID),
    onSuccess: (data) => {
      setReferralsDataList(data.response);
    },
    onError: (error) => {
      // console.log("referrals fetch error: ", error);
    },
  });

  

  // POST REWARD FORM DETAILS
  const postReferralFormDetails = useMutation({
    mutationFn: async () => {
      
      const res = await axiosConfig({
        method: "post",
        url: "/referral/referral_program",
        data: {
          userid: USERID,
          name: referralName,
          description: referralDesc,
          count: referralCount,
          active: referralActive,
        },
      });
      return res.data;
    },
    onSuccess: (data) => {
      setReferralsDataList((prevState) => {
        return [...prevState, data.response];
      });
    },
    onError: (error) => {
      // console.log("error in post referrals api", error);
      // window.alert(error?.response?.data?.response || "Something went wrong");
    },
  });

  // submit handler for reward form
  const submitReferralForm = () => {
    // console.log("rewardFormInputs: ", rewardFormInputs)
    postReferralFormDetails.mutate();
  };

  // DELETE REWARD ITEM FROM REWARDS TABLE
  // const deleteReferralItem = async (referral_id) => {
  //   try {
  //     const referralRes = await axiosConfig({
  //       method: "delete",
  //       url: `referral/referral_program?referral_program_id=${referral_id}`,
  //       data: {},
  //     });

  //     setReferralsDataList((prevState) => {
  //       return prevState.filter((item) => item.id !== referral_id);
  //     });
  //   } catch (error) {
  //     console.log("delete reward error: ", error);
  //   }
  // };

  return (
    <div>
      <h1>Create Referral Programs</h1>

      {/* CREATE REWARD FORM & TABLE */}
      <div className="rewardForm">
        <h2>Reward Form</h2>

        <div className="rewardNameBox">
          <label htmlFor="">Name:</label>
          <input
            type="text"
            name="rewardName"
            id="rewardName"
            value={rewardName}
            onChange={rewardInputsOnChange}
          />
        </div>

        <div className="rewardTypeBox">
          <label htmlFor="rewardType">Type:</label>
          <select
            name="rewardType"
            id="rewardType"
            value={rewardType}
            onChange={rewardInputsOnChange}
          >
            {rewardListOptions.map((item) => {
              return (
                <option key={item.id} value={item.value}>
                  {item.label}
                </option>
              );
            })}
          </select>
        </div>

        <div className="rewardValueBox">
          <label htmlFor="">Value:</label>
          <input
            placeholder=""
            type="text"
            name="rewardValue"
            id="rewardValue"
            value={rewardValue}
            onChange={rewardInputsOnChange}
          />
        </div>

        <button onClick={submitRewardForm}>submit</button>
      </div>

      {/* REWARDS TABLE */}
      <div className="rewardsTableContainer">
        <CustomHTMLTable
          tableBody={rewardsList}
          columns={rewardTableHeadings}
          deleteItem={deleteRewardItem}
        />
      </div>

      {/* CREATE REFERRAL FORM */}
      <div className="referralForm">
        <h2>Referral Program Form</h2>

        <div className="referralNameBox">
          <label htmlFor="referralName">Name:</label>
          <input
            placeholder=""
            type="text"
            name="referralName"
            id="referralName"
            value={referralName}
            onChange={referralInputsOnChange}
          />
        </div>

        <div className="referralNameBox">
          <label htmlFor="referralDesc">Description:</label>
          <input
            placeholder=""
            type="text"
            name="referralDesc"
            id="referralDesc"
            value={referralDesc}
            onChange={referralInputsOnChange}
          />
        </div>

        <div className="referralCountBox">
          <label htmlFor="referralCount">Referral Count:</label>
          <input
            placeholder=""
            type="text"
            name="referralCount"
            id="referralCount"
            value={referralCount}
            onChange={referralInputsOnChange}
          />
        </div>

        <div className="rewardTypeBox">
          <label htmlFor="rewardItem">Select Reward Type:</label>
          <select name="rewardItem" id="rewardItem" value={rewardItem} onChange={referralInputsOnChange} >
            {rewardsListApiFromBackend?.map((item) => {
              return (
                <option key={item.id} value={item.value}>
                  {item.label}
                </option>
              );
            })}
          </select>
        </div>

        <div className="rewardActiveBox">
          <label htmlFor="referralActive">Referral Active:</label>
          <input
            type="checkbox"
            name="referralActive"
            id="referralActive"
            defaultChecked={referralActive}
            value={referralActive}
            onChange={referralInputsOnChange}
          />
        </div>

        <button onClick={submitReferralForm} >submit</button>
      </div>

      {/* REFERRALS TABLE */}
    </div>
  );
};

export default ReferralRewardProgram;

// UTILITY FUNCTIONS
export const fetchRewardsData = async (user_id) => {
  if (!user_id) return;

  const res = await axiosConfig({
    method: "get",
    url: `/referral/referral_reward?userid=${user_id}`,
    data: {},
  });

  return res.data;
};

export const fetchReferralsData = async (user_id) => {
  if (!user_id) return;

  const res = await axiosConfig({
    method: "get",
    url: `/referral/referral_program?userid=${user_id}`,
    data: {},
  });

  return res.data;
};
