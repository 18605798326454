import React, { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { axiosConfig } from "../../../axios/axiosConfig";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import { useNavigate, useSearchParams } from "react-router-dom";
import SelectedTradeTab from "./SelectedTradeTab";

import styles from "./AdvisorTradeCalls.module.css";

const tradeCallTabsArray = [
  { id: 1, label: "Cash", value: "cash" },
  //   { id: 2, label: "Options", value: "options" },
  { id: 3, label: "Futures", value: "futures" },
];

const AdvisorTradeCalls = () => {
  const { user } = useSelector((state) => state.auth);
  const USERID = user?.userDetails?.id;

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const activeTabFromURL = searchParams.get("activeTab");

  //   const [selectedTab, setSelectedTab] = useState(activeTabFromURL || "cash");
  const [isEditing, setIsEditing] = useState(false);

  const [openTrades, setOpenTrades] = useState([]);
  const [closedTrades, setClosedTrades] = useState([]);
  const allTradesList = [...openTrades, ...closedTrades];
  const [activeAdvisoryObj, setActiveAdvisoryObj] = useState({});
  // const exitPriceRef = useRef(null) // not working

  const initialTradesFormInput = {
    symbolName: "AVROIND",
    segment: "INTRADAY", // BTST, INTRADAY, SWING (drop down field)
    signal: "long", // long or short for 'long buy, 'short sell'
    entryPrice: "", // suggested price, NO RUPEE symbols in entry, target and stop loss
    targetPrice: "",
    stopLoss: "",
    // description: "",
    exitPrice: "N/A", // EXIT price is only visible for EDIT / UPDATE
    lotSize: "", // 150.0 - FOR FUTURES ONLY
    lotExpiry: "", // "26 dec"  - FOR FUTURES ONLY
    profitLoss: "",
    tradeClosedInDays: "",
  };
  const [tradesFormInput, setTradesFormInput] = useState(
    initialTradesFormInput
  );

  // CLEAR / RESET advisoryFormInput
  const clearTradesFormInput = () => {
    setTradesFormInput(initialTradesFormInput);
  };

  // FETCHING OPEN TRADE CALLS, (cash, options, and futures)
  const openTradeCallsQuery = useQuery({
    retry: false,
    refetchOnWindowFocus: false, // Prevent refetch on window focus
    refetchInterval: false, // Disable automatic refetching
    queryKey: ["open-trades", activeTabFromURL], // dependency
    queryFn: () => fetchOpenTrades(USERID, activeTabFromURL),
    onSuccess: (data) => {
      //   setAllTradesList(data.response?.data);
      setOpenTrades(data.response?.data);
    },
    onError: (error) => {
      // console.log(" error: ", error);
      toast.error(error?.response?.data?.response || "Something went wrong");
    },
  });

  // FETCHING OPEN TRADE CALLS, (cash, options, and futures)
  const closeTradeCallsQuery = useQuery({
    retry: false,
    refetchOnWindowFocus: false, // Prevent refetch on window focus
    refetchInterval: false, // Disable automatic refetching
    queryKey: ["closed-trades", activeTabFromURL], // dependency
    queryFn: () => fetchClosedTrades(USERID, activeTabFromURL),
    onSuccess: (data) => {
      setClosedTrades(data.response?.data);
    },
    onError: (error) => {
      // console.log(" error: ", error);
      toast.error(error?.response?.data?.response || "Something went wrong");
    },
  });

  // this function copies the object row in tradesTable to tradesFormInput, makes it active
  const handleEditBtn = (item_obj) => {
    const { symbol_name, segment, entry_price, target_price, stop_loss } =
      item_obj;
    const tempTradesFormInput = {
      symbolName: symbol_name,
      segment: segment,
      entryPrice: entry_price, // suggested price, NO RUPEE symbols in entry, target and stop loss
      targetPrice: target_price,
      stopLoss: stop_loss,
      lotSize: item_obj?.lot_size,
      lotExpiry: item_obj?.lot_expiry,
      //   description: item_obj?.description,
    };

    setIsEditing(true);
    setActiveAdvisoryObj(item_obj);
    setTradesFormInput(tempTradesFormInput);
    // exitPriceRef.current.focus()

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth", // For smooth scrolling, or 'auto' for instant
    });
  };

  return (
    <div>
      <div style={{ margin: "15px", marginLeft: "20px" }}>
        <h2 className="mainHeading">Trades &#40;Cash and Futures&#41;</h2>
      </div>

      {/* tabs: cash, options, futures */}
      <div
        style={{ margin: "10px", marginLeft: "32px" }}
        className={styles.tabsList}
      >
        {tradeCallTabsArray.map((item) => {
          return (
            <span
              className={`${styles.tabItem} ${
                item.value === "options" ? styles.notActive : ""
              } ${item.value === activeTabFromURL ? styles.selected : ""}`}
              //   onClick={() => setSelectedTab(item.value)}
              onClick={() =>
                navigate(`/advisor/trade-calls?activeTab=${item.value}`)
              }
            >
              {item.label}
            </span>
          );
        })}
      </div>

      <SelectedTradeTab
        selectedTradeType={activeTabFromURL}
        isEditing={isEditing}
        setIsEditing={setIsEditing}
        tradesFormInput={tradesFormInput}
        setTradesFormInput={setTradesFormInput}
        clearTradesFormInput={clearTradesFormInput}
        activeAdvisoryObj={activeAdvisoryObj}
        allTradesList={allTradesList}
        handleEditBtn={handleEditBtn}
      />
    </div>
  );
};

export default AdvisorTradeCalls;

// UTILITIES
// fetching all trades (cash, futures, options) (open or closed)
const fetchOpenTrades = async (user_id, selected_trade_type) => {
  // if (!user_id) return;

  const res = await axiosConfig({
    method: "get",
    // url: "/predictions/stocks/advisory?status=OPEN",
    url: `/predictions/call/${user_id}/${selected_trade_type}`,
    data: {},
  });

  return res.data;
};

const fetchClosedTrades = async (user_id, selected_trade_type) => {
  // if (!user_id) return;

  const res = await axiosConfig({
    method: "get",
    // url: "/predictions/stocks/advisory?status=OPEN",
    // url: `/predictions/call/${user_id}/${selected_trade_type}`,
    url: `predictions/${selected_trade_type}/close`,
    data: {},
  });

  return res.data;
};
