import { useMutation } from "@tanstack/react-query";
import React, { useState } from "react";
import { axiosConfig } from "../../../../axios/axiosConfig";
import { useSelector } from "react-redux";

import styles from "./UpdateNameEmailModal.module.css";

const UpdateNameEmailModal = () => {
  const { user } = useSelector((state) => state.auth);
  const USERID = user?.userDetails?.id;

  const currentFullName = user?.userDetails?.username;
  const currentEmail = user?.userDetails?.email;

  // const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState(currentFullName);
  const [emailInput, setEmailInput] = useState(currentEmail);
  // const [emailOTPInput, setEmailOTPInput] = useState("");

  // console.log("user?.userDetails: ", user?.userDetails)

  // const fullNameInput = `${firstName} ${lastName}`;

  // add / update user details
  const updateUserDetailsMutation = useMutation({
    mutationFn: async () => {
      const res = await axiosConfig({
        method: "patch",
        url: `/user/userprofile?userid=${USERID}`,
        data: {
          userid: USERID,
          username: lastName,
          email: emailInput,
        },
      });
      return res.data;
    },
    onSuccess: (data) => {
      console.log("update name, email: ", data);
      // setUserCouponsData((prevState) => {
      //   return [...prevState, data.response];
      // });
    },
    onError: (error) => {
      console.log("user update error: ", error);
      // toast.error(error.message || "Something went wrong");
    },
  });

  const handleSaveDetails = () => {
    updateUserDetailsMutation.mutate();
  };

  return (
    <div className={styles.mainContainer}>
      {/* <h2 className={styles.mainTitle} >Update Name and Email</h2> */}
      <h2 className="topHeadingUnderline">Update Name and Email</h2>

      {/* <div className="firstNameContainer">
        <label>First Name:</label>

        <input
          className="firstName"
          type="text"
          name="firstName"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
        />
      </div> */}

      <div className={styles.lastNameContainer}>
        <label>Full Name:</label>

        <input
          // className={styles.lastName}
          className="commonTextInput"
          type="text"
          name="lastName"
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
        />
      </div>

      {/* EMAIL OTP VERIFICATION BOX */}
      <div className={styles.emailVerificationContainer}>
        <div className={styles.emailContainer}>
          <label>Email</label>

          <input
            // className={styles.emailInput}
            className="commonTextInput"
            type="email"
            name="emailInput"
            value={emailInput}
            onChange={(e) => setEmailInput(e.target.value)}
          />

          {/* <button>send email verification code</button> */}
        </div>

        {/* <div className="emailOTPContainer">
          <label>Email Code:</label>
          <input
            className="emailOTPInput"
            type="text"
            name="emailOTPInput"
            value={emailOTPInput}
            onChange={(e) => setEmailOTPInput(e.target.value)}
          />
        </div> */}
      </div>

      <div className={styles.saveBtnContainer}>
        <button className={styles.saveBtn} onClick={handleSaveDetails}>
          save
        </button>
      </div>

      {/* OUTER DIV */}
    </div>
  );
};

export default UpdateNameEmailModal;
