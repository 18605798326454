import React, { useRef, useState } from "react";
import { useParams } from "react-router-dom";

import { useMutation, useQuery } from "@tanstack/react-query";

import styles from "./SingleTicketPageComments.module.css";
import { axiosConfig } from "../../axios/axiosConfig";
import ReactHotToaster from "../../components/common/ReactHotToaster";
import BackButton from "../../components/common/BackButton";
import toast from "react-hot-toast";

const ticketStatusOptions = [
  { id: 1, value: "Open", label: "Open" },
  { id: 2, value: "Closed", label: "Closed" },
  { id: 3, value: "InProgress", label: "InProgress" },
  { id: 4, value: "Deferred", label: "Deferred" },
];

const priorityOptions = [
  { id: 1, value: "Low", label: "Low" },
  { id: 2, value: "Medium", label: "Medium" },
  { id: 3, value: "High", label: "High" },
];

// Low, Medium, High

const SingleTicketPageComments = ({ userType }) => {
  const { ticketID } = useParams();
  const commentInputRef = useRef();

  const [singleTicketDetail, setSingleTicketDetail] = useState([]);
  const [ticketComments, setTicketComments] = useState([]);

  // ticketStatus Input and ticketStatus from backend
  const currentTicketStatus = singleTicketDetail?.status; // ticket status stored at backend
  // const [ticketStatus, setTicketStatus] = useState(ticketStatusOptions[0].value);  // status - Open, Closed, Deferred, InProgress
  const [ticketStatusInput, setTicketStatusInput] = useState(""); // status - Open, Closed, Deferred, InProgress

  const currentPriority = singleTicketDetail?.priority; // priority stored at backend
  // const [priorityInput, setPriorityInput] = useState(priorityOptions[0].value);  // priority - High, Medium, Low
  const [priorityInput, setPriorityInput] = useState(
    singleTicketDetail?.priority || "Low"
  ); // priority - High, Medium, Low

  // ticket status options change handler
  const ticketStatusOnChange = (event) => {
    setTicketStatusInput(event.target.value);
  };
  // priority options change handler
  const prirityOptionOnChange = (event) => {
    setPriorityInput(event.target.value);
  };

  // post comment on user ticket
  const postCommentMutation = useMutation({
    mutationFn: () => postCommentOnTicket(ticketID, commentInputRef),
    onSuccess: (data) => {
      // console.log("ticket status data: ", data);
      setTicketComments((prevState) => {
        return [...prevState, data.response[0]];
      });
      // window.alert(data.response);
      commentInputRef.current.value = null;
    },
    onError: (error) => {
      console.log("ticket status error: ", error);
      toast.error(error?.response?.data?.response || "Something went wrong");
    },
  });

  const handlePostMsg = () => {
    postCommentMutation.mutate();
  };

  // change is ticket checked to true
  const setTicketCheckedMutation = useMutation({
    mutationFn: (ticket_id) => patchIsTickedChecked(ticket_id),
    onSuccess: (data) => {},
    onError: (error) => {
      console.log("raise ticket error: ", error);
      toast.error(error?.response?.data?.response || "Something went wrong");
    },
    onSettled: (data) => {
      // commentInputRef?.current?.value = null;
    },
  });

  // useEffect to patch isticketStatus
  // useEffect(() => {
  //   const isTicketChecked = singleTicketDetail?.isticketcheck;

  //   if (!isTicketChecked) {
  //     setTicketCheckedMutation.mutate(singleTicketDetail?.id);
  //   }
  // }, []);

  // FETCH specific ticket details
  const singleTicketQuery = useQuery({
    queryKey: ["single-ticket"],
    // enabled: false,
    refetchOnWindowFocus: false,
    queryFn: () => fetchSingleTicket(ticketID),
    onSuccess: (data) => {
      // console.log(" ticket res: ", data);
      const ticketDetails = data.response[0]?.ticket_details[0];
      setTicketComments(data.response[0].comments);
      setSingleTicketDetail(ticketDetails);
      setTicketStatusInput(ticketDetails?.status);
      setPriorityInput(ticketDetails?.priority);

      // when isticketcheck is false initially, make it true.
      if (!ticketDetails.isticketcheck) {
        // make it true
        setTicketCheckedMutation.mutate(ticketDetails.id);
      }
    },
    onError: (error) => {
      console.log("error: ", error);
    },
  });

  // PATCH STATUS & priority (PENDING, RESOLVED, CLOSED)
  const setStatusMutation = useMutation({
    mutationFn: () =>
      patchTicketStatusPriority(ticketID, ticketStatusInput, priorityInput),
    onSuccess: (data) => {
      const dataRes = data?.response;
      setSingleTicketDetail((prevState) => {
        return {
          ...prevState,
          status: dataRes.status,
          priority: dataRes.priority,
        };
      });
    },
    onError: (error) => {
      console.log("raise ticket error: ", error);
    },
    onSettled: (data) => {
      // commentInputRef?.current?.value = null;
    },
  });

  // SET STATUS HANDLER
  const handleSetStatus = () => {
    setStatusMutation.mutate();
  };

  const formatedCreatedAtDate = singleTicketDetail?.created_at
    ?.slice(0, 19)
    .split("T")
    .join(" ");

  return (
    <div className={styles.compContainer}>
      <BackButton />

      <h2 className={styles.pageTitle}>User Conversation</h2>

      {/* TICKET OPEN CLOSE */}
      <div className={styles.ticketStatusContainer}>
        <h3>Ticket Status & Priority</h3>

        <p className={styles.ticket_id}>
          ticket id: <span>{singleTicketDetail?.ticket_id}</span>
        </p>

        {/* Status - open, closed,deferred, inprogress */}
        <div className={styles.ticketStatusBox}>
          <div className={styles.statusPriorityContainer}>
            <div
              className={`${styles.ticketStatusTitle} ${styles[currentTicketStatus]}`}
            >
              <p>
                Status: <span>{currentTicketStatus}</span>
              </p>
            </div>

            <div className={styles.priorityTitle}>
              <p>
                PRIORITY: <span>{currentPriority}</span>
              </p>
            </div>
          </div>

          {/* SELECT PRIORITY & STATUS CONTAINER */}
          {userType.toLowerCase() === "admin" ? (
            <>
              <div className={styles.statusPriorityInputContainer}>
                <div className={styles.changeTicketStatusContainer}>
                  <select
                    value={ticketStatusInput}
                    onChange={ticketStatusOnChange}
                  >
                    <option>Set Status</option>
                    {ticketStatusOptions.map((option, index) => {
                      return (
                        <option key={index} value={option.value}>
                          {option.label}
                        </option>
                      );
                    })}
                  </select>
                </div>

                <div className={styles.changeTicketStatusContainer}>
                  <select
                    value={priorityInput}
                    onChange={prirityOptionOnChange}
                    disabled={
                      !currentTicketStatus ||
                      currentTicketStatus.toLowerCase() === "closed"
                    }
                  >
                    <option>Set Priority</option>
                    {priorityOptions.map((option, index) => {
                      return (
                        <option key={index} value={option.value}>
                          {option.label}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>

              <div className={styles.saveBtnContainer}>
                <button
                  className={styles.setStatusBtn}
                  onClick={() => handleSetStatus(ticketStatusInput)}
                >
                  SAVE
                </button>
              </div>
            </>
          ) : null}
        </div>
      </div>

      {/* DOWNLOAD FILE */}
      {singleTicketDetail?.file ? (
        <div className={styles.downloadFileContainer}>
          <div className={styles.downloadFileTitle}>
            <p>File Attached: </p>
          </div>
          <div className={styles.downloadFileLink}>
            <a href={singleTicketDetail?.file} download>
              Download File
            </a>
          </div>
        </div>
      ) : null}

      {/* user description container */}
      <div
        style={{ marginLeft: "15px" }}
        className={styles.descriptionContainer}
      >
        <div className={styles.descriptionBox}>
          <div className={styles.descriptionTitle}>
            <p className={styles.createdDate}>{formatedCreatedAtDate}</p>
            <h3>{singleTicketDetail?.title}</h3>
          </div>
          <div className={styles.descriptionText}>
            <p>{singleTicketDetail?.description}</p>
          </div>
        </div>
      </div>

      {/* msg response discussion comment container */}
      <div className={styles.msgResponseContainer}>
        {ticketComments?.map((comment) => {
          return <MessageBox commentList={comment} />;
        })}
      </div>

      {/* text box msg input container */}
      {!currentTicketStatus ||
      currentTicketStatus.toLowerCase() === "closed" ? null : (
        <div className={styles.msgInputBoxContainer}>
          <textarea
            className={styles.msgInputText}
            ref={commentInputRef}
            placeholder="Type your response here..."
            name=""
            id=""
            cols="30"
            rows="10"
          ></textarea>
          <div className={styles.btnContainer}>
            <button
              onClick={handlePostMsg}
              className={styles.postBtn}
              disabled={!ticketID}
            >
              post
            </button>
          </div>
        </div>
      )}

      {/* REACT HOT TOAST */}
      <ReactHotToaster />
    </div>
  );
};

export default SingleTicketPageComments;

const MessageBox = ({ commentList }) => {
  const { comments, commenter_type, commenter_name, created_at } = commentList;
  const formatedCreatedAtDate = new Date(created_at).toDateString();

  return (
    <div
      className={`${styles.msgResponseBox} ${
        commenter_type === "Admin" ? styles.admin : styles.user
      }`}
    >
      <div className={styles.msgResponse}>
        <div className={styles.msgResponseHeader}>
          <p className={styles.msgResponseName}>{commenter_name}</p>
          <p className={styles.msgResponseTime}>{formatedCreatedAtDate}</p>
        </div>
        <p className={styles.msgResponseContent}>{comments}</p>
      </div>
    </div>
  );
};

// UTILITY FUNCTIONS
const fetchSingleTicket = async (ticket_id) => {
  if (!ticket_id) return;

  const res = await axiosConfig({
    method: "get",
    url: `support/ticket_comment?id=${ticket_id}`,
    data: {},
  });

  return res.data;
};

// comment on ticket
const postCommentOnTicket = async (ticket_id, commentInputRef) => {
  const commentRefValue = commentInputRef.current?.value;

  if (!ticket_id || !commentRefValue) return;

  const res = await axiosConfig({
    method: "post",
    url: "/support/ticket_comment",
    data: {
      id: ticket_id,
      comments: commentRefValue,
    },
  });

  return res.data;
};

// patch ticket status - Open, Closed, Deferred, InProgress
const patchTicketStatusPriority = async (
  ticket_id,
  ticketStatusInput,
  priority
) => {
  if (!ticket_id || !ticketStatusInput || !priority) {
    window.alert("Ticked ID, Status or Priority is not Set!!");
    return;
  }

  const res = await axiosConfig({
    method: "patch",
    url: "/support/raise_request",
    data: {
      id: ticket_id,
      status: ticketStatusInput,
      priority: priority,
    },
  });

  return res.data;
};

const patchIsTickedChecked = async (ticketID) => {
  if (!ticketID) return;

  const res = await axiosConfig({
    method: "patch",
    url: "/support/ticket_status",
    data: {
      // isticketcheck: isTickedChecked,
      id: ticketID,
    },
  });

  return res.data;
};
