// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.RaiseTicketModal_userSupportBox__73P9D {
  --borderRadius: 0.2rem;

  min-width: 350px;
  padding: 1rem;
  border: 1px solid #ccc;
  border-radius: var(--borderRadius);
}

.RaiseTicketModal_userSupportBox__73P9D h2 {
  text-align: center;
  margin-bottom: 1rem;
}

.RaiseTicketModal_subjectLabel__Ds1an,
.RaiseTicketModal_descriptionLabel__JfoOQ {
  text-transform: uppercase;
}

.RaiseTicketModal_titleInput__8gCO2,
.RaiseTicketModal_descInput__AlZAM,
.RaiseTicketModal_fileInput__DIK4h {
  outline: none;
  width: 100%;
  margin: 0.4rem 0 1rem;
  border: 1px solid #ccc;
  border-radius: var(--borderRadius);
}

.RaiseTicketModal_titleInput__8gCO2 {
  height: 30px;
}

.RaiseTicketModal_descInput__AlZAM {
  resize: none;
}

.RaiseTicketModal_submitButton__X88mV {
  outline: none;
  margin: 0.5rem 0;
  padding: 0.2rem 1rem;
  text-transform: uppercase;
  cursor: pointer;
}

.RaiseTicketModal_submitButton__X88mV:hover {
  background-color: #333;
  color: #fff;
}`, "",{"version":3,"sources":["webpack://./src/components/UserDashboard/Support/RaiseTicketModal/RaiseTicketModal.module.css"],"names":[],"mappings":";AACA;EACE,sBAAsB;;EAEtB,gBAAgB;EAChB,aAAa;EACb,sBAAsB;EACtB,kCAAkC;AACpC;;AAEA;EACE,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;;EAEE,yBAAyB;AAC3B;;AAEA;;;EAGE,aAAa;EACb,WAAW;EACX,qBAAqB;EACrB,sBAAsB;EACtB,kCAAkC;AACpC;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,aAAa;EACb,gBAAgB;EAChB,oBAAoB;EACpB,yBAAyB;EACzB,eAAe;AACjB;;AAEA;EACE,sBAAsB;EACtB,WAAW;AACb","sourcesContent":["\n.userSupportBox {\n  --borderRadius: 0.2rem;\n\n  min-width: 350px;\n  padding: 1rem;\n  border: 1px solid #ccc;\n  border-radius: var(--borderRadius);\n}\n\n.userSupportBox h2 {\n  text-align: center;\n  margin-bottom: 1rem;\n}\n\n.subjectLabel,\n.descriptionLabel {\n  text-transform: uppercase;\n}\n\n.titleInput,\n.descInput,\n.fileInput {\n  outline: none;\n  width: 100%;\n  margin: 0.4rem 0 1rem;\n  border: 1px solid #ccc;\n  border-radius: var(--borderRadius);\n}\n\n.titleInput {\n  height: 30px;\n}\n\n.descInput {\n  resize: none;\n}\n\n.submitButton {\n  outline: none;\n  margin: 0.5rem 0;\n  padding: 0.2rem 1rem;\n  text-transform: uppercase;\n  cursor: pointer;\n}\n\n.submitButton:hover {\n  background-color: #333;\n  color: #fff;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"userSupportBox": `RaiseTicketModal_userSupportBox__73P9D`,
	"subjectLabel": `RaiseTicketModal_subjectLabel__Ds1an`,
	"descriptionLabel": `RaiseTicketModal_descriptionLabel__JfoOQ`,
	"titleInput": `RaiseTicketModal_titleInput__8gCO2`,
	"descInput": `RaiseTicketModal_descInput__AlZAM`,
	"fileInput": `RaiseTicketModal_fileInput__DIK4h`,
	"submitButton": `RaiseTicketModal_submitButton__X88mV`
};
export default ___CSS_LOADER_EXPORT___;
