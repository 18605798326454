// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.SupbscriptionPlan_activeTopDetailsContainer__x6tTV {
  margin: 2rem;
  padding: 2rem 1rem;
  max-width: 350px;
  border: 1px solid #ccc;
  border-radius: 0.5rem;
}

.SupbscriptionPlan_activeTopDetailsContainer__x6tTV h2 {
  text-align: center;
  margin-bottom: 1rem;
}

.SupbscriptionPlan_detailItem__njKTV {
  display: flex;
  align-items: center;
  justify-content: space-between;

  border-bottom: 1px solid #ccc;
}
.SupbscriptionPlan_detailItem__njKTV h3 {
  font-weight: 300;
  text-transform: uppercase;
}`, "",{"version":3,"sources":["webpack://./src/components/UserDashboard/SubscriptionPlan/SupbscriptionPlan.module.css"],"names":[],"mappings":";AACA;EACE,YAAY;EACZ,kBAAkB;EAClB,gBAAgB;EAChB,sBAAsB;EACtB,qBAAqB;AACvB;;AAEA;EACE,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;;EAE9B,6BAA6B;AAC/B;AACA;EACE,gBAAgB;EAChB,yBAAyB;AAC3B","sourcesContent":["\n.activeTopDetailsContainer {\n  margin: 2rem;\n  padding: 2rem 1rem;\n  max-width: 350px;\n  border: 1px solid #ccc;\n  border-radius: 0.5rem;\n}\n\n.activeTopDetailsContainer h2 {\n  text-align: center;\n  margin-bottom: 1rem;\n}\n\n.detailItem {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n\n  border-bottom: 1px solid #ccc;\n}\n.detailItem h3 {\n  font-weight: 300;\n  text-transform: uppercase;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"activeTopDetailsContainer": `SupbscriptionPlan_activeTopDetailsContainer__x6tTV`,
	"detailItem": `SupbscriptionPlan_detailItem__njKTV`
};
export default ___CSS_LOADER_EXPORT___;
