import React, { useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  FormGroup,
  Stack,
  Typography,
} from "@mui/material";
import { useLocation } from "react-router-dom";

import axiosConfig from "../../axios/axiosConfig";
import { useSelector } from "react-redux";
import { displayRazorpay } from "../../utils/razorpay/checkout";
// import PayPal from "../../components/payment-gateways/PayPal";
import PayPalMain from "../../components/payment-gateways/PayPalMain";

const addOnsList = [
  {
    id: 1,
    label: "Commodities",
    value: "mcx",
    checked: false,
    price: 5,
  },
  { id: 2, label: "Crypto", value: "crypto", checked: false, price: 10 },
  {
    id: 3,
    label: "Options with Chart & Signals",
    value: "options",
    checked: false,
    price: 5,
  },
  {
    id: 4,
    label: "F&O",
    value: "f&o",
    checked: false,
    price: 5,
  },
  {
    id: 5,
    label: "Cash",
    value: "cash",
    checked: false,
    price: 15,
  },
  {
    id: 6,
    label: "Nifty 50",
    value: "nifty_50",
    checked: false,
    price: 10,
  },
  {
    id: 7,
    label: "FOREX",
    value: "forex",
    checked: false,
    price: 5,
  },
  {
    id: 8,
    label: "Simulator",
    value: "simulator",
    checked: false,
    price: 10,
  },
];

const AddOnsForm = () => {
  const { user: currentUser } = useSelector((state) => state.auth);
  const USERID = currentUser.userDetails.id;
  const location = useLocation();
  const SELECTED_PLAN = location?.state?.selectedTier.title;
  // const SELECTED_PLAN = location?.state?.selectedTier;
  
  const [selectedAddOns, setSelectedAddOns] = useState(addOnsList); // An Array of AddOns

  const handleAddOnsChange = (event) => {
    const newAddOnsList = selectedAddOns.map((addOn) => {
      if (addOn.value === event.target.value) {
        return { ...addOn, checked: event.target.checked };
      }
      return addOn;
    });
    setSelectedAddOns(newAddOnsList);
  };

  // Calculate total amount of Plan + Add Ons
  const calculateTotalAmount = () => {
    // calculate Total Price for Add Ons
    let addOnsTotalPrice = selectedAddOns
      .filter((item) => item.checked)
      .reduce((total, item) => item.price + total, 0);

    return addOnsTotalPrice + (location?.state?.totalPayableAmount || 0);
  };

  const total_payable_amount = calculateTotalAmount()

  //
  const handleSubscribe = async () => {
    // Adds Ons separated by commas, ie "Commodities,Crypto"
    const addOn_String = selectedAddOns
      .filter((item) => item.checked)
      .map((item) => item.value)
      .join(",");

    if (!addOn_String || addOn_String === "") return;

    try {
      const res = await axiosConfig({
        method: "post",
        url: "/subscription/user_subscription",
        data: {
          plan_type: SELECTED_PLAN,
          add_on: addOn_String,
          userid: USERID,
        },
      });

      console.log(res);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Container maxWidth="md">
      <h1>Add Ons</h1>

      <Box>
        <Stack direction="row" spacing={3}>
          <Typography variant="h5">Selected Plan: </Typography>
          <Typography variant="h5">
            {location?.state?.selectedTier.title.toUpperCase()}
            {/* {location?.state?.selectedTier.toUpperCase()} */}
          </Typography>
        </Stack>

        <Stack direction="row" spacing={6}>
          <Typography variant="h5">Plan Period: </Typography>
          <Typography variant="h5">
            {location?.state?.planPeriod.toUpperCase()}
          </Typography>
        </Stack>
      </Box>

      <Box sx={{ mt: 3 }}>
        <Stack
          direction="row"
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: 300,
          }}
        >
          <Typography variant="h5">Add Ons</Typography>
          <Typography variant="h5">Price</Typography>
        </Stack>

        <FormGroup>
          {selectedAddOns.map((addOn) => {
            return (
              <Stack
                direction="row"
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: 300,
                }}
              >
                <FormControlLabel
                  key={addOn.id}
                  control={
                    <Checkbox
                      value={addOn.value}
                      checked={addOn.checked}
                      onChange={handleAddOnsChange}
                      // inputProps={{ "aria-label": "controlled" }}
                    />
                  }
                  label={addOn.label}
                />

                <Typography variant="subtitle">{addOn.price}/-</Typography>
              </Stack>
            );
          })}
        </FormGroup>
      </Box>

      <Box sx={{ mt: 2 }}>
        {/* Getting Location state from SubscriptionPlan ('/pricing') component */}
        <Typography variant="h4">
          Total Payable: {total_payable_amount}
        </Typography>
        <Button variant="contained" onClick={handleSubscribe} sx={{ mt: 2 }}>
          Confirm & Pay
        </Button>
        <Button variant="contained" onClick={() => displayRazorpay(USERID, total_payable_amount, 'INR')} sx={{ mt: 2 }}>
          TEST RAZORPAY
        </Button>
      </Box>


      {/* Temporary PAYPAL COMPONENT */}
      {/* <PayPal /> */}

      <PayPalMain total_payable_amount={total_payable_amount} />
    </Container>
  );
};

export default AddOnsForm;

// UTILITY FUNCTIONS
// function calcTotalPriceWithAddOns() {}
