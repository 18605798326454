export const addCommaToCurrency = (currency) => {
  if (currency == null) return "";

  // Convert to a fixed number (2 decimals only if needed)
  const currString = parseFloat(currency).toFixed(2).replace(/\.00$/, "");
  const [integerPart, decimalPart] = currString.split(".");

  // Format the integer part with commas
  const formattedIntegerPart = integerPart
    .split("")
    .reverse()
    .reduce((acc, item, ind, arr) => {
      if (ind % 2 === 0 && ind !== 0 && arr.length - 1 !== ind) {
        acc.push(item);
        acc.push(",");
      } else {
        acc.push(item);
      }
      return acc;
    }, [])
    .reverse()
    .join("");

  // Return the formatted number
  return decimalPart
    ? `${formattedIntegerPart}.${decimalPart}`
    : formattedIntegerPart;
};
