import React from 'react'
import Banner from '../../AdminDashboard/HomeScreenContent/Banner'

const AdvisorHomeBanner = () => {
  return (
    <div>
        <h2>Advisor Home Banner</h2>

        {/* Banner Component */}
        <Banner />
    </div>
  )
}

export default AdvisorHomeBanner