// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AdminMobileOTPLoginForm_mainContainer__8ictS {
  width: 100vw;
  height: 100vh;
}

.AdminMobileOTPLoginForm_formContainer__NBYOc {
  max-width: 350px;
  margin: 0 auto;
  margin-top: 4rem;
  padding: 2rem;
  border: 3px solid #ccc;
  border-style: double;
  border-radius: 0.2rem;
  background-color: rgb(243, 242, 242);
}

.AdminMobileOTPLoginForm_title__7gN5g,
.AdminMobileOTPLoginForm_subtitle__yMahe {
  text-align: center;
}

.AdminMobileOTPLoginForm_subtitle__yMahe {
  margin: 1rem 0;
  text-transform: uppercase;
  letter-spacing: 4px;
  font-weight: 400;
}

.AdminMobileOTPLoginForm_inputBox__qi7de {
  margin: 0.5rem 0;
  display: flex;
  /* align-items: center; */
  justify-content: space-between;
}

.AdminMobileOTPLoginForm_mobileInput__99azz,
.AdminMobileOTPLoginForm_mobileOTP__NiEhA {
  /* border: none; */
  padding: 0.5rem;
  outline: none;
  border: 1px solid #ccc;
  border-radius: 0.2rem;
  width: 60%;
}

.AdminMobileOTPLoginForm_buttonContainer__ux-Dj {
  margin: 1rem 0;
  width: 100%;
  display: flex;
  justify-content: center;
}

.AdminMobileOTPLoginForm_submitButton__yl0bV {
  outline: none;
  padding: 0.2rem 0.5rem;
  /* background-color: #aaa;
  color: white; */

  border: 1px solid #ccc;
  border-radius: 0.2rem;
  cursor: pointer;
}

.AdminMobileOTPLoginForm_submitButton__yl0bV:hover {
  background-color: #ccc;
}`, "",{"version":3,"sources":["webpack://./src/Routes/AdminRoute/AdminMobileOTPLoginForm/AdminMobileOTPLoginForm.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;AACf;;AAEA;EACE,gBAAgB;EAChB,cAAc;EACd,gBAAgB;EAChB,aAAa;EACb,sBAAsB;EACtB,oBAAoB;EACpB,qBAAqB;EACrB,oCAAoC;AACtC;;AAEA;;EAEE,kBAAkB;AACpB;;AAEA;EACE,cAAc;EACd,yBAAyB;EACzB,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,aAAa;EACb,yBAAyB;EACzB,8BAA8B;AAChC;;AAEA;;EAEE,kBAAkB;EAClB,eAAe;EACf,aAAa;EACb,sBAAsB;EACtB,qBAAqB;EACrB,UAAU;AACZ;;AAEA;EACE,cAAc;EACd,WAAW;EACX,aAAa;EACb,uBAAuB;AACzB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB;iBACe;;EAEf,sBAAsB;EACtB,qBAAqB;EACrB,eAAe;AACjB;;AAEA;EACE,sBAAsB;AACxB","sourcesContent":[".mainContainer {\n  width: 100vw;\n  height: 100vh;\n}\n\n.formContainer {\n  max-width: 350px;\n  margin: 0 auto;\n  margin-top: 4rem;\n  padding: 2rem;\n  border: 3px solid #ccc;\n  border-style: double;\n  border-radius: 0.2rem;\n  background-color: rgb(243, 242, 242);\n}\n\n.title,\n.subtitle {\n  text-align: center;\n}\n\n.subtitle {\n  margin: 1rem 0;\n  text-transform: uppercase;\n  letter-spacing: 4px;\n  font-weight: 400;\n}\n\n.inputBox {\n  margin: 0.5rem 0;\n  display: flex;\n  /* align-items: center; */\n  justify-content: space-between;\n}\n\n.mobileInput,\n.mobileOTP {\n  /* border: none; */\n  padding: 0.5rem;\n  outline: none;\n  border: 1px solid #ccc;\n  border-radius: 0.2rem;\n  width: 60%;\n}\n\n.buttonContainer {\n  margin: 1rem 0;\n  width: 100%;\n  display: flex;\n  justify-content: center;\n}\n\n.submitButton {\n  outline: none;\n  padding: 0.2rem 0.5rem;\n  /* background-color: #aaa;\n  color: white; */\n\n  border: 1px solid #ccc;\n  border-radius: 0.2rem;\n  cursor: pointer;\n}\n\n.submitButton:hover {\n  background-color: #ccc;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mainContainer": `AdminMobileOTPLoginForm_mainContainer__8ictS`,
	"formContainer": `AdminMobileOTPLoginForm_formContainer__NBYOc`,
	"title": `AdminMobileOTPLoginForm_title__7gN5g`,
	"subtitle": `AdminMobileOTPLoginForm_subtitle__yMahe`,
	"inputBox": `AdminMobileOTPLoginForm_inputBox__qi7de`,
	"mobileInput": `AdminMobileOTPLoginForm_mobileInput__99azz`,
	"mobileOTP": `AdminMobileOTPLoginForm_mobileOTP__NiEhA`,
	"buttonContainer": `AdminMobileOTPLoginForm_buttonContainer__ux-Dj`,
	"submitButton": `AdminMobileOTPLoginForm_submitButton__yl0bV`
};
export default ___CSS_LOADER_EXPORT___;
