import React from "react";

import DashboardIcon from "@mui/icons-material/Dashboard";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import PanoramaIcon from '@mui/icons-material/Panorama';
import AbcIcon from '@mui/icons-material/Abc';

import DashboardLayout from "../components/common/DashboardLayout";

const mainListItemsArray = [
  {
    id: 1,
    label: "Advisory Form",
    link: "/advisor-dashboard/advisory-form",
    icon: <DashboardIcon />,
  },
  {
    id: 2,
    label: "User Support",
    link: "/advisor-dashboard/user-support",
    icon: <SupportAgentIcon />,
  },
  {
    id: 3,
    label: "Add Banner",
    link: "/advisor-dashboard/banner",
    icon: <PanoramaIcon />,
  },
  {
    id: 4,
    label: "Create Ticker",
    link: "/advisor-dashboard/create-ticker",
    icon: <AbcIcon />,
  },
];

export default function AdvisorDashboardLayout() {
  return <DashboardLayout mainListItems={mainListItemsArray} />;
}
