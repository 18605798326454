import React from "react";

import styles from './AdvisorySummaryModal.module.css';

const AdvisorySummaryModal = ({
  advisoryFormInput,
  advisoryDuration,
  fileInput,
  handlePublish
}) => {
  
  const formInputArray = Object.entries(advisoryFormInput).map(([key, value]) => ({
    label: key,
    value: value,
  }));

  
  return (
    <div className={styles.summaryModalContainer} >
      <h2 className={styles.modalTitle} >Summary</h2>

      <div className={styles.summaryContainer} >
        {formInputArray.map(item => {
            return <SummaryItem labelName={item.label} labelValue={item.value} />
        })}

        <SummaryItem labelName="Duration" labelValue={advisoryDuration} />
        <SummaryItem labelName="file" labelValue={fileInput?.name} />
      </div>

      <div className={styles.publishBtnContainer}>
        <button onClick={handlePublish} className={styles.publishBtn}>publish</button>
      </div>
    </div>
  );
};

export default AdvisorySummaryModal;

// Summary item comp
const SummaryItem = ({ labelName, labelValue }) => {
  return (
    <div className={styles.symbolNameContainer}>
      <p className={styles.labelName} >{labelName}</p>
      <span className={styles.labelValue} >{labelValue}</span>
    </div>
  );
};
