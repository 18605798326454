// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `


.HomePage_topImageBannerContainer__cSe66 {
  /* border: 2px solid red; */
  width: 100%;
  /* height: 565px; */
  /* height: 100%; */
  
}
.HomePage_topBannerImg__-3vxK {
  height: 100%;
  width: 100%;
/* object-fit: fill; */
}

.HomePage_badgeContainers__9pAMF {
  width: 100%;
  display: flex;
  gap: 1rem;
  justify-content: center;
  align-items: center;
  margin: 1rem 0;
}

.HomePage_topStocksTableContainer__50gIR {
  margin: 2rem 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

`, "",{"version":3,"sources":["webpack://./src/pages/HomePage/HomePage.module.css"],"names":[],"mappings":";;;AAGA;EACE,2BAA2B;EAC3B,WAAW;EACX,mBAAmB;EACnB,kBAAkB;;AAEpB;AACA;EACE,YAAY;EACZ,WAAW;AACb,sBAAsB;AACtB;;AAEA;EACE,WAAW;EACX,aAAa;EACb,SAAS;EACT,uBAAuB;EACvB,mBAAmB;EACnB,cAAc;AAChB;;AAEA;EACE,cAAc;EACd,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB","sourcesContent":["\n\n\n.topImageBannerContainer {\n  /* border: 2px solid red; */\n  width: 100%;\n  /* height: 565px; */\n  /* height: 100%; */\n  \n}\n.topBannerImg {\n  height: 100%;\n  width: 100%;\n/* object-fit: fill; */\n}\n\n.badgeContainers {\n  width: 100%;\n  display: flex;\n  gap: 1rem;\n  justify-content: center;\n  align-items: center;\n  margin: 1rem 0;\n}\n\n.topStocksTableContainer {\n  margin: 2rem 0;\n  width: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"topImageBannerContainer": `HomePage_topImageBannerContainer__cSe66`,
	"topBannerImg": `HomePage_topBannerImg__-3vxK`,
	"badgeContainers": `HomePage_badgeContainers__9pAMF`,
	"topStocksTableContainer": `HomePage_topStocksTableContainer__50gIR`
};
export default ___CSS_LOADER_EXPORT___;
