// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.BackButton_backBtn__kA6d9 {
    margin: 1rem;
    border: 1px solid #ccc;
    padding: 0.2rem 0.5rem;
    border-radius: 0.2rem;

    font-weight: bold;
    color: #333;
    background-color: #eee;
    text-transform: uppercase;
    cursor: pointer;
}

.BackButton_backBtn__kA6d9:hover {
    background-color: #333;
    color: #fff;
}`, "",{"version":3,"sources":["webpack://./src/components/common/BackButton/BackButton.module.css"],"names":[],"mappings":";AACA;IACI,YAAY;IACZ,sBAAsB;IACtB,sBAAsB;IACtB,qBAAqB;;IAErB,iBAAiB;IACjB,WAAW;IACX,sBAAsB;IACtB,yBAAyB;IACzB,eAAe;AACnB;;AAEA;IACI,sBAAsB;IACtB,WAAW;AACf","sourcesContent":["\n.backBtn {\n    margin: 1rem;\n    border: 1px solid #ccc;\n    padding: 0.2rem 0.5rem;\n    border-radius: 0.2rem;\n\n    font-weight: bold;\n    color: #333;\n    background-color: #eee;\n    text-transform: uppercase;\n    cursor: pointer;\n}\n\n.backBtn:hover {\n    background-color: #333;\n    color: #fff;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"backBtn": `BackButton_backBtn__kA6d9`
};
export default ___CSS_LOADER_EXPORT___;
