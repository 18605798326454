// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.InfoCards_infoCards_Container__GkTGY {
  margin: 2rem 0;
  padding: 4rem 2rem;
}

.InfoCards_infoCardContainer__NQzUu {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  justify-content: space-evenly;
}`, "",{"version":3,"sources":["webpack://./src/components/homepage/InfoCards/InfoCards.module.css"],"names":[],"mappings":";;AAEA;EACE,cAAc;EACd,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,eAAe;EACf,SAAS;EACT,6BAA6B;AAC/B","sourcesContent":["\n\n.infoCards_Container {\n  margin: 2rem 0;\n  padding: 4rem 2rem;\n}\n\n.infoCardContainer {\n  display: flex;\n  flex-wrap: wrap;\n  gap: 2rem;\n  justify-content: space-evenly;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"infoCards_Container": `InfoCards_infoCards_Container__GkTGY`,
	"infoCardContainer": `InfoCards_infoCardContainer__NQzUu`
};
export default ___CSS_LOADER_EXPORT___;
