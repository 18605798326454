import React, { useRef, useState } from "react";
import { axiosConfig } from "../../../axios/axiosConfig";
import toast from "react-hot-toast";
import { useQuery } from "@tanstack/react-query";
import AdvisoryDetailsTable from "./AdvisoryDetailsTable";
import StockAdvisoryForm from "./StockAdvisoryForm";

import styles from "./AdvisorHome.module.css";

const AdvisorHome = () => {
  const [advisoryStocksList, setAdvisoryStocksList] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [activeAdvisoryObj, setActiveAdvisoryObj] = useState({});
  const exitPriceRef = useRef(null);

  const initialAdvisoryFormInput = {
    symbolName: "AVROIND",
    advisoryType: "SHORT",
    priceRange: "₹125-₹150",
    entryPrice: "", // suggested price, NO RUPEE symbols in entry, target and stop loss
    targetPrice: "",
    stopLoss: "",
    description: "",
    exitPrice: "N/A", // EXIT price is only visible for EDIT / UPDATE
    profitLoss: "",
    tradeClosedInDays: "",
  };
  const [advisoryFormInput, setAdvisoryFormInput] = useState(
    initialAdvisoryFormInput
  );

  // CLEAR / RESET advisoryFormInput
  const clearAdvisoryFormInput = () => {
    setAdvisoryFormInput(initialAdvisoryFormInput);
  };

  // FETCHING ADVISORY DETAILS
  const advisoryQuery = useQuery({
    retry: false,
    refetchOnWindowFocus: false, // Prevent refetch on window focus
    refetchInterval: false, // Disable automatic refetching
    queryKey: ["advisory-details"], // dependency
    queryFn: () => fetchAdvisoryDetails(),
    onSuccess: (data) => {
      setAdvisoryStocksList(data.response?.data);
    },
    onError: (error) => {
      // console.log(" error: ", error);
      toast.error(error?.response?.data?.response || "Something went wrong");
    },
  });

  // this function copies the object row in advisorytable to AdvisoryFormInput, makes it active
  const handleEditBtn = (item_obj) => {
    const {
      symbol_name,
      adviosry_type,
      price_range,
      entry_price,
      target_price,
      stoploss_price,
    } = item_obj;
    const tempAdvisoryFormInput = {
      symbolName: symbol_name,
      advisoryType: adviosry_type,
      priceRange: price_range,
      entryPrice: entry_price, // suggested price, NO RUPEE symbols in entry, target and stop loss
      targetPrice: target_price,
      stopLoss: stoploss_price,
      description: item_obj?.description,
    };

    setIsEditing(true);
    setActiveAdvisoryObj(item_obj);
    setAdvisoryFormInput(tempAdvisoryFormInput);
    exitPriceRef.current.focus();

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth", // For smooth scrolling, or 'auto' for instant
    });
  };

  return (
    <div className={styles.AdvisorHome}>
      <div style={{ marginLeft: "40px", marginBottom: "-10px" }}>
        <h1>Advisory Home</h1>
      </div>

      {/* stock advisor form */}
      <StockAdvisoryForm
        isEditing={isEditing}
        setIsEditing={setIsEditing}
        advisoryFormInput={advisoryFormInput}
        setAdvisoryFormInput={setAdvisoryFormInput}
        initialAdvisoryFormInput={initialAdvisoryFormInput}
        clearAdvisoryFormInput={clearAdvisoryFormInput}
        activeAdvisoryObj={activeAdvisoryObj}
        exitPriceRef={exitPriceRef}
      />

      {/* advisory details table */}
      <AdvisoryDetailsTable
        advisoryDetailsList={advisoryStocksList}
        handleEditBtn={handleEditBtn}
      />
    </div>
  );
};

export default AdvisorHome;

// UTILITIES
// fetching all stocks advisory (open or closed)
const fetchAdvisoryDetails = async () => {
  // if (!watchlistId) return;

  const res = await axiosConfig({
    method: "get",
    // url: "/predictions/stocks/advisory?status=OPEN",
    url: "/predictions/stocks/advisory",
    data: {},
  });

  return res.data;
};
