import React, { useState } from "react";

import styles from "./UserPaymentsDashboardTable.module.css";
// import { Link } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { axiosConfig } from "../../../../axios/axiosConfig";
import { CSVLink } from "react-csv";
import toast from "react-hot-toast";
import DownloadIcon from "@mui/icons-material/Download";
import { Button } from "@mui/material";

const columnHeaders = [
  { col_id: "s_no", name: "#", col_width: 30 },
  { col_id: "email", name: "Email" },
  { col_id: "phoneno", name: "Mobile" },
  { col_id: "amount", name: "Amount", col_width: 50 },
  { col_id: "payment_date", name: "Payment Date" },
  { col_id: "payment_end_date", name: "End Date" },
  { col_id: "payment_id", name: "Payment ID" },
  { col_id: "total_alerts", name: "Total Alerts" },
  { col_id: "used_alerts", name: "Used Alerts" },
  { col_id: "actions", name: "Actions" },
];

// const searchByOptions = [
//   { id: 1, name: "Mobile", value: "mobile" },
//   { id: 2, name: "Email", value: "email" },
//   { id: 3, name: "Payment ID", value: "payment_id" },
// ];

const UserPaymentsDashboardTable = ({
  allUserPaymentDetails,
  setAllUserPaymentDetails,
}) => {
  // const [searchBy, setSearchBy] = useState("mobile");
  const [searchQuery, setSearchQuery] = useState("");

  // refund button handler
  const refundMutation = useMutation({
    mutationFn: async (paymentID) => {
      const res = await axiosConfig({
        method: "post",
        url: "/payment/refund",
        data: {
          id: paymentID,
        },
      });
      return res.data;
    },
    onSuccess: (data, paymentID) => {
      setAllUserPaymentDetails((prevState) => {
        return prevState.filter((item) => item.id !== paymentID);
      });

      toast.success(data.response);
    },
    onError: (error) => {
      toast.error(error.message || "Something went wrong");
    },
  });

  // HANDLE CREATE / POST NEW MARQUEE
  const handleRefund = (payment_id) => {
    const consent = window.confirm(
      "Are you sure you want to refund this user?"
    );
    if (!consent) {
      window.alert("Cancelled, No Action Taken!");
      return;
    }
    refundMutation.mutate(payment_id);
  };

  // Clear search input query (display all user payments)
  const handleClear = () => {
    setSearchQuery("");
  };

  // Search function to filter data based on search input
  const filteredPayments = allUserPaymentDetails.filter((payment) => {
    const query = searchQuery.trim().toLowerCase();
    // const query = searchQuery.toLowerCase();
    return (
      payment.email.toLowerCase().includes(query) ||
      payment.phoneno.includes(query) ||
      payment.payment_id.toLowerCase().includes(query)
    );
  });

  return (
    <div>
      <div className={styles.searchBoxContainer}>
        <label htmlFor="searchQuery">Search: </label>

        <input
          type="text"
          className={styles.searchQueryInput}
          placeholder="Email, mobile or payment ID"
          name="searchQuery"
          id="searchQuery"
          value={searchQuery}
          onChange={(event) => setSearchQuery(event.target.value)}
        />

        {/* <button onClick={handleSearch}>Search</button> */}

        <div className={styles.btnContainer}>
          <button className={styles.clearBtn} onClick={handleClear}>
            clear
          </button>
        </div>
      </div>

      <h2 className={styles.tableTitle}>List of Premium Users</h2>

      {/* DOWNLOAD CSV BUTTON */}
      <CSVLink data={filteredPayments} filename={"Premium Users.csv"}>
        <Button sx={{
          mb: '1rem',
        }} variant="contained" startIcon={<DownloadIcon />}>
          DOWNLOAD CSV
        </Button>
      </CSVLink>

      <div className={styles.tableContainer}>
        <table className={styles.table}>
          <thead>
            <tr>
              {columnHeaders.map((column) => (
                <th
                  key={column.col_id}
                  style={{
                    width: column?.col_width,
                  }}
                >
                  {column.name}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {filteredPayments.map((row, index) => (
              <tr key={row.id} className={styles.tableRow}>
                {columnHeaders.map((column) => {
                  const dataItem =
                    column.col_id === "s_no" ? index + 1 : row[column.col_id];

                  // if (column.col_id === "userID") {
                  //   return <td>
                  //     <Link to={`user/${dataItem}`} >
                  //       view
                  //     </Link>
                  //   </td>;
                  // }

                  if (column.col_id === "actions") {
                    return (
                      <td>
                        <button
                          className={styles.refundBtn}
                          onClick={() => handleRefund(row.id)}
                        >
                          refund
                        </button>
                      </td>
                    );
                  }

                  return <td key={column.col_id}>{dataItem}</td>;
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default UserPaymentsDashboardTable;
