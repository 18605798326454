import React, { useState, useEffect } from "react";

import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

// import { measureRootOptions } from '../../utils/screener-dropdown-options';

const EmaFields = ({ values, handleEmaFieldValues }) => {
  const [emaMeasure, setEmaMeasure] = useState(values.measure || "close");
  const [emaDays, setEmaDays] = useState(values.days || 20);

  useEffect(() => {
    handleEmaFieldValues(emaMeasure, emaDays);
  }, [emaDays, emaMeasure]);

  return (
    <>
      <div
        style={{
          display: "flex",
        }}
      >
        <Autocomplete
          disablePortal
          value={emaMeasure}
          onChange={(event, newValue) => {
            setEmaMeasure(newValue);
          }}
          id="combo-box-demo"
          options={["open", "close", "high", "low"]}
          size="small"
          sx={{ width: 120 }}
          renderInput={(params) => (
            <TextField {...params} label="Measure root" />
          )}
        />
        <TextField
          sx={{ width: 70 }}
          value={emaDays}
          size="small"
          onChange={(e) => setEmaDays(e.target.value)}
        />
      </div>
    </>
  );
};

export default EmaFields;
