import React from "react";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import styles from "./BackButton.module.css";

const BackButton = () => {
  const navigate = useNavigate();

  return (
    <>
      <button className={styles.backBtn} onClick={() => navigate(-1)}>
        <ArrowBackIcon />
      </button>
    </>
  );
};

export default BackButton;
