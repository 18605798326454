// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CreateCouponForm_editMsgContainer__PKbJi {
  margin-bottom: 1rem;
  padding: 0.2rem;
  border: 1px solid #ccc;
  border-radius: 0.2rem;
}

.CreateCouponForm_editMsg__NmeIf {
  color: red;
  font-size: 0.8rem;
  font-weight: 400;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 1.5px;
}
`, "",{"version":3,"sources":["webpack://./src/components/AdminDashboard/UserCouponCodesPage/CreateCouponForm/CreateCouponForm.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,eAAe;EACf,sBAAsB;EACtB,qBAAqB;AACvB;;AAEA;EACE,UAAU;EACV,iBAAiB;EACjB,gBAAgB;EAChB,yBAAyB;EACzB,kBAAkB;EAClB,qBAAqB;AACvB","sourcesContent":[".editMsgContainer {\n  margin-bottom: 1rem;\n  padding: 0.2rem;\n  border: 1px solid #ccc;\n  border-radius: 0.2rem;\n}\n\n.editMsg {\n  color: red;\n  font-size: 0.8rem;\n  font-weight: 400;\n  text-transform: uppercase;\n  text-align: center;\n  letter-spacing: 1.5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"editMsgContainer": `CreateCouponForm_editMsgContainer__PKbJi`,
	"editMsg": `CreateCouponForm_editMsg__NmeIf`
};
export default ___CSS_LOADER_EXPORT___;
