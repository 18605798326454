// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.HomeScreenContent_tabItem__0aohV {
  display: inline-block;
  padding: 0.2rem 0.5rem;
  border: 1px solid #000;
  border-right: none;
  text-transform: uppercase;
  cursor: pointer;

  font-size: 1.2rem;
}

.HomeScreenContent_tabItem__0aohV:hover {
  background-color: rgb(52, 124, 219);
  color: #fff;
}

.HomeScreenContent_tabItem__0aohV:first-child {
  border-radius: 0.5rem 0 0 0;
}
.HomeScreenContent_tabItem__0aohV:last-child {
  border-radius: 0 0.5rem 0 0;
  border-right: 1px solid #000;
}`, "",{"version":3,"sources":["webpack://./src/components/AdminDashboard/HomeScreenContent/HomeScreenContent.module.css"],"names":[],"mappings":";;AAEA;EACE,qBAAqB;EACrB,sBAAsB;EACtB,sBAAsB;EACtB,kBAAkB;EAClB,yBAAyB;EACzB,eAAe;;EAEf,iBAAiB;AACnB;;AAEA;EACE,mCAAmC;EACnC,WAAW;AACb;;AAEA;EACE,2BAA2B;AAC7B;AACA;EACE,2BAA2B;EAC3B,4BAA4B;AAC9B","sourcesContent":["\n\n.tabItem {\n  display: inline-block;\n  padding: 0.2rem 0.5rem;\n  border: 1px solid #000;\n  border-right: none;\n  text-transform: uppercase;\n  cursor: pointer;\n\n  font-size: 1.2rem;\n}\n\n.tabItem:hover {\n  background-color: rgb(52, 124, 219);\n  color: #fff;\n}\n\n.tabItem:first-child {\n  border-radius: 0.5rem 0 0 0;\n}\n.tabItem:last-child {\n  border-radius: 0 0.5rem 0 0;\n  border-right: 1px solid #000;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tabItem": `HomeScreenContent_tabItem__0aohV`
};
export default ___CSS_LOADER_EXPORT___;
