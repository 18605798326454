export function convertDateFormat_DDMMYYYY(dateString) {
  // Split the date string into an array [YYYY, MM, DD]
  const [year, month, day] = dateString.split("-");

  // Return the new format DD-MM-YYYY
  return `${day}-${month}-${year}`;
}

export function convertDateTimeFormat_DDMMYYYY_time(dateTimeString) {
  // Split the date and time parts
  const [date, time] = dateTimeString.split(" ");

  // Extract year, month, and day from the date
  const [year, month, day] = date.split("-");

  // Extract hours, minutes, and seconds from the time
  let [hours, minutes, seconds] = time.split(":").map(Number);

  // Determine AM/PM and adjust hours for 12-hour format
  const period = hours >= 12 ? "pm" : "am";
  hours = hours % 12 || 12; // Convert 0 to 12 for midnight

  // Add leading zero for minutes and seconds if necessary
  minutes = String(minutes).padStart(2, "0");
  seconds = String(seconds).padStart(2, "0");

  // Return the formatted string
  return `${day}-${month}-${year} ${hours}:${minutes} ${period}`;
}
