import React from "react";

const UserRefund = () => {
  return (
    <div style={{ margin: "15px", marginLeft: "30px" }}>
      <h2>User Refund</h2>
    </div>
  );
};

export default UserRefund;
