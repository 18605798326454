import React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";

// const bull = (
//   <Box
//     component="span"
//     sx={{ display: "inline-block", mx: "2px", transform: "scale(0.8)" }}
//   >
//     •
//   </Box>
// );

export default function SubscriptionPlanCard() {
  return (
    <Card sx={{ minWidth: 300, p: 2 }}>
      <CardContent>
        <Typography variant="h5" component="div">
          Subscription Plan Name
        </Typography>
        {/* <Typography sx={{ mb: 1.5 }} color="text.secondary">
          subscription plan small description, may be 2-3 lines
        </Typography> */}

        <Box sx={{ mt: 2 }}>
          <Typography variant="h4">$29.99 / month</Typography>
          {/* <Typography variant="body1">Subscribed On: 3rd April 2023</Typography> */}
          <Typography variant="body2" color="text.secondary">
            Next Billing Date
          </Typography>
          <Typography variant="body2">24th May, 2023</Typography>
        </Box>

        <Box sx={{ m: "2rem 0" }}>
          <Typography variant="h6">
            Plan Type:
            <span
              style={{
                backgroundColor: "#ddd",
                padding: 3,
                borderRadius: 3,
                color: "#444",
                marginLeft: 4,
              }}
            >
              Premium
            </span>
          </Typography>

          <Typography variant="h6">
            Add Ons:
            <Stack direction="row" spacing={1}>
              {["crypto", "options"].map((addOn) => {
                return (
                  <Chip
                    key={addOn}
                    label={addOn}
                    color="primary"
                    size="small"
                  />
                );
              })}
            </Stack>
          </Typography>
        </Box>

        <Typography
          variant="body2"
          sx={{
            border: "1px solid",
            display: "inline-block",
            padding: 0.5,
            borderRadius: 1,
          }}
        >
          Payment Mode: Paypal
        </Typography>
      </CardContent>
      <CardActions>
        {/* <Button size="small">Learn More</Button> */}
      </CardActions>
    </Card>
  );
}
