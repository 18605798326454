import React from "react";
import DownloadIcon from "@mui/icons-material/Download";
import EditIcon from "@mui/icons-material/Edit";

import styles from "./AdvisoryDetailsTable.module.css";
import ColorSignalCircle from "../../../common/ColorSignalCircle";
import { convertDateFormat_DDMMYYYY } from "../../../../utils/common/dateConverter";

const AdvisoryDetailsTable = ({
  advisoryDetailsList,
  handleEditBtn,
  isEdit,
}) => {
  return (
    <div className={styles.AdvisoryDetailsTable}>
      <div style={{ margin: "15px", marginLeft: "0px" }}>
        <h2>Stock Advisory History</h2>
      </div>

      <table className={styles.tableContainer}>
        <thead>
          <tr>
            <th>Sr. No.</th>
            <th>Symbol</th>
            <th>Description</th>
            <th>Entry</th>
            <th>Target</th>
            <th>Exit</th>
            <th>Stop Loss</th>
            <th>Type</th>
            <th>Duration</th>

            <th>Status</th>
            <th>Created</th>
            <th>Modified</th>
            <th>End Date</th>
            <th>Target hit</th>
            <th>Target hit date</th>

            <th>Download</th>
            {!isEdit && <th>Actions</th>}
          </tr>
        </thead>

        <tbody className={styles.tableBodyContainer}>
          {advisoryDetailsList?.map((item, index) => {
            // const createdDate = new Date(item.created_at).toDateString();
            // const modifiedDate = new Date(item.modified_at).toDateString();
            const formattedAdvisoryStatus =
              item?.adviosry_status.trim().toLowerCase() === "closed";

            return (
              <tr
                key={item.id}
                className={formattedAdvisoryStatus ? styles.statusClosed : ""}
              >
                <td>{index + 1}</td>
                <td>{item.symbol_name}</td>
                <td>{item.description}</td>
                <td>{item.entry_price}</td>
                <td>{item.target_price}</td>
                <td>{item.exit_price}</td>
                <td>{item.stoploss_price}</td>

                <td>{item.adviosry_type}</td>
                <td>{item.adviosry_duration}</td>
                <td>{item.adviosry_status}</td>

                <td>{convertDateFormat_DDMMYYYY(item.created_at)}</td>
                <td>{convertDateFormat_DDMMYYYY(item.modified_at)}</td>
                <td>{convertDateFormat_DDMMYYYY(item.advisoury_end_date)}</td>

                <td>
                  <div className={styles.colorCircleContainer}>
                    {item.is_target_hit ? (
                      <ColorSignalCircle bgColor="green" />
                    ) : (
                      <ColorSignalCircle bgColor="red" />
                    )}
                  </div>
                </td>
                <td>{item.target_hit_date ? item.target_hit_date : "N/A"}</td>

                <td>
                  <div>
                    <a
                      style={{ paddingLeft: "20px" }}
                      className={styles.fileDownloadLink}
                      href={item?.file}
                    >
                      <DownloadIcon />
                    </a>
                  </div>
                </td>

                {!isEdit && (
                  <td>
                    <div>
                      <button
                        disabled={formattedAdvisoryStatus}
                        className={styles.editBtn}
                        onClick={() => handleEditBtn(item)}
                      >
                        <EditIcon />
                      </button>
                    </div>
                  </td>
                )}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default AdvisoryDetailsTable;
