import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import FileUploadIcon from "@mui/icons-material/FileUpload";

import styles from "./UploadBannerForm.module.css";
import { axiosConfig } from "../../../axios/axiosConfig";
import ReactHotToaster from "../ReactHotToaster";

const UploadBannerForm = ({
  imgPreview,
  formInput,
  handleFormInputOnChange,
  handleImageFileChange,
  handleSubmit,
  handleUpdate,
  isEditing,
  setIsEditing,
}) => {
  const [bannerScreensList, setBannerScreensList] = useState([]);
  const [userTypes, setUserTypes] = useState([]);
  const screenListOptions = bannerScreensList?.map((item) => {
    return {
      id: item.id,
      label: item.screen_name,
      value: item.screen_name,
    };
  });

  useEffect(() => {
    const getUserTypeList = async () => {
      try {
        const data = await fetchUserTypeList();
        const modified_data = (data.response.user_type =
          data.response.user_type.map((type, index) => ({
            id: index,
            type: type,
          })));

        setUserTypes(modified_data);
      } catch (error) {}
    };

    const getBannerScreensList = async () => {
      try {
        const data = await fetchBannerScreensList();
        setBannerScreensList(data?.response);
      } catch (error) {
        toast.error(error?.response?.data?.response || "Something went wrong");
      }
    };
    getBannerScreensList(); // FETCH BANNER Screens
    getUserTypeList(); // FETCH User Type
  }, []);

  const disableCreateBtn =
    !formInput?.user_type?.length ||
    formInput.selectedScreen.trim() === "" ||
    !formInput.imgUrl ||
    !formInput.endDate;

  return (
    <div>
      <div
        style={{ padding: "0rem", marginTop: "1rem" }}
        className={styles.formPreviewContainer}
      >
        <form className={styles.bannerForm}>
          <h2>Create New Banner</h2>

          <div className={styles.inputBox}>
            <label htmlFor="height">Select Module: </label>
            <select
              className={styles.inputControl}
              name="selectedScreen"
              value={formInput.selectedScreen}
              onChange={handleFormInputOnChange}
            >
              {screenListOptions?.map((item) => {
                return (
                  <option key={item.value} value={item.value}>
                    {item.label}
                  </option>
                );
              })}
            </select>
          </div>

          <div className={styles.inputBox}>
            <label htmlFor="title">Select Image</label>

            <div className={styles.imgUploadContainer}>
              <input
                className={styles.imgUrlInput}
                type="text"
                name="imgUrl"
                id="imgUrl"
                value={formInput.imgUrl}
                onChange={handleFormInputOnChange}
              />
              <label htmlFor="imageUpload">
                <FileUploadIcon className={styles.uploadBtn} />
                <input
                  onChange={handleImageFileChange}
                  className={styles.imgFileInput}
                  type="file"
                  name="imageUpload"
                  id="imageUpload"
                />
              </label>
            </div>
          </div>

          {/* target url */}
          {/* <div className={styles.inputBox}>
            <label htmlFor="targetUrl">Target Link</label>
            <input
              disabled
              className={styles.inputControl}
              type="text"
              name="targetUrl"
              id="targetUrl"
              value={formInput.targetUrl}
              onChange={handleFormInputOnChange}
            />
          </div> */}

          {/* FROM DATE PICKER */}
          <div className={styles.inputBox}>
            <label htmlFor="startDate">Start Date: </label>
            <input
              className={styles.inputControl}
              type="date"
              name="startDate"
              id="startDate"
              min={new Date().toISOString().slice(0, 10)}
              value={formInput.startDate}
              onChange={handleFormInputOnChange}
            />
          </div>

          {/* TO DATE PICKER */}
          <div className={styles.inputBox}>
            <label htmlFor="endDate">End Date: </label>
            <input
              className={styles.inputControl}
              type="date"
              name="endDate"
              id="endDate"
              value={formInput.endDate}
              onChange={handleFormInputOnChange}
            />
          </div>

          {/* HEIGHT INPUT */}
          <div className={styles.inputBox}>
            <label htmlFor="height">Height (px): </label>
            <input
              className={styles.inputControl}
              type="text"
              name="height"
              id="height"
              value={formInput.height}
              onChange={handleFormInputOnChange}
              placeholder="Enter height"
              min="0" // Optional: Ensures no negative values
              step="1" // Optional: Specifies integer input
            />
          </div>
          {/* SELECT DROPDOWN */}
          <div className={styles.inputBox}>
            <label>Select Audience: </label>
            <Select
              className={styles.inputControl}
              name="user_type"
              placeholder="Select Audience"
              multiple
              value={
                Array.isArray(formInput.user_type) ? formInput.user_type : []
              }
              onChange={(event) => {
                const { value } = event.target;
                if (value.includes("all")) {
                  // Toggle select all
                  handleFormInputOnChange({
                    target: {
                      name: "user_type",
                      value:
                        formInput.user_type.length === userTypes.length
                          ? [] // Deselect all
                          : userTypes.map((user) => user.type), // Select all
                    },
                  });
                } else {
                  handleFormInputOnChange(event);
                }
              }}
              renderValue={(selected) => selected.join(", ")}
            >
              {/* Select All Option */}
              <MenuItem value="all">
                <Checkbox
                  checked={
                    formInput?.user_type?.length === userTypes?.length &&
                    userTypes?.length > 0
                  }
                />
                <ListItemText primary="Select All" />
              </MenuItem>
              {/* Individual User Types */}
              {userTypes?.map(({ id, type }) => (
                <MenuItem key={id} value={type}>
                  <Checkbox checked={formInput?.user_type?.includes(type)} />
                  <ListItemText primary={type} />
                </MenuItem>
              ))}
            </Select>
          </div>

          {/* <div className={styles.btnContainer}>
            {isEditing ? (
              <div className="btnContainer">
                <button
                  style={{ marginRight: "10px" }}
                  className={styles.submitBtn}
                  onClick={handleUpdate}
                >
                  update
                </button>
                <button
                  className={styles.submitBtn}
                  onClick={() => setIsEditing(false)}
                >
                  cancel
                </button>
              </div>
            ) : (
              <button className={styles.submitBtn} onClick={handleSubmit}>
                create
              </button>
            )}
          </div> */}
          <div className={styles.btnContainer}>
            <button
              disabled={disableCreateBtn}
              className={styles.createContentBtn}
              onClick={handleSubmit}
            >
              Create
            </button>
            {/* Uncomment these buttons if needed */}
            {/* <button className={styles.editContentBtn} onClick={handleEditContent}>
          View/Edit
        </button>
        <button className={styles.deleteContentBtn} onClick={handleDeleteContent}>
          Delete
        </button> */}
          </div>
        </form>

        {/* Image Preview Container */}
        <div className={styles.imgPreviewContainer}>
          <h2>Image Preview</h2>
          <div className={styles.imgContainer}>
            <img className={styles.imgPreview} src={imgPreview} alt="" />
          </div>
        </div>
      </div>

      {/* REACT HOT TOAST COMP */}
      <ReactHotToaster />
    </div>
  );
};

export default UploadBannerForm;

// UTILITIES
export const fetchBannerScreensList = async () => {
  // if (!watchlistId) return;

  const res = await axiosConfig({
    method: "get",
    url: "/advertisement/screens/list",
    data: {},
  });

  return res.data;
};

export const fetchUserTypeList = async () => {
  const res = await axiosConfig({
    method: "get",
    url: "/user/user_type",
    data: {},
  });

  return res.data;
};
