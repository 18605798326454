import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useMutation } from "@tanstack/react-query";
import LoginScreen from "../../components/LoginScreen/LoginScreen";
import AccessDenied from "../../components/common/AccessDenied";
// import AdminMobileOTPLoginForm from "./AdminMobileOTPLoginForm";
import axios from "axios";
// import VerifyEmailPrompt from "../components/VerifyEmailPrompt";

const local_admin_access_token = localStorage.getItem("admin_access_token");

const AdvisorRoute = ({
  auth: { isAuthenticated },
  children,
  allowedRoles = null,
}) => {
  const userState = useSelector((store) => store.auth);
  

  const USER_DETAILS = userState?.user?.userDetails;
  
  // state for session ID
  // const [currentAdminAccessToken, setCurrentAdminAccessToken] = useState('');
  const [currentAdminAccessToken, setCurrentAdminAccessToken] = useState(
    local_admin_access_token
  );
  const [isAdminAccessTokenValid, setIsAdminAccessTokenValid] = useState(false);

  
  // STATE FOR verifyOTP loading (true or false);
  const [isVerifyOTPApiLoading, setIsVerifyOTPApiLoading] = useState(false);

  // FUNCTION TO SET ADMIN ACCESS TOKEN
  // const setAdminAccessToken = (access_token) => {
  //   setCurrentAdminAccessToken(access_token);
  // };

  // POST REQUEST TO CHECK SESSION ID
  const checkAdminAccessToken = useMutation({
    retry: false,
    refetchOnWindowFocus: false, // Prevent refetch on window focus
    refetchInterval: false, // Disable automatic refetching
    mutationFn: async () => {
      // if (!currentAdminAccessToken || currentAdminAccessToken.trim() === "") {
      //   return;
      // }

      // const res = await axiosConfig({
      //   method: "post",
      //   url: "/predictions/advisor/login",
      //   data: {
      //     access_token: currentAdminAccessToken,
      //   },
      // });
      const res = await axios.post("predictions/advisor/login", {
        access_token: currentAdminAccessToken,
      },  {
        headers: {
          // Authorization: '' // Override the Authorization header
          Authorization: null // Override the Authorization header
        }
      });
      
      return res.data;
    },
    onSuccess: (data) => {
      
      if (data.response.toLowerCase() === "valid") {
        setIsAdminAccessTokenValid(true);
      } else {
        setIsAdminAccessTokenValid(false);
      }
    },
    onError: (error) => {
      
      setIsAdminAccessTokenValid(false);
      window.alert(error?.response?.data?.response || "Something went wrong");
    },
  });

  // useEffect to send and check admin access token
  // useEffect(() => {
  //   if (!currentAdminAccessToken || currentAdminAccessToken.trim() === "") {
  //     return;
  //   }
  //   checkAdminAccessToken.mutate();
  // }, [currentAdminAccessToken]);

  // IF NOT AUTHENTICATED, ROUTE TO LOGIN PAGE
  if (!isAuthenticated) {
    return <LoginScreen />;
  }

  // ONLY ALLOW ROLES CONTAINED IN 'ALLOWED_ROLES' ARRAY
  if (!allowedRoles?.includes(USER_DETAILS?.user_type.toLowerCase())) {
    return <AccessDenied />;
  }

  // IMPORTANT: UNCOMMENT AFTER DEVELOPMENT IS DONE
  if (checkAdminAccessToken.isLoading || isVerifyOTPApiLoading) {
    return <h1>Loading...</h1>;
  }

  // if there's no token, render OTP form, else send post request to check if token is valid
  // if (!isAdminAccessTokenValid) {
  //   return (
  //     <AdminMobileOTPLoginForm
  //       setAccessToken={setAdminAccessToken}
  //       validateAccessToken={setIsAdminAccessTokenValid}
  //       setIsVerifyOTPApiLoading={setIsVerifyOTPApiLoading}
  //     />
  //   );
  // }

  if (!isVerifyOTPApiLoading) {
    return children;
  }

  // return isAuthenticated ? children : <LoginScreen />;
};

export default AdvisorRoute;
