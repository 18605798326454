import { Box, Button, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

import axiosConfig from "../../axios/axiosConfig";
import ReactHotToaster from "../../components/common/ReactHotToaster";

const VerifyEmailCodePage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const user_email = location.state?.email;

  const [OTP, setOTP] = useState("");
  // const [otpSubmitCounter, setOtpSubmitCounter] = useState(0);

  const handleSubmitOtp = async () => {
    // if 3 times otp incorrect, disable submit button, and return from this function.
    // finally if otp correct, route to 'reset-password' page

    try {
      const res = await axiosConfig({
        method: "post",
        url: "/enroll/forgot_password",
        data: { email: user_email, otp: OTP },
      });
      console.log("res: ", res);
      if (res.status === 200 || res.statusText === "OK") {
        toast.success('Email Verified Successfully.');
        navigate("/reset-password", { state: { email: user_email, otp: OTP } });
      }
      
    } catch (error) {
      console.log(error);
      toast.error(error.response?.data?.response || "Something went wrong");
    }
  };

  const disbleBtn = !OTP || OTP.trim() === "" || OTP.trim().length < 6;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        mt: 4,
        // backgroundColor: 'yellow'
      }}
    >
      <h1>Verify Email Code</h1>

      <Box
        component="form"
        sx={{
          width: "50%",
          minWidth: 400,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          margin: "1rem 0",
          // backgroundColor: 'red'
        }}
        noValidate
        autoComplete="off"
      >
        <Typography>Type the email verification code that you've received on your email.</Typography>

        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: 'center',
            gap: '1rem'
          }}
        >
          <TextField
          size="small"
          id="otp"
          label="Code"
          
          value={OTP}
          onChange={(event) => {
            setOTP(event.target.value);
          }}
          sx={{
            margin: "1rem 0",
            maxWidth: 100,
          }}
          inputProps={{ maxLength: 6 }}
        />

          <Button disabled={disbleBtn} variant="contained" onClick={handleSubmitOtp}>
            Submit
          </Button>
        </div>
      </Box>

      {/* REACT HOT TOAST FOR TOAST MESSAGES */}
      <ReactHotToaster /> 
    </Box>
  );
};

export default VerifyEmailCodePage;


