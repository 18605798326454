import React from "react";
import { Editor } from "@tinymce/tinymce-react";
import styles from "./TinyMCEEditorCommon.module.css";

// const editorRef = useRef(null); // STATE FOR TINYMCE EDITOR IN PARENT COMPONENT

const TinyMCEEditorCommon = ({
  // screenContent = "<p>This is the initial content of the editor.</p>",

  crudType = "create",
  editorRef,
  editable = true,
  initialContent,
}) => {
  // const editorRef = useRef(null);
  const useDarkMode = window.matchMedia("(prefers-color-scheme: dark)").matches;
  const isSmallScreen = window.matchMedia("(max-width: 1023.5px)").matches;

  const screenContent =
    initialContent || "<p>This is the initial content of the editor.</p>";

  return (
    <div className={styles.createPostWrapper}>
      {/* <h2 className={styles.mainHeading}>{crudType} content</h2> */}

      <div className={styles.editorContainer}>
        <Editor
          apiKey="5ny7yci6t16adt8v7trzzri7kni4ikh6ppv3poaawghb9sde"
          onInit={(evt, editor) => (editorRef.current = editor)}
          initialValue={screenContent}
          init={{
            editable_root: editable,
            selector: "textarea#open-source-plugins",
            plugins:
              "preview importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media codesample table charmap pagebreak nonbreaking anchor insertdatetime advlist lists wordcount help charmap quickbars emoticons accordion",
            editimage_cors_hosts: ["picsum.photos"],
            menubar: editable ? "file edit view insert format tools table help" : false,
            toolbar: editable ? "undo redo | accordion accordionremove | blocks fontfamily fontsize | bold italic underline strikethrough | align numlist bullist | link image | table media | lineheight outdent indent| forecolor backcolor removeformat | charmap emoticons | code fullscreen preview | save print | pagebreak anchor codesample | ltr rtl" : false,
            autosave_ask_before_unload: true,
            autosave_interval: "30s",
            autosave_prefix: "{path}{query}-{id}-",
            autosave_restore_when_empty: false,
            autosave_retention: "2m",
            image_advtab: true,
            link_list: [
              { title: "My page 1", value: "https://www.tiny.cloud" },
              { title: "My page 2", value: "http://www.moxiecode.com" },
            ],
            image_list: [
              { title: "My page 1", value: "https://www.tiny.cloud" },
              { title: "My page 2", value: "http://www.moxiecode.com" },
            ],
            image_class_list: [
              { title: "None", value: "" },
              { title: "Some class", value: "class-name" },
            ],
            importcss_append: true,
            file_picker_callback: (callback, value, meta) => {
              /* Provide file and text for the link dialog */
              if (meta.filetype === "file") {
                callback("https://www.google.com/logos/google.jpg", {
                  text: "My text",
                });
              }

              /* Provide image and alt text for the image dialog */
              if (meta.filetype === "image") {
                callback("https://www.google.com/logos/google.jpg", {
                  alt: "My alt text",
                });
              }

              /* Provide alternative source and posted for the media dialog */
              if (meta.filetype === "media") {
                callback("movie.mp4", {
                  source2: "alt.ogg",
                  poster: "https://www.google.com/logos/google.jpg",
                });
              }
            },
            height: 600,
            image_caption: true,
            quickbars_selection_toolbar:
              "bold italic | quicklink h2 h3 blockquote quickimage quicktable",
            noneditable_class: "mceNonEditable",
            toolbar_mode: "sliding",
            contextmenu: "link image table",
            skin: useDarkMode ? "oxide-dark" : "oxide",
            content_css: useDarkMode ? "dark" : "default",
            content_style:
              "body { font-family:Helvetica,Arial,sans-serif; font-size:16px }",
          }}
        />
      </div>
    </div>
  );
};

export default TinyMCEEditorCommon;
