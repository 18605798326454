import { Box, Stack, Container, Card, Button, Typography } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import toast from "react-hot-toast";
import React, { useState } from "react";
import { useSelector } from "react-redux";

import useCopyToClipboard from "../../../hooks/useCopyToClipboard";
import SubscriptionPlanCard from "./SubscriptionPlanCard";
import ReactHotToaster from "../../common/ReactHotToaster";
import axiosConfig from "../../../axios/axiosConfig";
import { useNavigate } from "react-router-dom";
import MuiModal from "../../muiComps/MuiModal";
import UpdateNameEmailModal from "./UpdateNameEmailModal";
// import useCopyToClipboard from "../../hooks/useCopyToClipboard";
// import { curry } from "lodash";

const styles = {
  labelContainer: {
    border: "1px solid #ccc",
    p: "8.5px 14px",
    borderRadius: "4px",
    backgroundColor: "#eee",
    color: "#555",
  },
  editIconContainer: {
    padding: "2px",
    position: "absolute",
    right: "10px",
    top: "10px",
    cursor: "pointer",
  },
};

// const ROOT_DOMAIN =
//   process.env.NODE_ENV === "development"
//     ? "http://localhost:3000"
//     : "https://domainname.com";
// const ROOT_DOMAIN = "https://tnibro.com";
const ROOT_DOMAIN = "https://stockyaari.com";
// http://localhost:3000/register?referral_code=IRD2HMU7G6XY

// https://usehooks-ts.com/react-hook/use-copy-to-clipboard

const UserProfile = () => {
  const navigate = useNavigate();
  const {
    user: { userDetails },
  } = useSelector((store) => store.auth);
  const USERID = userDetails?.id;

  const [value, copy] = useCopyToClipboard();

  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");

  const [openNameEmailEditModal, setOpenNameEmailEditModal] = useState(false);

  const updateFullName = async () => {
    if (!fullName || fullName.trim() === "") return;

    const consent = window.confirm(
      "Are you sure you want to update full name?"
    );

    if (!consent) return;

    try {
      const res = await axiosConfig({
        method: "patch",
        url: `/enroll/user_profile?userid=${USERID}`,
        data: { username: fullName },
      });

      if (res.statusText === "OK" || res.status === 200) {
        const user = JSON.parse(localStorage.getItem("user"));
        const newUser = { ...user, userDetails: res.data.response };
        localStorage.setItem("user", JSON.stringify(newUser));

        toast.success("Name changed successfully!");
      }
    } catch (error) {
      console.log("updateFullName error: ", error);
      toast.error(error?.response?.data?.response || "Something went wrong");
    }
  };

  const disableSaveBtn = !fullName || fullName.trim() === "";

  const REFERRAL_LINK_STAGING = `https://faircopy.tnibro.com/register?referral_code=${userDetails.referralcode}`;
  const REFERRAL_LINK = `${ROOT_DOMAIN}/register?referral_code=${userDetails.referralcode}`;

  // SEND EMAIL OTP HANDLER
  const sendEmailOTP = async () => {
    try {
      const res = await axiosConfig({
        method: "patch",
        url: `/enroll/user_profile?userid=${userDetails?.id}`,
        data: { email: email },
      });

      // if success, setVerifyBtnClicked(true)
      if (res.status === 200 || res.statusText === "OK") {
        toast.success("Verification code sent to your email.");
        navigate("/email-update-verify", { state: { email: email } });
      }
    } catch (error) {
      console.log(error.response);
      toast.error(error.response.data.response || "Something went wrong");
    }
  };

  const renderDetailBox = (label, value) => (
    <Stack sx={{ m: "1rem 0", minWidth: 300 }}>
      <Typography variant="body2">{label}</Typography>
      <Box sx={styles.labelContainer}>{value || "-"}</Box>
    </Stack>
  );

  return (
    <Container>
      <Typography variant="h4" gutterBottom padding={1} paddingTop={1.5}>
        User Profile
      </Typography>

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-start",
          gap: 2,
        }}
      >
        <Card
          sx={{
            maxWidth: 700,
            display: "flex",
            flexWrap: "wrap",
            gap: 1,
            backgroundColor: "white",
            p: 2,
          }}
        >
          <Stack
            sx={{
              border: "1px solid #ccc",
              padding: "1rem",
              position: "relative",
            }}
          >
            <EditIcon
              sx={styles.editIconContainer}
              onClick={() => setOpenNameEmailEditModal(true)}
            />
            {renderDetailBox("Full Name", userDetails.username)}
            {renderDetailBox("Email", userDetails.email)}
          </Stack>

          {renderDetailBox("Phone", userDetails.phoneno)}
          {renderDetailBox("Country", userDetails.country)}
          {renderDetailBox("User Type", userDetails.user_type)}
          {renderDetailBox(
            "Joined On",
            new Date(userDetails.date_joined).toDateString()
          )}
          {renderDetailBox("Referral Code", userDetails.referralcode)}

          <Stack sx={{ m: "1rem 0" }}>
            <Typography variant="body2">Referral Link (Staging)</Typography>
            <Stack direction="row" spacing={2}>
              <Box sx={styles.labelContainer}>{REFERRAL_LINK_STAGING}</Box>
              <Button
                size="small"
                variant="contained"
                onClick={() => {
                  copy(REFERRAL_LINK_STAGING);
                  toast.success("Referral Link copied to clipboard");
                }}
              >
                Copy Link
              </Button>
            </Stack>
          </Stack>

          <Stack sx={{ m: "1rem 0" }}>
            <Typography variant="body2">Referral Link</Typography>
            <Stack direction="row" spacing={2}>
              <Box sx={styles.labelContainer}>{REFERRAL_LINK}</Box>
              <Button
                size="small"
                variant="contained"
                onClick={() => {
                  copy(REFERRAL_LINK);
                  toast.success("Referral Link copied to clipboard");
                }}
              >
                Copy Link
              </Button>
            </Stack>
          </Stack>
        </Card>

        <SubscriptionPlanCard />
      </Box>

      <Stack direction="row" spacing={2} sx={{ mt: 4 }}>
        <Button
          disabled={disableSaveBtn}
          variant="contained"
          size="small"
          color="secondary"
          onClick={updateFullName}
        >
          Save
        </Button>
      </Stack>

      {/* <Stack direction="row" spacing={2}>
        <LoadingButton
          loading
          loadingPosition="start"
          startIcon={<SaveIcon />}
          variant="outlined"
        >
          Save
        </LoadingButton>
      </Stack> */}

      {/* EDIT NAME, EMAIL MODAL */}
      <MuiModal
        open={openNameEmailEditModal}
        setOpen={setOpenNameEmailEditModal}
      >
        <UpdateNameEmailModal />
      </MuiModal>

      {/* REACT TOAST */}
      <ReactHotToaster />
    </Container>
  );
};

export default UserProfile;
