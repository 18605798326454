// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-wrappper{
  display: flex;
  flex-direction: column;
  margin: 20px;
}

.member-details-container {
  margin: 20px
}

.member-list{
  margin-top: 20px;
}

.team-members-divider-container {
  margin: 10px 0px
}`, "",{"version":3,"sources":["webpack://./src/Member/TeamMembers.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,YAAY;AACd;;AAEA;EACE;AACF;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE;AACF","sourcesContent":[".user-wrappper{\n  display: flex;\n  flex-direction: column;\n  margin: 20px;\n}\n\n.member-details-container {\n  margin: 20px\n}\n\n.member-list{\n  margin-top: 20px;\n}\n\n.team-members-divider-container {\n  margin: 10px 0px\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
