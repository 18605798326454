import React, { useState } from "react";
import styles from "./AdBannersTable.module.css";
import { axiosConfig } from "../../../../axios/axiosConfig";
import { useMutation } from "@tanstack/react-query";
import { IconButton, Tooltip } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  convertDateFormat_DDMMYYYY,
  convertDateTimeFormat_DDMMYYYY_time,
} from "../../../../utils/common/dateConverter";

const AdBannersTable = ({
  allBannersList,
  setAllBannersList,
  // handleEditClick,
}) => {
  // Utility to delete a banner
  const deleteBanner = async (bannerId) => {
    const response = await axiosConfig.delete("advertisement/screens/banner", {
      data: { id: bannerId },
    });
    return response.data;
  };

  // Mutation for deleting a banner
  const deleteBannerMutation = useMutation({
    mutationFn: deleteBanner,
    onSuccess: (_, bannerId) => {
      setAllBannersList((prev) =>
        prev.filter((banner) => banner.id !== bannerId)
      );
    },
    onError: (error) => {
      console.error(error?.response?.data?.response || "Something went wrong");
    },
  });

  const handleDeleteBanner = (bannerId) => {
    if (window.confirm("Are you sure you want to delete this banner?")) {
      deleteBannerMutation.mutate(bannerId);
    }
  };

  return (
    <div className={styles.container}>
      <h2 className={styles.title}>Banners Table</h2>

      <table className={styles.tableContainer}>
        <thead>
          <tr>
            <th>Screen</th>
            <th>Created On</th>
            <th>Start</th>
            <th>End</th>
            <th>Audience</th>
            <th>Image Preview</th>
            <th>Actions</th>
          </tr>
        </thead>

        <tbody className={styles.tableBodyContainer}>
          {allBannersList.map(
            ({
              id,
              screen_name,
              created_at,
              from_date,
              till_date,
              screen_url,
              user_type,
            }) => (
              <tr key={id}>
                <td>{screen_name}</td>
                <td>{convertDateTimeFormat_DDMMYYYY_time(created_at)}</td>
                <td>{convertDateFormat_DDMMYYYY(from_date)}</td>
                <td>{convertDateFormat_DDMMYYYY(till_date)}</td>
                <td>{user_type}</td>
                <td style={{ alignItem: "center" }}>
                  <Tooltip
                    title={
                      <img
                        src={screen_url}
                        alt="Banner Preview"
                        style={{ width: 250 }}
                      />
                    }
                    placement="top"
                  >
                    <IconButton style={{ marginLeft: 50 }}>
                      <VisibilityIcon color="primary" />
                    </IconButton>
                  </Tooltip>
                </td>
                <td>
                  <div className={styles.actions}>
                    {/* <button onClick={() => handleEditClick({ id, screen_name, created_at, from_date, till_date, screen_url })}>
                    Edit
                  </button> */}
                    <DeleteIcon
                      className={styles.deleteIcon}
                      style={{ marginLeft: 25 }}
                      onClick={() => handleDeleteBanner(id)}
                      title="Delete"
                    />
                  </div>
                </td>
              </tr>
            )
          )}
        </tbody>
      </table>
    </div>
  );
};

export default AdBannersTable;
