// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TermsAndConditions_TermsAndConditions__eIygs,
.TermsAndConditions_RefundsAndCancellation__XttO6,
.TermsAndConditions_PrivacyPolicy__7KAgl {
  font-family: 'Oswald', 'Rubik', sans-serif;
  font-weight: 300;
  font-size: 18px;
  text-align: justify;
}

.TermsAndConditions_TermsAndConditions__eIygs h1,
.TermsAndConditions_RefundsAndCancellation__XttO6 h1,
.TermsAndConditions_PrivacyPolicy__7KAgl h1 {
  margin: 1rem 0;
}

.TermsAndConditions_TermsAndConditions__eIygs h2,
.TermsAndConditions_RefundsAndCancellation__XttO6 h2,
.TermsAndConditions_PrivacyPolicy__7KAgl h2 {
  margin: 2rem 0 0.5rem;
}

.TermsAndConditions_TermsAndConditions__eIygs ul,
.TermsAndConditions_RefundsAndCancellation__XttO6 ul,
.TermsAndConditions_PrivacyPolicy__7KAgl ul {
  /* list-style: none; */
  margin-bottom: 0.5rem;
}

.TermsAndConditions_TermsAndConditions__eIygs p,
.TermsAndConditions_RefundsAndCancellation__XttO6 p,
.TermsAndConditions_PrivacyPolicy__7KAgl p {
  margin-bottom: 1rem;
}`, "",{"version":3,"sources":["webpack://./src/pages/terms-conditions/TermsAndConditions.module.css"],"names":[],"mappings":"AAGA;;;EAGE,0CAA0C;EAC1C,gBAAgB;EAChB,eAAe;EACf,mBAAmB;AACrB;;AAEA;;;EAGE,cAAc;AAChB;;AAEA;;;EAGE,qBAAqB;AACvB;;AAEA;;;EAGE,sBAAsB;EACtB,qBAAqB;AACvB;;AAEA;;;EAGE,mBAAmB;AACrB","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');\n\n\n.TermsAndConditions,\n.RefundsAndCancellation,\n.PrivacyPolicy {\n  font-family: 'Oswald', 'Rubik', sans-serif;\n  font-weight: 300;\n  font-size: 18px;\n  text-align: justify;\n}\n\n.TermsAndConditions h1,\n.RefundsAndCancellation h1,\n.PrivacyPolicy h1 {\n  margin: 1rem 0;\n}\n\n.TermsAndConditions h2,\n.RefundsAndCancellation h2,\n.PrivacyPolicy h2 {\n  margin: 2rem 0 0.5rem;\n}\n\n.TermsAndConditions ul,\n.RefundsAndCancellation ul,\n.PrivacyPolicy ul {\n  /* list-style: none; */\n  margin-bottom: 0.5rem;\n}\n\n.TermsAndConditions p,\n.RefundsAndCancellation p,\n.PrivacyPolicy p {\n  margin-bottom: 1rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"TermsAndConditions": `TermsAndConditions_TermsAndConditions__eIygs`,
	"RefundsAndCancellation": `TermsAndConditions_RefundsAndCancellation__XttO6`,
	"PrivacyPolicy": `TermsAndConditions_PrivacyPolicy__7KAgl`
};
export default ___CSS_LOADER_EXPORT___;
