// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TradeCallsTable_TradeDetailsTable__I0mw6 {
    margin: 2rem;
  }
  
  .TradeCallsTable_tableContainer__TEnP- {
    width: 100%;
    max-width: 1600px;
    border-collapse: collapse;
    border: 1px solid #ccc;
    /* background-color: red; */
  }
  
  .TradeCallsTable_tableContainer__TEnP- th,
  .TradeCallsTable_tableContainer__TEnP- td {
    padding: 8px;
    text-align: left;
    border: 1px solid #ccc;
  }
  
  .TradeCallsTable_tableContainer__TEnP- th {
    background-color: #f2f2f2;
  }
  
  .TradeCallsTable_tableBodyContainer__3goVs tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  
  .TradeCallsTable_tableBodyContainer__3goVs tr.TradeCallsTable_statusClosed__mVMZb {
    /* background-color: #a9a9a9; */
    background-color: #e2e2e2;
    /* color: #fff; */
  }
  
  .TradeCallsTable_tableBodyContainer__3goVs tr:hover {
    /* background-color: #e2e2e2; */
    background-color: #a9a9a9;
    color: #111;
  }
  
  .TradeCallsTable_colorCircleContainer__ZHGqh {
    /* border: 1px solid red; */
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .TradeCallsTable_editBtn__F8NTG {
    padding: 0.2rem 0.6rem;
    text-transform: uppercase;
    cursor: pointer;
  }
  .TradeCallsTable_editBtn__F8NTG:disabled,
  .TradeCallsTable_editBtn__F8NTG[disabled] {
    border: 1px solid #999999;
    background-color: #cccccc;
    color: #666666;
    cursor: not-allowed;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/AdvisorDashboard/AdvisorTradeCalls/common/TradeCallsTable/TradeCallsTable.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;EACd;;EAEA;IACE,WAAW;IACX,iBAAiB;IACjB,yBAAyB;IACzB,sBAAsB;IACtB,2BAA2B;EAC7B;;EAEA;;IAEE,YAAY;IACZ,gBAAgB;IAChB,sBAAsB;EACxB;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,+BAA+B;IAC/B,yBAAyB;IACzB,iBAAiB;EACnB;;EAEA;IACE,+BAA+B;IAC/B,yBAAyB;IACzB,WAAW;EACb;;EAEA;IACE,2BAA2B;IAC3B,aAAa;IACb,mBAAmB;IACnB,uBAAuB;EACzB;;EAEA;IACE,sBAAsB;IACtB,yBAAyB;IACzB,eAAe;EACjB;EACA;;IAEE,yBAAyB;IACzB,yBAAyB;IACzB,cAAc;IACd,mBAAmB;EACrB","sourcesContent":[".TradeDetailsTable {\n    margin: 2rem;\n  }\n  \n  .tableContainer {\n    width: 100%;\n    max-width: 1600px;\n    border-collapse: collapse;\n    border: 1px solid #ccc;\n    /* background-color: red; */\n  }\n  \n  .tableContainer th,\n  .tableContainer td {\n    padding: 8px;\n    text-align: left;\n    border: 1px solid #ccc;\n  }\n  \n  .tableContainer th {\n    background-color: #f2f2f2;\n  }\n  \n  .tableBodyContainer tr:nth-child(even) {\n    background-color: #f2f2f2;\n  }\n  \n  .tableBodyContainer tr.statusClosed {\n    /* background-color: #a9a9a9; */\n    background-color: #e2e2e2;\n    /* color: #fff; */\n  }\n  \n  .tableBodyContainer tr:hover {\n    /* background-color: #e2e2e2; */\n    background-color: #a9a9a9;\n    color: #111;\n  }\n  \n  .colorCircleContainer {\n    /* border: 1px solid red; */\n    display: flex;\n    align-items: center;\n    justify-content: center;\n  }\n  \n  .editBtn {\n    padding: 0.2rem 0.6rem;\n    text-transform: uppercase;\n    cursor: pointer;\n  }\n  .editBtn:disabled,\n  .editBtn[disabled] {\n    border: 1px solid #999999;\n    background-color: #cccccc;\n    color: #666666;\n    cursor: not-allowed;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"TradeDetailsTable": `TradeCallsTable_TradeDetailsTable__I0mw6`,
	"tableContainer": `TradeCallsTable_tableContainer__TEnP-`,
	"tableBodyContainer": `TradeCallsTable_tableBodyContainer__3goVs`,
	"statusClosed": `TradeCallsTable_statusClosed__mVMZb`,
	"colorCircleContainer": `TradeCallsTable_colorCircleContainer__ZHGqh`,
	"editBtn": `TradeCallsTable_editBtn__F8NTG`
};
export default ___CSS_LOADER_EXPORT___;
