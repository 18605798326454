import React, { useState } from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import { visuallyHidden } from "@mui/utils";

import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useQuery } from "@tanstack/react-query";
import { fetchAllAlertsData } from "../../../../api/userAlerts";
import MuiLinearProgressLoader from "../../../muiComps/MuiLinearProgressLoader";
import toast from "react-hot-toast";
import ReactHotToaster from "../../../common/ReactHotToaster";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

// TABLE HEADINGS
const headCells = [
  {
    id: "serial",
    numeric: true,
    disablePadding: true,
    label: "Sr.No.",
  },
  {
    id: "symbol",
    numeric: false,
    disablePadding: true,
    label: "SYMBOL",
  },
  {
    id: "alert_count",
    numeric: true,
    disablePadding: true,
    label: "ALERT COUNT",
  },
  {
    id: "price_alert_count",
    numeric: true,
    disablePadding: true,
    label: "PRICE ALERT",
  },
  {
    id: "buy_alert_count",
    numeric: true,
    disablePadding: true,
    label: "BUY ALERT",
  },
  {
    id: "sell_alert_count",
    numeric: true,
    disablePadding: true,
    label: "SELL ALERT",
  },
];

// const headCells2 = [
//   {
//     id: 'name',
//     numeric: false,
//     disablePadding: true,
//     label: 'Dessert (100g serving)',
//   },
//   {
//     id: 'calories',
//     numeric: true,
//     disablePadding: false,
//     label: 'Calories',
//   },
//   {
//     id: 'fat',
//     numeric: true,
//     disablePadding: false,
//     label: 'Fat (g)',
//   },
//   {
//     id: 'carbs',
//     numeric: true,
//     disablePadding: false,
//     label: 'Carbs (g)',
//   },
//   {
//     id: 'protein',
//     numeric: true,
//     disablePadding: false,
//     label: 'Protein (g)',
//   },
// ];

function EnhancedTableHead(props) {
  const {
    // onSelectAllClick,
    order,
    orderBy,
    // numSelected,
    // rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{ fontWeight: "bold", padding: "0 10px" }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const { numSelected } = props;

  // const headerStyles = {
  //   backgroundColor: "#F5B7B1",
  //   borderRadius: "2px",
  //   padding: "2px 4px",
  // };

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {/* {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: "1 1 100%" }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Alert Count for All Symbols
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )} */}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function AdminAlertsTable() {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(true);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  const {
    user: { userDetails },
  } = useSelector((store) => store.auth);

  // ALL USER ALERTS DATA FOR SUPER ADMINs
  const [allSymbolAlertsData, setAllSymbolAlertsData] = useState([]);
  const [alertCount, setAlertCount] = useState({});
  const { active_total_alert_count, total_alert_count } = alertCount;

  const formattedAllSymbolAlertsData = allSymbolAlertsData.map((item) => {
    return {
      alert_count: item.alert_count,
      ...item.data,
    };
  });

  // FETCH ALL ALERTS DATA
  const userAlertsQuery = useQuery({
    queryKey: ["all-alerts"],
    queryFn: () => fetchAllAlertsData(userDetails.id),
    onSuccess: (data) => {
      setAlertCount(data);
      setAllSymbolAlertsData(JSON.parse(data.response));
    },
    onError: (error) => {
      toast.error(error?.response?.data?.response || "Something went wrong");
    },
  });

  // useEffect(() => {
  //   const fetchAllAlertsData = async () => {
  //     try {
  //       const res = await axiosConfig({
  //         method: "get",
  //         url: `/admin_alert?userid=${userDetails.id}`,
  //         data: {},
  //       });

  //       if (res.status === 200 || res.statusText === "OK") {
  //         setAllSymbolAlertsData(res.data.response);

  //       }
  //     } catch (error) {
  //       console.log(error);

  //     }
  //   }

  //   fetchAllAlertsData();
  // }, [userDetails.id])

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  // const handleSelectAllClick = (event) => {
  //   if (event.target.checked) {
  //     const newSelected = formattedAllSymbolAlertsData.map((n) => n.name);
  //     setSelected(newSelected);
  //     return;
  //   }
  //   setSelected([]);
  // };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0
      ? Math.max(
          0,
          (1 + page) * rowsPerPage - formattedAllSymbolAlertsData.length
        )
      : 0;

  if (userAlertsQuery.isLoading) {
    return <MuiLinearProgressLoader />;
  }

  return (
    <>
      <div
        style={{
          marginTop: "20px",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          width: "100%", // Ensures full width for responsiveness
          padding: "1rem",
          backgroundColor: "#f5f5f5", // Subtle background color
          borderRadius: "8px", // Rounded corners
          boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)", // Soft shadow for depth
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
          }}
        >
          <Typography
            variant="h6"
            sx={{ marginBottom: "0.5rem", fontWeight: "bold" }}
          >
            Active Alerts:
          </Typography>
          <Typography
            variant="h6"
            sx={{ color: "#3f51b5", fontWeight: "500", paddingLeft: "10px" }}
          >
            {active_total_alert_count}
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
          }}
        >
          <Typography
            variant="h6"
            sx={{
              paddingLeft: "50px",
              marginBottom: "0.5rem",
              fontWeight: "bold",
            }}
          >
            Total Alerts:
          </Typography>
          <Typography
            variant="h6"
            sx={{ color: "#f50057", fontWeight: "500", paddingLeft: "10px" }}
          >
            {total_alert_count}
          </Typography>
        </Box>
      </div>
      <Box
        sx={{
          marginTop: "2rem",
          padding: "1rem",
          borderRadius: "8px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        }}
      >
        <Paper sx={{ maxWidth: 1500, mb: 2, padding: "0 1rem" }}>
          <EnhancedTableToolbar numSelected={selected.length} />
          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={dense ? "small" : "medium"}
            >
              <EnhancedTableHead
                // numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                // onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                // rowCount={rows.length}
              />
              <TableBody>
                {stableSort(
                  formattedAllSymbolAlertsData,
                  getComparator(order, orderBy)
                )
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const isItemSelected = isSelected(row.symbol);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        // onClick={(event) => handleClick(event, row.symbol)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.symbol}
                        selected={isItemSelected}
                      >
                        <TableCell align="right">{index + 1}</TableCell>
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                        >
                          <Link to={`/admin/alerts/${row.symbol}`}>
                            {/* <Typography sx={{ '&:hover': { textDecoration: 'underline'}}} >{row.symbol}</Typography> */}
                            <Typography
                              sx={{
                                "&:hover": { borderBottom: "1px solid" },
                                display: "inline-block",
                              }}
                            >
                              {row.symbol}
                            </Typography>
                          </Link>
                        </TableCell>

                        <TableCell align="right">{row.alert_count}</TableCell>

                        <TableCell align="right">
                          {row?.price_alert_count}
                        </TableCell>
                        <TableCell align="right">
                          {row?.buy_alert_count}
                        </TableCell>
                        <TableCell align="right">
                          {row?.sell_alert_count}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: (dense ? 33 : 53) * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50]}
            component="div"
            count={formattedAllSymbolAlertsData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
        {/* <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      /> */}
        {/* REACT HOT */}
        <ReactHotToaster />
      </Box>
    </>
  );
}
