// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UpdateNameEmailModal_mainContainer__q-qVa {
    min-width: 300px;
}

.UpdateNameEmailModal_lastNameContainer__XP1DS,
.UpdateNameEmailModal_emailContainer__HkCTD {
    text-transform: uppercase;
    margin: 1rem 0;
    display: flex;
    justify-content: space-between;
}

.UpdateNameEmailModal_saveBtnContainer__Dp203 {
    /* border: 1px solid red; */
    margin-top: 2rem;
    display: flex;
    justify-content: center;
}

.UpdateNameEmailModal_saveBtn__zJhBe {
    outline: none;
    border: 1px solid #ccc;
    padding: 0.3rem 2rem;
    color: #333;
    font-size: 1rem;
    font-weight: bold;
    letter-spacing: 2px;
    text-transform: uppercase;
    border-radius: 4px;
    cursor: pointer;
}

.UpdateNameEmailModal_saveBtn__zJhBe:hover {
    background-color: #333;
    color: #fff;
}`, "",{"version":3,"sources":["webpack://./src/components/UserDashboard/Profile/UpdateNameEmailModal/UpdateNameEmailModal.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;AACpB;;AAEA;;IAEI,yBAAyB;IACzB,cAAc;IACd,aAAa;IACb,8BAA8B;AAClC;;AAEA;IACI,2BAA2B;IAC3B,gBAAgB;IAChB,aAAa;IACb,uBAAuB;AAC3B;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,oBAAoB;IACpB,WAAW;IACX,eAAe;IACf,iBAAiB;IACjB,mBAAmB;IACnB,yBAAyB;IACzB,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI,sBAAsB;IACtB,WAAW;AACf","sourcesContent":[".mainContainer {\n    min-width: 300px;\n}\n\n.lastNameContainer,\n.emailContainer {\n    text-transform: uppercase;\n    margin: 1rem 0;\n    display: flex;\n    justify-content: space-between;\n}\n\n.saveBtnContainer {\n    /* border: 1px solid red; */\n    margin-top: 2rem;\n    display: flex;\n    justify-content: center;\n}\n\n.saveBtn {\n    outline: none;\n    border: 1px solid #ccc;\n    padding: 0.3rem 2rem;\n    color: #333;\n    font-size: 1rem;\n    font-weight: bold;\n    letter-spacing: 2px;\n    text-transform: uppercase;\n    border-radius: 4px;\n    cursor: pointer;\n}\n\n.saveBtn:hover {\n    background-color: #333;\n    color: #fff;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mainContainer": `UpdateNameEmailModal_mainContainer__q-qVa`,
	"lastNameContainer": `UpdateNameEmailModal_lastNameContainer__XP1DS`,
	"emailContainer": `UpdateNameEmailModal_emailContainer__HkCTD`,
	"saveBtnContainer": `UpdateNameEmailModal_saveBtnContainer__Dp203`,
	"saveBtn": `UpdateNameEmailModal_saveBtn__zJhBe`
};
export default ___CSS_LOADER_EXPORT___;
