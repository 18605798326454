import { Box, Button, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import MuiModal from "../../muiComps/MuiModal";
import DeactivateAccountModal from "./DeactivateAccountModal";
import { Link } from "react-router-dom";
import ReactHotToaster from "../../common/ReactHotToaster";

const UserSettings = () => {
  const [showDeactivateAccountModal, setShowDeactivateAccountModal] =
    useState(false);

  const handleDeactivate = () => {
    const prompt = window.confirm(
      "Are you sure you want to deactivate your account?"
    );

    if (prompt) {
      setShowDeactivateAccountModal(true);
    }
  };

  return (
    <Box>
      <div style={{ margin: "15px", marginLeft: "30px" }}>
        <h2>User Settings</h2>
      </div>

      {/* DEACTIVATE ACCOUNT BOX */}
      <Box
        sx={{
          width: "400px",
          margin: "2rem",
          padding: "2rem",
          backgroundColor: "#fff",
          // border: '1px solid red',
          borderRadius: "0.3rem",
          boxShadow: "2px 2px 4px 1px rgba(0,0,0,0.8)",
        }}
      >
        <Typography variant="subtitle1">
          You can temporarily{" "}
          <span
            style={{
              fontStyle: "italic",
            }}
          >
            deactivate your account
          </span>{" "}
          by clicking below. You can activate it later by visiting
          <Link to="/activate-account"> this link.</Link>
        </Typography>

        <Stack
          direction="row"
          sx={{
            // margin: '2rem',
            marginTop: "1rem",
          }}
        >
          <Button onClick={handleDeactivate} size="small" variant="contained">
            Deactivate Account
          </Button>
        </Stack>
      </Box>

      {/* Deactivate Modal */}
      <MuiModal
        open={showDeactivateAccountModal}
        setOpen={setShowDeactivateAccountModal}
      >
        <DeactivateAccountModal setOpen={setShowDeactivateAccountModal} />
      </MuiModal>

      {/* REACT HOT TOAST */}
      <ReactHotToaster />
    </Box>
  );
};

export default UserSettings;
