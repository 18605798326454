// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CreateTopUpForm_subscriptionFormContainer__nPGPy,
.CreateTopUpForm_formControl__ublbK {
  border: 1px solid #ccc;
  border-radius: 0.2rem;
}

.CreateTopUpForm_subscriptionFormContainer__nPGPy {
  width: 400px;
  max-width: 500px;
  margin: 1rem;
  padding: 1rem;

  background-color: #eee;
}

.CreateTopUpForm_formTitle__tpZzX {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 1rem;
  text-align: center;
  text-transform: uppercase;
}

.CreateTopUpForm_formGroup__HC3\\+n {
  margin: 0.8rem 0;
  text-transform: uppercase;

  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ccc;
  padding-bottom: 0.5rem;
  /* padding: 0.5rem 0; */
}

.CreateTopUpForm_formControl__ublbK {
  outline: none;
  border: none;
  padding: 0.4rem;
  /* border-bottom: 1px solid #ccc; */
  width: 60%;
  box-shadow: 2px 2px 4px #888888;
}

.CreateTopUpForm_btnContainer__7iEQ1 {
  margin: 1.5rem 0 0.5rem;
  display: flex;
  justify-content: center;
}

.CreateTopUpForm_btn__absZe {
  outline: none;
  border: none;
  padding: 0.3rem 0.6rem;
  border-radius: 0.2rem;
  font-size: 1.2rem;
  text-transform: uppercase;
  background-color: #333;
  color: #fff;
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/components/AdminDashboard/CreateTopUp/CreateTopUpForm/CreateTopUpForm.module.css"],"names":[],"mappings":"AAAA;;EAEE,sBAAsB;EACtB,qBAAqB;AACvB;;AAEA;EACE,YAAY;EACZ,gBAAgB;EAChB,YAAY;EACZ,aAAa;;EAEb,sBAAsB;AACxB;;AAEA;EACE,iBAAiB;EACjB,gBAAgB;EAChB,mBAAmB;EACnB,kBAAkB;EAClB,yBAAyB;AAC3B;;AAEA;EACE,gBAAgB;EAChB,yBAAyB;;EAEzB,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,6BAA6B;EAC7B,sBAAsB;EACtB,uBAAuB;AACzB;;AAEA;EACE,aAAa;EACb,YAAY;EACZ,eAAe;EACf,mCAAmC;EACnC,UAAU;EACV,+BAA+B;AACjC;;AAEA;EACE,uBAAuB;EACvB,aAAa;EACb,uBAAuB;AACzB;;AAEA;EACE,aAAa;EACb,YAAY;EACZ,sBAAsB;EACtB,qBAAqB;EACrB,iBAAiB;EACjB,yBAAyB;EACzB,sBAAsB;EACtB,WAAW;EACX,eAAe;AACjB","sourcesContent":[".subscriptionFormContainer,\n.formControl {\n  border: 1px solid #ccc;\n  border-radius: 0.2rem;\n}\n\n.subscriptionFormContainer {\n  width: 400px;\n  max-width: 500px;\n  margin: 1rem;\n  padding: 1rem;\n\n  background-color: #eee;\n}\n\n.formTitle {\n  font-size: 1.5rem;\n  font-weight: 600;\n  margin-bottom: 1rem;\n  text-align: center;\n  text-transform: uppercase;\n}\n\n.formGroup {\n  margin: 0.8rem 0;\n  text-transform: uppercase;\n\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  border-bottom: 1px solid #ccc;\n  padding-bottom: 0.5rem;\n  /* padding: 0.5rem 0; */\n}\n\n.formControl {\n  outline: none;\n  border: none;\n  padding: 0.4rem;\n  /* border-bottom: 1px solid #ccc; */\n  width: 60%;\n  box-shadow: 2px 2px 4px #888888;\n}\n\n.btnContainer {\n  margin: 1.5rem 0 0.5rem;\n  display: flex;\n  justify-content: center;\n}\n\n.btn {\n  outline: none;\n  border: none;\n  padding: 0.3rem 0.6rem;\n  border-radius: 0.2rem;\n  font-size: 1.2rem;\n  text-transform: uppercase;\n  background-color: #333;\n  color: #fff;\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"subscriptionFormContainer": `CreateTopUpForm_subscriptionFormContainer__nPGPy`,
	"formControl": `CreateTopUpForm_formControl__ublbK`,
	"formTitle": `CreateTopUpForm_formTitle__tpZzX`,
	"formGroup": `CreateTopUpForm_formGroup__HC3+n`,
	"btnContainer": `CreateTopUpForm_btnContainer__7iEQ1`,
	"btn": `CreateTopUpForm_btn__absZe`
};
export default ___CSS_LOADER_EXPORT___;
