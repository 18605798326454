import React from "react";

// import PreviewIcon from "@mui/icons-material/Preview";
import InfoIcon from "@mui/icons-material/Info";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from '@mui/icons-material/Delete';
import Tooltip from "@mui/material/Tooltip";

import toast from "react-hot-toast";
import { axiosConfig } from "../../../../../axios/axiosConfig";
import { useMutation } from "@tanstack/react-query";

import styles from "./BannerImageTable.module.css";

const BannerImageTable = ({ bannerImagesList, setBannerImagesList }) => {
  // POST TINYMCE TEXT TO BACKEND [CREATE]
  const deleteBannerMutation = useMutation({
    mutationFn: async (banner_id) => {
      const res = await axiosConfig({
        method: "delete",
        url: "/home/banner",
        data: {
          id: banner_id,
        },
      });
      return res.data;
    },
    onSuccess: (data, banner_id) => {
      setBannerImagesList((currBannerList) => {
        return currBannerList.filter((item) => item.id !== banner_id);
      });

      alert(data?.response);
    },
    onError: (error) => {
      toast.error(error?.response?.data?.response || "Something went wrong");
    },
  });

  const handleDeleteBanner = (BannerID) => {
    const consent = window.confirm("Are you sure you want to delete this image?");

    if (!consent) return;

    // If consent is true, send tinymce text
    deleteBannerMutation.mutate(BannerID);
  };

  return (
    <div className={styles.mainContainer}>
      <h2>BannerImageTable</h2>

      <table className={styles.tableContainer}>
        <thead>
          <tr>
            <th>#</th>
            <th>Image Link</th>
            <th>URL</th>
            <th>Preview</th>
            <th>Actions</th>
          </tr>
        </thead>

        <tbody className={styles.tableBodyContainer}>
          {bannerImagesList?.map((item, index) => {
            const imgPreview = item?.image ? item?.image : item?.redirect_url;

            return (
              <tr key={item.id}>
                <td>{index + 1}</td>
                <td>{item?.image}</td>
                <td>{item?.redirect_url}</td>

                {/* IMAGE PREVIEW */}
                <td>
                  
                  <Tooltip
                    // title={<img src={image.url} alt="Preview" style={{ width: 150, border: '1px solid #ddd' }} />}
                    title={
                      <img
                        src={imgPreview}
                        alt="Banner Preview"
                        style={{ width: 200 }}
                      />
                    }
                    placement="top"
                  >
                    <IconButton>
                      <InfoIcon color="primary" />
                    </IconButton>
                  </Tooltip>
                </td>

                <td>
                  <div className={styles.deleteBtnContainer}>
                    {/* <button
                      className={styles.deleteBtn}
                      onClick={() => handleDeleteBanner(item?.id)}
                    >
                      delete
                    </button> */}

                    <DeleteIcon className={styles.deleteIcon} color="error" onClick={() => handleDeleteBanner(item?.id)} />
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default BannerImageTable;
