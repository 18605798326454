// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SearchResultsPage_date__RglzJ {
  padding: 0 0.3rem;
  border: 1px solid green;
  border-radius: 0.3rem;
}

.SearchResultsPage_sectorTagWrapper__Pqzeh {
  max-width: 400px;
  height: auto;
  padding: 0.2rem 0;
  margin: 0.5rem 0;
  /* border: 1px solid red; */

  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.SearchResultsPage_sectorTagItem__Uwyw2 {
  padding: 0 0.4rem;
  border: 1px solid #ccc;
  border-radius: 0.2rem;

  text-transform: uppercase;
  font-size: 0.7rem;
  font-weight: 500;
  cursor: pointer;
}

.SearchResultsPage_sectorTagItem__Uwyw2:hover {
  background-color: #666;
  color: #fff;
}


.SearchResultsPage_previousRecommendationsBtn__h2Obd {
  cursor: pointer;
  border: none;
  outline: none;
  border-radius: 0.2rem;
  margin: 1rem 0;
  padding: 0.5rem 1rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 1.2rem;
  font-weight: 300;
  background-color: rgb(3, 166, 109);
  color: #fff;
}`, "",{"version":3,"sources":["webpack://./src/pages/SearchResultsPage/SearchResultsPage.module.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,uBAAuB;EACvB,qBAAqB;AACvB;;AAEA;EACE,gBAAgB;EAChB,YAAY;EACZ,iBAAiB;EACjB,gBAAgB;EAChB,2BAA2B;;EAE3B,aAAa;EACb,eAAe;EACf,WAAW;AACb;;AAEA;EACE,iBAAiB;EACjB,sBAAsB;EACtB,qBAAqB;;EAErB,yBAAyB;EACzB,iBAAiB;EACjB,gBAAgB;EAChB,eAAe;AACjB;;AAEA;EACE,sBAAsB;EACtB,WAAW;AACb;;;AAGA;EACE,eAAe;EACf,YAAY;EACZ,aAAa;EACb,qBAAqB;EACrB,cAAc;EACd,oBAAoB;EACpB,yBAAyB;EACzB,mBAAmB;EACnB,iBAAiB;EACjB,gBAAgB;EAChB,kCAAkC;EAClC,WAAW;AACb","sourcesContent":[".date {\n  padding: 0 0.3rem;\n  border: 1px solid green;\n  border-radius: 0.3rem;\n}\n\n.sectorTagWrapper {\n  max-width: 400px;\n  height: auto;\n  padding: 0.2rem 0;\n  margin: 0.5rem 0;\n  /* border: 1px solid red; */\n\n  display: flex;\n  flex-wrap: wrap;\n  gap: 0.5rem;\n}\n\n.sectorTagItem {\n  padding: 0 0.4rem;\n  border: 1px solid #ccc;\n  border-radius: 0.2rem;\n\n  text-transform: uppercase;\n  font-size: 0.7rem;\n  font-weight: 500;\n  cursor: pointer;\n}\n\n.sectorTagItem:hover {\n  background-color: #666;\n  color: #fff;\n}\n\n\n.previousRecommendationsBtn {\n  cursor: pointer;\n  border: none;\n  outline: none;\n  border-radius: 0.2rem;\n  margin: 1rem 0;\n  padding: 0.5rem 1rem;\n  text-transform: uppercase;\n  letter-spacing: 1px;\n  font-size: 1.2rem;\n  font-weight: 300;\n  background-color: rgb(3, 166, 109);\n  color: #fff;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"date": `SearchResultsPage_date__RglzJ`,
	"sectorTagWrapper": `SearchResultsPage_sectorTagWrapper__Pqzeh`,
	"sectorTagItem": `SearchResultsPage_sectorTagItem__Uwyw2`,
	"previousRecommendationsBtn": `SearchResultsPage_previousRecommendationsBtn__h2Obd`
};
export default ___CSS_LOADER_EXPORT___;
