// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
:root {
  /* --main-bg-color: brown; */
  --border-radius: 0.2rem;
  --border-dark: 1px solid #999;
  --border-light: 1px solid #ccc;
}

.TinyMCEEditorCommon_mainHeading__Z1bgQ {
  text-transform: uppercase;
  text-align: center;
}

.TinyMCEEditorCommon_createPostWrapper__ANtjC {
  /* min-width: 800px;
  padding: 1rem; */
}



.TinyMCEEditorCommon_instructionsContainer__dK8\\+g {
  margin: 1rem 0;
  padding: 0.2rem 0.4rem;
  display: inline-block;
  border: var(--border-light);
  border-radius: var(--border-radius);

  /* background-color: #FAC898; */
  background-color: #F2D0D9;
  
}

.TinyMCEEditorCommon_instructionsContainer__dK8\\+g ul {
  list-style: none;
}

.TinyMCEEditorCommon_instructionsContainer__dK8\\+g p,
.TinyMCEEditorCommon_instructionsContainer__dK8\\+g li {
  color: #333;
  /* color: #D24545; */
  font-size: 0.8rem;
  /* font-weight: bold; */
  text-transform: uppercase;
}

.TinyMCEEditorCommon_createPostBtn__HYPQ9,
.TinyMCEEditorCommon_updateBtn__bnjMD {
  cursor: pointer;
  outline: none;
  border: var(--border-dark);

  padding: 0.2rem;
  margin-top: 0.5rem;
  border-radius: var(--border-radius);

  text-transform: uppercase;
  font-weight: bold;
  color: #333;
}`, "",{"version":3,"sources":["webpack://./src/components/common/TinyMCEEditorCommon/TinyMCEEditorCommon.module.css"],"names":[],"mappings":";AACA;EACE,4BAA4B;EAC5B,uBAAuB;EACvB,6BAA6B;EAC7B,8BAA8B;AAChC;;AAEA;EACE,yBAAyB;EACzB,kBAAkB;AACpB;;AAEA;EACE;kBACgB;AAClB;;;;AAIA;EACE,cAAc;EACd,sBAAsB;EACtB,qBAAqB;EACrB,2BAA2B;EAC3B,mCAAmC;;EAEnC,+BAA+B;EAC/B,yBAAyB;;AAE3B;;AAEA;EACE,gBAAgB;AAClB;;AAEA;;EAEE,WAAW;EACX,oBAAoB;EACpB,iBAAiB;EACjB,uBAAuB;EACvB,yBAAyB;AAC3B;;AAEA;;EAEE,eAAe;EACf,aAAa;EACb,0BAA0B;;EAE1B,eAAe;EACf,kBAAkB;EAClB,mCAAmC;;EAEnC,yBAAyB;EACzB,iBAAiB;EACjB,WAAW;AACb","sourcesContent":["\n:root {\n  /* --main-bg-color: brown; */\n  --border-radius: 0.2rem;\n  --border-dark: 1px solid #999;\n  --border-light: 1px solid #ccc;\n}\n\n.mainHeading {\n  text-transform: uppercase;\n  text-align: center;\n}\n\n.createPostWrapper {\n  /* min-width: 800px;\n  padding: 1rem; */\n}\n\n\n\n.instructionsContainer {\n  margin: 1rem 0;\n  padding: 0.2rem 0.4rem;\n  display: inline-block;\n  border: var(--border-light);\n  border-radius: var(--border-radius);\n\n  /* background-color: #FAC898; */\n  background-color: #F2D0D9;\n  \n}\n\n.instructionsContainer ul {\n  list-style: none;\n}\n\n.instructionsContainer p,\n.instructionsContainer li {\n  color: #333;\n  /* color: #D24545; */\n  font-size: 0.8rem;\n  /* font-weight: bold; */\n  text-transform: uppercase;\n}\n\n.createPostBtn,\n.updateBtn {\n  cursor: pointer;\n  outline: none;\n  border: var(--border-dark);\n\n  padding: 0.2rem;\n  margin-top: 0.5rem;\n  border-radius: var(--border-radius);\n\n  text-transform: uppercase;\n  font-weight: bold;\n  color: #333;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mainHeading": `TinyMCEEditorCommon_mainHeading__Z1bgQ`,
	"createPostWrapper": `TinyMCEEditorCommon_createPostWrapper__ANtjC`,
	"instructionsContainer": `TinyMCEEditorCommon_instructionsContainer__dK8+g`,
	"createPostBtn": `TinyMCEEditorCommon_createPostBtn__HYPQ9`,
	"updateBtn": `TinyMCEEditorCommon_updateBtn__bnjMD`
};
export default ___CSS_LOADER_EXPORT___;
