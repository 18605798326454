import React from "react";

import styles from "./TicketHistoryTable.module.css";
import { useNavigate } from "react-router-dom";

const TicketHistoryTable = ({ userTickets }) => {
  const navigate = useNavigate();

  return (
    <div className={styles.outerContainer}>
      <div style={{ margin: "15px", marginLeft: "0px" }}>
        <h2>Ticket History Table</h2>
      </div>

      <table className={styles.tableContainer}>
        <thead>
          <tr>
            <th>Ticket ID</th>
            <th>Title</th>
            <th>Description</th>
            <th>Created On</th>
            <th>Status</th>
            <th>Last Updated</th>
            <th>Actions</th>
          </tr>
        </thead>

        <tbody className={styles.tableBodyContainer}>
          {userTickets.map((item, index) => {
            const createdDate = new Date(item.created_at).toDateString();
            const modifiedDate = new Date(item.modified_at).toDateString();

            return (
              <tr key={item.id}>
                <td>{item.ticket_id}</td>
                <td>{item.title}</td>
                <td>{item.description}</td>
                <td>{createdDate}</td>
                <td>{item.status}</td>
                <td>{modifiedDate}</td>

                <td>
                  <div className={styles.viewBtnContainer}>
                    <button
                      className={styles.viewBtn}
                      onClick={() =>
                        navigate(`/user-dashboard/support/${item.id}`)
                      }
                    >
                      view
                    </button>
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default TicketHistoryTable;
