// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.add-member-wrappper{
  margin: 10px;
  background-color: rgba(208, 235, 135, 0.305);
  border-radius: 8px;
  padding: 10px;
}

.add-member-fields-container {
  display: flex;
}

.team-member-action {
margin-top: 20px;
}`, "",{"version":3,"sources":["webpack://./src/Member/AddTeamMember.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,4CAA4C;EAC5C,kBAAkB;EAClB,aAAa;AACf;;AAEA;EACE,aAAa;AACf;;AAEA;AACA,gBAAgB;AAChB","sourcesContent":[".add-member-wrappper{\n  margin: 10px;\n  background-color: rgba(208, 235, 135, 0.305);\n  border-radius: 8px;\n  padding: 10px;\n}\n\n.add-member-fields-container {\n  display: flex;\n}\n\n.team-member-action {\nmargin-top: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
