import React from "react";
import { Button, Box, Stack, TextField } from "@mui/material";
// import SaveIcon from "@mui/icons-material/Save";
import { useNavigate } from "react-router-dom";

const CommonWatchlistForm = ({
  onClickHandler,
  dashboardName,
  setDashboardName,
  dashboardDesc,
  setDashboardDesc,
}) => {
  const navigate = useNavigate();

  return (
    <Box>
      <Stack spacing={4} sx={{ maxWidth: 500, mb: 4 }}>
        <Stack spacing={1}>
          <span>Name</span>
          <TextField
            required
            size="small"
            id="name"
            // label="Name"
            variant="outlined"
            value={dashboardName}
            // defaultValue={defaultDashboardNameValue}
            onChange={(e) => setDashboardName(e.target.value)}
            helperText="MAX 40 CHARACTERS"
            inputProps={{ maxLength: "40" }}
          />
        </Stack>

        <Stack spacing={1}>
          <span>Description</span>
          <TextField
            id="description"
            label="Description"
            variant="outlined"
            multiline
            rows={3}
            // maxRows={Infinity}
            value={dashboardDesc}
            onChange={(e) => setDashboardDesc(e.target.value)}
          />
        </Stack>
      </Stack>

      <Stack direction="row" gap={1}>
        <Button variant="contained" onClick={onClickHandler}>
          save
        </Button>

        <Button variant="contained" onClick={() => navigate("/watchlist")}>
          back
        </Button>
      </Stack>
    </Box>
  );
};

export default CommonWatchlistForm;
