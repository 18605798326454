import Box from "@mui/material/Box";
import React from "react";
import AdminAlertsTable from "./AdminAlertsTable";
// import AdminAlertsTable from '../../components/adminAlerts/AdminAlertsTable';

const AdminAlertsPage = () => {
  return (
    <Box sx={{ p: "1rem 2rem" }}>
      <h2>Admin Alerts</h2>

      {/* ADMIN ALERTS SYMBOL TABLE HERE */}
      <AdminAlertsTable />
    </Box>
  );
};

export default AdminAlertsPage;
