import React from "react";

import GroupsIcon from "@mui/icons-material/Groups";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import RedeemIcon from "@mui/icons-material/Redeem";
import NotesIcon from "@mui/icons-material/Notes";
import ListIcon from "@mui/icons-material/List";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import UploadIcon from "@mui/icons-material/Upload";
import ReceiptIcon from '@mui/icons-material/Receipt';

import DashboardLayout from "../components/common/DashboardLayout";

// const user = JSON.parse(localStorage.getItem("user"));

// const drawerWidth = 240;

// IMP: COMMENTED OUT few items AS BACKEND CODE NOT READY, TESTING NOT DONE [17-04-2024]
const mainListItemsArray = [
  {
    id: 1,
    label: "Users",
    link: "/admin-dashboard/users",
    icon: <GroupsIcon />,
  },
  {
    id: 2,
    label: "Alerts",
    link: "/admin-dashboard/alerts",
    icon: <NotificationsActiveIcon />,
  },

  // {
  //   id: 4,
  //   label: "Ads",
  //   link: "/admin-dashboard/ads",
  //   icon: <CollectionsIcon />,
  // },

  {
    id: 6,
    label: "Create Ticker",
    link: "/admin-dashboard/create-ticker",
    icon: <NotesIcon />,
  },
  // {
  //   id: 7,
  //   label: "Referral Programs",
  //   link: "/admin-dashboard/create-referral-program",
  //   icon: <NotesIcon />,
  // },
  {
    id: 8,
    label: "Payments",
    link: "/admin-dashboard/payments-dashboard",
    icon: <ReceiptIcon />,
  },
];

// IMP: COMMENTED OUT AS BACKEND CODE NOT READY, TESTING NOT DONE
const secondaryListItemsArray = [
  {
    id: 1,
    label: "Coupons",
    link: "/admin-dashboard/coupons",
    icon: <RedeemIcon />,
  },
  {
    id: 2,
    label: "Create Plan",
    link: "/admin-dashboard/create-plan",
    icon: <ListIcon />,
  },
  {
    id: 3,
    label: "Create TopUp",
    link: "/admin-dashboard/create-topup",
    icon: <ListIcon />,
  },
  {
    id: 4,
    label: "App Home Screen",
    link: "/admin-dashboard/home-screen-content",
    icon: <ListIcon />,
  },
  {
    id: 5,
    label: "User Support",
    link: "/admin-dashboard/user-support",
    icon: <ListIcon />,
  },
  {
    id: 6,
    label: "Refunds",
    link: "/admin-dashboard/user-refunds",
    icon: <CurrencyExchangeIcon />,
  },
  {
    id: 7,
    label: "Upload Banners",
    link: "/admin-dashboard/upload-banners",
    icon: <UploadIcon />,
  },
];

export default function AdminDashboardLayout() {
  return (
    <DashboardLayout
      mainListItems={mainListItemsArray}
      secondListItems={secondaryListItemsArray}
    />
  );
}
