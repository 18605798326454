// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.InfoCard_outerWrapper__dgZrz {
  width: 300px;
  height: 300px;
  padding: 2rem;
  /* border: 1px solid red; */
  border-radius: 0.4rem;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

}

.InfoCard_innerWrapper__YEaHg {
  /* border: 1px solid green; */
  display: flex;
  gap: 1rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}


.InfoCard_image__eR9Yh {
  width: 60px;
  border-radius: 50%;
}

h2.InfoCard_title__kFPfY {
  font-size: 2rem;
  text-align: center;
}

.InfoCard_description__yzwpe {
  text-align: center;
  font-weight: 300;
  color: rgba(86, 86, 86, 1);

}`, "",{"version":3,"sources":["webpack://./src/components/homepage/InfoCards/InfoCard/InfoCard.module.css"],"names":[],"mappings":";AACA;EACE,YAAY;EACZ,aAAa;EACb,aAAa;EACb,2BAA2B;EAC3B,qBAAqB;EACrB,+CAA+C;;AAEjD;;AAEA;EACE,6BAA6B;EAC7B,aAAa;EACb,SAAS;EACT,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;AACzB;;;AAGA;EACE,WAAW;EACX,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,gBAAgB;EAChB,0BAA0B;;AAE5B","sourcesContent":["\n.outerWrapper {\n  width: 300px;\n  height: 300px;\n  padding: 2rem;\n  /* border: 1px solid red; */\n  border-radius: 0.4rem;\n  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);\n\n}\n\n.innerWrapper {\n  /* border: 1px solid green; */\n  display: flex;\n  gap: 1rem;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n}\n\n\n.image {\n  width: 60px;\n  border-radius: 50%;\n}\n\nh2.title {\n  font-size: 2rem;\n  text-align: center;\n}\n\n.description {\n  text-align: center;\n  font-weight: 300;\n  color: rgba(86, 86, 86, 1);\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"outerWrapper": `InfoCard_outerWrapper__dgZrz`,
	"innerWrapper": `InfoCard_innerWrapper__YEaHg`,
	"image": `InfoCard_image__eR9Yh`,
	"title": `InfoCard_title__kFPfY`,
	"description": `InfoCard_description__yzwpe`
};
export default ___CSS_LOADER_EXPORT___;
