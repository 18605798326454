// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .no-results-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.no-results-text {
  font-size: 2rem;
  font-weight: bold;
  color: #555;
  text-align: center;
  margin: 0;
} */


.no-results-container {
  margin: 3rem 0 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  /* width: 100%; */
  /* height: 100%; */
  background: linear-gradient(to bottom right, #5f27cd, #341f97);
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
}

.no-results-text {
  /* width: 100%; */
  text-transform: uppercase;
  padding: 0.5rem 0;
  font-size: 2rem;
  /* font-weight: 100; */
  color: white;
  text-align: center;
  margin: 0;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
}`, "",{"version":3,"sources":["webpack://./src/components/common/NoResultsToDisplay/NoResultsToDisplay.css"],"names":[],"mappings":"AAAA;;;;;;;;;;;;;GAaG;;;AAGH;EACE,mBAAmB;EACnB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,iBAAiB;EACjB,kBAAkB;EAClB,8DAA8D;EAC9D,mBAAmB;EACnB,2CAA2C;AAC7C;;AAEA;EACE,iBAAiB;EACjB,yBAAyB;EACzB,iBAAiB;EACjB,eAAe;EACf,sBAAsB;EACtB,YAAY;EACZ,kBAAkB;EAClB,SAAS;EACT,2CAA2C;AAC7C","sourcesContent":["/* .no-results-container {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 100%;\n}\n\n.no-results-text {\n  font-size: 2rem;\n  font-weight: bold;\n  color: #555;\n  text-align: center;\n  margin: 0;\n} */\n\n\n.no-results-container {\n  margin: 3rem 0 2rem;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  /* width: 100%; */\n  /* height: 100%; */\n  background: linear-gradient(to bottom right, #5f27cd, #341f97);\n  border-radius: 10px;\n  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);\n}\n\n.no-results-text {\n  /* width: 100%; */\n  text-transform: uppercase;\n  padding: 0.5rem 0;\n  font-size: 2rem;\n  /* font-weight: 100; */\n  color: white;\n  text-align: center;\n  margin: 0;\n  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
