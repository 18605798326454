import React, { useState } from "react";
import VerifiedIcon from "@mui/icons-material/Verified";
import { useNavigate } from "react-router-dom";
import styles from "../PricingPlans.module.css";
import { YEARLY_DISCOUNT } from "../../../utils/globalConstants";
// import { useMutation } from "@tanstack/react-query";
// import { axiosConfig } from "../../../axios/axiosConfig";
// import toast from "react-hot-toast";
// import { useSelector } from "react-redux";
import MuiModal from "../../muiComps/MuiModal";
import UpdateUserDetailsForm from "./UpdateUserDetailsForm";

// const YEARLY_DISCOUNT = 0.2; // assuming discount is 10%

// PREMIUM TIER LIST
const featuresList = [
  { id: 1, label: <VerifiedIcon />, value: "screener" },
  { id: 2, label: <VerifiedIcon />, value: "options-chart" },
  { id: 3, label: <VerifiedIcon />, value: "backtesting" },
  { id: 4, label: <VerifiedIcon />, value: "stocks-chart" },
  { id: 5, label: <VerifiedIcon />, value: "dashboards" },
  { id: 6, label: <VerifiedIcon />, value: "watchlist" },
  { id: 7, label: <VerifiedIcon />, value: "alerts" },
  { id: 8, label: <VerifiedIcon />, value: "email-support" },
  // { id: 9, label: "BUY", value: "buy-premium-tier" },
];

// This is new component made on 27th June, 2023
const PricingTileNew = ({ tier, planUpgradeType }) => {
  const navigate = useNavigate();
  // const { user: currentUser } = useSelector((state) => state.auth);
  // const loggedInUser = currentUser?.userDetails;
  // const USERID = currentUser.userDetails.id;

  const [openUserUpdateForm, setOpenUserUpdateForm] = useState(false);

  const handleClickNavigate = (selectedTier) => {
    // const userProfileNotUpdated = !loggedInUser?.email || loggedInUser?.email.trim() === "" || !loggedInUser?.username || loggedInUser?.username.trim() === ""
    // if (userProfileNotUpdated) {
    //   setOpenUserUpdateForm(true);
    //   return;
    // }
    // navigate state refresh error due to sending icon comp in state. (complex state)
    // so not sending <Icon /> in tier object
    // const formattedTier = {
    //   price: selectedTier.price,
    //   subheader: selectedTier.subheader,
    //   title: selectedTier.title,
    // };

    // const state = tier;
    const state = { planType: planUpgradeType, selectedPlan: tier };
    navigate("/cart", { state });
  };


  return (
    <div>
      <ul className={styles.free_tier_column}>
        <li className={styles.heading_list_item}>
          <h2 className={styles.plan_title}>{tier.name}</h2>
          <span>{tier.description}</span>
          <h3>
            &#8377; {tier.price}
            <span className={styles.price_span}>monthly</span>
          </h3>
        </li>

        {featuresList.map((item) => {
          return <li key={item.id}>{item.label}</li>;
        })}

        <li>
          <div className={styles.btnContainer}>
            {/* {planUpgradeType === "update" ? (
            <button
              className={styles.choose_plan_button}
              onClick={handleUpdatePlan}
            >
              Change Plan
            </button>
          ) : (
            <button
              className={styles.choose_plan_button}
              onClick={() => handleClickNavigate(tier)}
            >
              buy new plan
            </button>
          )} */}
            <button
              className={styles.choose_plan_button}
              onClick={handleClickNavigate}
            >
              Choose Plan
            </button>
          </div>
        </li>
      </ul>

      {/* update profile modal popup */}
      <MuiModal open={openUserUpdateForm} setOpen={setOpenUserUpdateForm}>
        <UpdateUserDetailsForm />
      </MuiModal>
    </div>
  );
};

export default PricingTileNew;

// UTILITY FUNCTIONS
// function calcTotalPrice(selected_tier, plan_period) {
//   let total_price = 1;

//   if (plan_period === "monthly") {
//     total_price = selected_tier.price;
//   }

//   // if yearly plan is selected, then multiply by 12, and apply the discount
//   if (plan_period === "yearly") {
//     let yearly_price = selected_tier.price * 12; // yearly price without discount
//     total_price = yearly_price - yearly_price * YEARLY_DISCOUNT;
//   }

//   return total_price;
// }
