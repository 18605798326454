// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AdBannersTable_tableContainer__6uSA1 {
  width: 100%;
  max-width: 1000px;
  border-collapse: collapse;
  border: 1px solid #ccc;
  /* background-color: red; */
}

.AdBannersTable_tableContainer__6uSA1 th,
.AdBannersTable_tableContainer__6uSA1 td {
  padding: 8px;
  text-align: left;
  border: 1px solid #ccc;
}

.AdBannersTable_tableContainer__6uSA1 th {
  background-color: #f2f2f2;
}

.AdBannersTable_tableBodyContainer__7V\\+0s tr:nth-child(even) {
  background-color: #f2f2f2;
}

.AdBannersTable_imgPreviewBox__jtKSe {
  width: 100px;
}

.AdBannersTable_imgPreview__6oHGr {
  width: 100%;
}

.AdBannersTable_container__idtvy {
  margin: 15px;
}

.AdBannersTable_title__FOrpW {
  margin: 10px;
}
.AdBannersTable_deleteIcon__rFzdc {
  width: 20px;
  height: 20px;
  fill: var(--danger-color);
  cursor: pointer;
  transition: transform 0.2s ease, fill 0.2s ease;
}

.AdBannersTable_deleteIcon__rFzdc:hover {
  transform: scale(1.2);
  fill: #a71d2a;
}
`, "",{"version":3,"sources":["webpack://./src/components/AdminDashboard/UploadBanners/AdBannersTable/AdBannersTable.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,iBAAiB;EACjB,yBAAyB;EACzB,sBAAsB;EACtB,2BAA2B;AAC7B;;AAEA;;EAEE,YAAY;EACZ,gBAAgB;EAChB,sBAAsB;AACxB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,YAAY;AACd;AACA;EACE,WAAW;EACX,YAAY;EACZ,yBAAyB;EACzB,eAAe;EACf,+CAA+C;AACjD;;AAEA;EACE,qBAAqB;EACrB,aAAa;AACf","sourcesContent":[".tableContainer {\n  width: 100%;\n  max-width: 1000px;\n  border-collapse: collapse;\n  border: 1px solid #ccc;\n  /* background-color: red; */\n}\n\n.tableContainer th,\n.tableContainer td {\n  padding: 8px;\n  text-align: left;\n  border: 1px solid #ccc;\n}\n\n.tableContainer th {\n  background-color: #f2f2f2;\n}\n\n.tableBodyContainer tr:nth-child(even) {\n  background-color: #f2f2f2;\n}\n\n.imgPreviewBox {\n  width: 100px;\n}\n\n.imgPreview {\n  width: 100%;\n}\n\n.container {\n  margin: 15px;\n}\n\n.title {\n  margin: 10px;\n}\n.deleteIcon {\n  width: 20px;\n  height: 20px;\n  fill: var(--danger-color);\n  cursor: pointer;\n  transition: transform 0.2s ease, fill 0.2s ease;\n}\n\n.deleteIcon:hover {\n  transform: scale(1.2);\n  fill: #a71d2a;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tableContainer": `AdBannersTable_tableContainer__6uSA1`,
	"tableBodyContainer": `AdBannersTable_tableBodyContainer__7V+0s`,
	"imgPreviewBox": `AdBannersTable_imgPreviewBox__jtKSe`,
	"imgPreview": `AdBannersTable_imgPreview__6oHGr`,
	"container": `AdBannersTable_container__idtvy`,
	"title": `AdBannersTable_title__FOrpW`,
	"deleteIcon": `AdBannersTable_deleteIcon__rFzdc`
};
export default ___CSS_LOADER_EXPORT___;
