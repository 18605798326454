import React from "react";
import AllUsersTicketHistoryTable from "./AllUsersTicketHistoryTable";
import BackButton from "../../common/BackButton";

const UserSupportPanel = () => {
  return (
    <div>
      <div style={{ margin: "10px", marginLeft: "25px" }}>
        <BackButton />
      </div>

      {/* users tickets table */}
      <AllUsersTicketHistoryTable
        title="Admin Support Panel"
        userType="admin"
      />
    </div>
  );
};

export default UserSupportPanel;
