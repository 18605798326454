import React, { useState } from "react";

import styles from "./CreateTopUpForm.module.css";
import { useMutation } from "@tanstack/react-query";
import { axiosConfig } from "../../../../axios/axiosConfig";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";

const CreateTopUpForm = ({
  formType = "",
  activeTopUp,
  setAllTopUpDetails,
  setOpenTopUpForm,
}) => {
  const { user } = useSelector((state) => state.auth);
  const USERID = user?.userDetails?.id;

  // planInput state will be initialized with activeTopUp if "editing" or updating subscription form
  const [planInput, setPlanInput] = useState({
    name: formType === "edit" ? activeTopUp?.title : "",
    code: formType === "edit" ? activeTopUp?.topup_code : "",
    description: formType === "edit" ? activeTopUp?.description : "",
    features: formType === "edit" ? activeTopUp?.topup_description : "",
    quantity: formType === "edit" ? activeTopUp?.quantity : "",
    price: formType === "edit" ? activeTopUp?.price : "",
    active: formType === "edit" ? activeTopUp?.active : true,
    interval: formType === "edit" ? activeTopUp?.interval : "",
    interval_unit: formType === "edit" ? activeTopUp?.interval_unit : "monthly",
  });
  // const [isPlanActive, setIsPlanActive] = useState(
  //   activeTopUp?.active || false
  // );

  const { name, code, description, features, price } = planInput;

  // onchange handler for all inputs
  const inputOnChange = (event) => {
    // 
    setPlanInput((prevState) => {
      return {
        ...prevState,
        [event.target.name]:
          event.target.name === "active"
            ? event.target.checked
            : event.target.value,
      };
    });
  };

  const METHOD_TYPE = formType === "edit" ? "patch" : "post";

  // CREATE NEW or update subscription plan
  const postTopUp = useMutation({
    mutationFn: async () =>
      mutateTopUp(METHOD_TYPE, planInput, USERID, activeTopUp?.id),
    onSuccess: (data) => {
      
      if (formType === "edit") {
        setAllTopUpDetails((prevPlans) => {
          return prevPlans.map((plan) => {
            if (plan.id === activeTopUp?.id) {
              return data.response;
            } else {
              return plan;
            }
          });
        });
      } else if (formType === "create") {
        setAllTopUpDetails((prevPlans) => {
          return [...prevPlans, data.response];
        });
      }
    },
    onError: (error) => {
      toast.error(error.message || "Something went wrong");
    },
  });

  // HANDLE CREATE / POST NEW MARQUEE
  const handleMutatePlan = (event) => {
    event.preventDefault();

    const consent = window.confirm(
      `Are you sure you want to ${formType} Plan?`
    );
    if (!consent) {
      return;
    }

    postTopUp.mutate();
    setOpenTopUpForm(false);
  };

  const disableCreateBtn =
    !name ||
    name.trim() === "" ||
    !code ||
    code.trim() === "" ||
    !description ||
    description.trim() === "" ||
    !features ||
    features.trim() === "" ||
    !price;

  return (
    <form action="" className={styles.subscriptionFormContainer}>
      <h2 className={styles.formTitle}>
        {formType === "edit" ? "Update TopUp" : "Create TopUp"}
      </h2>

      <div className={styles.formGroup}>
        <label htmlFor="name">TopUp Name</label>
        <input
          // placeholder="eg. "
          type="text"
          className={styles.formControl}
          id="name"
          name="name"
          value={planInput.name}
          onChange={inputOnChange}
        />
      </div>

      <div className={styles.formGroup}>
        <label htmlFor="code">TopUp Code</label>
        <input
          placeholder="eg. ALERT100"
          disabled={formType === "edit"}
          type="text"
          className={styles.formControl}
          id="code"
          name="code"
          value={planInput.code}
          onChange={inputOnChange}
        />
      </div>
      <div className={styles.formGroup}>
        <label htmlFor="description">Description</label>
        <input
          placeholder="a brief description"
          type="text"
          className={styles.formControl}
          id="description"
          name="description"
          value={planInput.description}
          onChange={inputOnChange}
        />
      </div>

      {/* Plan Features/Description (plan_description) */}
      <div className={styles.formGroup}>
        <label htmlFor="features">Features</label>
        <input
          placeholder="feature1, feature2, etc."
          type="text"
          className={styles.formControl}
          id="features"
          name="features"
          value={planInput.features}
          onChange={inputOnChange}
        />
      </div>
      <div className={styles.formGroup}>
        <label htmlFor="price">Price</label>
        <input
          placeholder="199 (price in rupees)"
          type="text"
          className={styles.formControl}
          id="price"
          name="price"
          value={planInput.price}
          onChange={inputOnChange}
        />
      </div>
      <div className={styles.formGroup}>
        <label htmlFor="interval">Quantity</label>
        <input
          placeholder="500 (number of Alerts)"
          type="text"
          className={styles.formControl}
          id="quantity"
          name="quantity"
          value={planInput.quantity}
          onChange={inputOnChange}
        />
      </div>

      <div className={styles.formGroup}>
        <label htmlFor="interval">Interval</label>
        <input
          placeholder="2 (number of months or year)"
          type="text"
          className={styles.formControl}
          id="interval"
          name="interval"
          value={planInput.interval}
          onChange={inputOnChange}
        />
      </div>

      {/* Interval Units, delete not needed */}
      <div className={styles.formGroup}>
        <label htmlFor="intervalUnit">Interval Unit</label>
        <select
          className={styles.formControl}
          id="intervalUnit"
          name="intervalUnit"
          value={planInput.interval_unit}
          onChange={inputOnChange}
        >
          {intervalOptions.map((item) => {
            return (
              <option key={item.value} value={item.value}>
                {item.label}
              </option>
            );
          })}
        </select>
      </div>

      {/* ACTIVE OR DEACTIVE */}
      {formType === "edit" ? (
        <div className={styles.formGroup}>
          <label htmlFor="active">Active (Uncheck to De-activate)</label>
          <input
            type="checkbox"
            // className={styles.formControl}
            id="active"
            name="active"
            // checked={}
            defaultChecked={planInput.active}
            value={planInput.active}
            // onChange={(e) => setIsPlanActive(e.target.checked)}
            onChange={inputOnChange}
          />
        </div>
      ) : null}

      {/* button container */}
      <div className={styles.btnContainer}>
        {formType === "edit" ? (
          <button
            // disabled={disableCreateBtn}
            // className="btn editPlanBtn"
            className={`${styles.btn} ${styles.editPlanBtn}`}
            onClick={handleMutatePlan}
          >
            update
          </button>
        ) : (
          <button
            type="submit"
            disabled={disableCreateBtn}
            // className="btn createPlanBtn"
            className={`${styles.btn} ${styles.createPlanBtn}`}
            onClick={handleMutatePlan}
          >
            Create TopUp
          </button>
        )}
      </div>
    </form>
  );
};

export default CreateTopUpForm;

const intervalOptions = [
  { id: 1, value: "monthly", label: "monthly" },
  { id: 2, value: "yearly", label: "yearly" },
  // { id: 3, value: "weeks", label: "weeks" },
  // { id: 4, value: "days", label: "days" },
];

// MUTATE (CREATE & UPDATE) subscription plan
export const mutateTopUp = async (method, topup_input, USERID, topup_id) => {
  if (!USERID) return;
  const {
    name,
    code,
    description,
    features,
    price,
    quantity,
    interval,
    interval_unit,
    active,
  } = topup_input;

  let apiURL = "/subscription/topup_plan"; // for post method only
  apiURL =
    method === "patch"
      ? `/subscription/topup_plan?topup_plan_id=${topup_id}`
      : apiURL;

  const res = await axiosConfig({
    method: method,
    url: apiURL,
    data: {
      userid: USERID,
      title: name,
      topup_code: code,
      description: description,
      topup_description: features,
      price: price,
      quantity: quantity,
      interval: interval,
      interval_unit: interval_unit,
      active: active,
    },
  });
  return res.data;
};
