// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Global Styles for the whole app */


/* BLINKING BACKGROUND ANIMATION */
@keyframes blinking {
  0% {
    background-color: #fff;
    /* border-top: 2px solid #5fed9d;
    border-bottom: 2px solid #5fed9d; */
    /* color: #fff; */
    /* border: 1px solid #666; */
  }
  100% {
    /* background-color: rgb(111, 235, 192); */
    background-color: #baf1d2;
    /* color: #fff; */
    /* border: 1px solid #666; */
  }
}
.blink_background {
  /* animation: blinking 2s infinite; */
  animation-name: blinking;
  animation-duration: 0.8s;
  animation-iteration-count: infinite;
  animation-direction: alternate-reverse;
  animation-timing-function: ease-in-out;
}`, "",{"version":3,"sources":["webpack://./src/styles/globalStyles.css"],"names":[],"mappings":"AAAA,oCAAoC;;;AAGpC,kCAAkC;AAClC;EACE;IACE,sBAAsB;IACtB;uCACmC;IACnC,iBAAiB;IACjB,4BAA4B;EAC9B;EACA;IACE,0CAA0C;IAC1C,yBAAyB;IACzB,iBAAiB;IACjB,4BAA4B;EAC9B;AACF;AACA;EACE,qCAAqC;EACrC,wBAAwB;EACxB,wBAAwB;EACxB,mCAAmC;EACnC,sCAAsC;EACtC,sCAAsC;AACxC","sourcesContent":["/* Global Styles for the whole app */\n\n\n/* BLINKING BACKGROUND ANIMATION */\n@keyframes blinking {\n  0% {\n    background-color: #fff;\n    /* border-top: 2px solid #5fed9d;\n    border-bottom: 2px solid #5fed9d; */\n    /* color: #fff; */\n    /* border: 1px solid #666; */\n  }\n  100% {\n    /* background-color: rgb(111, 235, 192); */\n    background-color: #baf1d2;\n    /* color: #fff; */\n    /* border: 1px solid #666; */\n  }\n}\n.blink_background {\n  /* animation: blinking 2s infinite; */\n  animation-name: blinking;\n  animation-duration: 0.8s;\n  animation-iteration-count: infinite;\n  animation-direction: alternate-reverse;\n  animation-timing-function: ease-in-out;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
