// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* TAble styling */

.TicketHistoryTable_outerContainer__jQBfg {
  margin: 2rem;
}

.TicketHistoryTable_tableTitle__lMeoH {
  text-align: center;
  margin-bottom: 1rem;
}

.TicketHistoryTable_tableContainer__zhSYr {
  width: 100%;
  max-width: 900px;
  border-collapse: collapse;
  border: 1px solid #ccc;
  /* background-color: red; */
}

.TicketHistoryTable_tableContainer__zhSYr th,
.TicketHistoryTable_tableContainer__zhSYr td {
  padding: 8px;
  text-align: left;
  border: 1px solid #ccc;
}

.TicketHistoryTable_tableContainer__zhSYr th {
  background-color: #f2f2f2;
}

.TicketHistoryTable_tableBodyContainer__3vYSz tr:nth-child(even) {
  background-color: #f2f2f2;
}

.TicketHistoryTable_btn__q1M31,
.TicketHistoryTable_viewBtn__PyNH0 {
  outline: none;
  border: 1px solid #333;
  padding: 0.3rem 0.6rem;
  border-radius: 0.2rem;
  /* font-weight: bold; */
  /* font-size: 1.2rem; */
  text-transform: uppercase;
  background-color: #98FB98;
  color: #333;
  cursor: pointer;
}

.TicketHistoryTable_viewBtn__PyNH0:hover {
  background-color: #2b682b;
  color: #fff;
}`, "",{"version":3,"sources":["webpack://./src/components/UserDashboard/Support/TicketHistoryTable/TicketHistoryTable.module.css"],"names":[],"mappings":"AAAA,kBAAkB;;AAElB;EACE,YAAY;AACd;;AAEA;EACE,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,gBAAgB;EAChB,yBAAyB;EACzB,sBAAsB;EACtB,2BAA2B;AAC7B;;AAEA;;EAEE,YAAY;EACZ,gBAAgB;EAChB,sBAAsB;AACxB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;;EAEE,aAAa;EACb,sBAAsB;EACtB,sBAAsB;EACtB,qBAAqB;EACrB,uBAAuB;EACvB,uBAAuB;EACvB,yBAAyB;EACzB,yBAAyB;EACzB,WAAW;EACX,eAAe;AACjB;;AAEA;EACE,yBAAyB;EACzB,WAAW;AACb","sourcesContent":["/* TAble styling */\n\n.outerContainer {\n  margin: 2rem;\n}\n\n.tableTitle {\n  text-align: center;\n  margin-bottom: 1rem;\n}\n\n.tableContainer {\n  width: 100%;\n  max-width: 900px;\n  border-collapse: collapse;\n  border: 1px solid #ccc;\n  /* background-color: red; */\n}\n\n.tableContainer th,\n.tableContainer td {\n  padding: 8px;\n  text-align: left;\n  border: 1px solid #ccc;\n}\n\n.tableContainer th {\n  background-color: #f2f2f2;\n}\n\n.tableBodyContainer tr:nth-child(even) {\n  background-color: #f2f2f2;\n}\n\n.btn,\n.viewBtn {\n  outline: none;\n  border: 1px solid #333;\n  padding: 0.3rem 0.6rem;\n  border-radius: 0.2rem;\n  /* font-weight: bold; */\n  /* font-size: 1.2rem; */\n  text-transform: uppercase;\n  background-color: #98FB98;\n  color: #333;\n  cursor: pointer;\n}\n\n.viewBtn:hover {\n  background-color: #2b682b;\n  color: #fff;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"outerContainer": `TicketHistoryTable_outerContainer__jQBfg`,
	"tableTitle": `TicketHistoryTable_tableTitle__lMeoH`,
	"tableContainer": `TicketHistoryTable_tableContainer__zhSYr`,
	"tableBodyContainer": `TicketHistoryTable_tableBodyContainer__3vYSz`,
	"btn": `TicketHistoryTable_btn__q1M31`,
	"viewBtn": `TicketHistoryTable_viewBtn__PyNH0`
};
export default ___CSS_LOADER_EXPORT___;
