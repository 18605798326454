import React, { useState } from 'react';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import DeleteIcon from '@mui/icons-material/Delete';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

import BasicModal from '../components/Modal/Modal';

export default function MembersEditableTable({ tableData, editedFieldData, deleteMember }) {

  // const [userData, setUserData] = useState([]);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [userToEdit, setUserToEdit] = useState({});

  const handleEditedFieldData = (editedObj) => {
    editedFieldData(editedObj);
  };

  // useEffect(() => {
  //   setUserData(tableData);
  // }, [tableData]);


  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell align="right">Member id</TableCell>
              <TableCell align="right">Email</TableCell>
              <TableCell align="right">Password</TableCell>
              <TableCell align="right">Department</TableCell>
              <TableCell align="right">Phone</TableCell>
              <TableCell align="right">Joined</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData?.map((row) => (
              <TableRow
                key={row.name}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.username}
                </TableCell>
                <TableCell align="right">{row.userid}</TableCell>
                <TableCell align="right">{row.email}</TableCell>
                <TableCell align="right">{row?.user_password}</TableCell>
                <TableCell align="right">{row?.department}</TableCell>
                <TableCell align="right">{row.phoneno}</TableCell>
                <TableCell align="right">{row.date_joined.slice(0, 10)}</TableCell>

                <span style={{ cursor: "pointer", margin: "0px 10px" }}><EditOutlinedIcon onClick={(e) => { setOpenEditModal(true); setUserToEdit(row) }} /></span>
                <span style={{ cursor: "pointer", margin: "0px 10px" }}><DeleteIcon onClick={(e) => { e.stopPropagation(); deleteMember(row) }} color="error" /></span>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {
        openEditModal
        && <BasicModal
          userFields={userToEdit}
          isOpen={openEditModal}
          action={(val) => setOpenEditModal(val)}
          onValidate={handleEditedFieldData}
        />
      }
    </>
  );
}
