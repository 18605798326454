import React, { useEffect, useState } from "react";

import styles from "./AdminMobileOTPLoginForm.module.css";
import { useMutation } from "@tanstack/react-query";

import axiosConfig from "../../../axios/axiosConfig";
import toast from "react-hot-toast";
import ReactHotToaster from "../../../components/common/ReactHotToaster";
import axios from "axios";

const AdminMobileOTPLoginForm = ({ setAccessToken, validateAccessToken, setIsVerifyOTPApiLoading }) => {
  const [mobileNumber, setMobileNumber] = useState("");
  const [mobileOTP, setMobileOTP] = useState("");
  const [sessionID, setSessionID] = useState("");

  // FETCH MOBILE OTP POST REQUEST
  const getMobileOTP = useMutation({
    mutationFn: async () => {
      const res = await axiosConfig({
        method: "post",
        url: "enroll/user_register",
        data: {
          phoneno: mobileNumber,
          country: '91',
          referred_by: null,
        },
      });

      if (res.status === 200 || res.statusText === 'OK') {
        window.alert("OTP sent successfully to your mobile!");
      }
      return res.data;
    },
    onSuccess: (data) => {
      // console.log("mobile session id res: ", data)
      // setSessionID(data.Details);
    },
    onError: (error) => {
      toast.error(error?.response?.data?.response || "Something went wrong");
    }
  })

  const getOTP = () => {
    getMobileOTP.mutate();
  };

  const adminVerification = (session_id) => {
    axios.post('tnibroadmin/admin_access', {
      admin_access_token: session_id,
    })
      .then(response => {
        console.log("+++",response)
        setAccessToken(session_id);
        localStorage.setItem("admin_access_token", session_id);
        validateAccessToken(true);
        window.location.reload();
      })
      .catch(error => {
        console.error('Error:', error.response ? error.response.data : error.message);
        // Handle the error (e.g., display error message to the user)
      });
  };

  // VERIFY MOBILE OTP POST REQUEST
  const verifyMobileOTP = useMutation({
    mutationFn: async () => {
      const res = await axiosConfig({
        method: "post",
        url: "/enroll/verifyotp",
        data: {
          phoneno: mobileNumber,
          country: '91', // country dial code
          otp: mobileOTP,
          device_id: "",
          device_name: "",
          device_type: "",
        },
      });
      return res.data;
    },
    onSuccess: (data) => {
      console.log("data: ", data)
      adminVerification(data.access_token)
    },
    onError: (error) => {
      validateAccessToken(false);
      toast.error(error?.response?.data?.response || "Something went wrong");
    },
  });

  const verifyOTP = () => {
    verifyMobileOTP.mutate();
  };

  // useEffect for changing verifyOTP loading state
  useEffect(() => {
    setIsVerifyOTPApiLoading(verifyMobileOTP.isLoading)
  }, [verifyMobileOTP.isLoading])

  return (
    <div className={styles.mainContainer}>
      <div className={styles.formContainer}>
        <h2 className={styles.title}>Session Expired or Invalid</h2>
        <h3 className={styles.subtitle}>Please Login</h3>

        {/* mobiler otp login form */}
        <div className={styles.inputContainer}>
          <div className={styles.inputBox}>
            <label htmlFor="mobileNumber">Mobile Number: </label>
            <input
              required
              className={styles.mobileInput}
              type="text"
              placeholder="eg. 0123456789"
              maxLength="10"
              name="mobileNumber"
              id="mobileNumber"
              value={mobileNumber}
              onChange={(e) => setMobileNumber(e.target.value)}
            />
          </div>

          <div className={styles.buttonContainer}>
            <button
              disabled={mobileNumber.length < 10}
              className={styles.submitButton}
              onClick={getOTP}
            >
              GET OTP
            </button>
          </div>

          <div className={styles.inputBox}>
            <label htmlFor="mobileOTP">OTP: </label>
            <input
              required
              className={styles.mobileOTP}
              maxlength="6"
              type="text"
              name="mobileOTP"
              id="mobileOTP"
              onChange={(e) => setMobileOTP(e.target.value)}
            />
          </div>

          <div className={styles.buttonContainer}>
            <button
              disabled={mobileOTP.length < 6}
              className={styles.submitButton}
              onClick={verifyOTP}
            >
              VERIFY OTP
            </button>
          </div>
        </div>
      </div>

      {/* REACT HOT TOAST */}
      <ReactHotToaster />
    </div>
  );
};

export default AdminMobileOTPLoginForm;
