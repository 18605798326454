// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SinglePaymentDetails_SinglePaymentDetails__wCG1u {
  /* border: 1px solid red; */
  padding: 2rem;
}

.SinglePaymentDetails_logoContainer__7h33C {
  width: 100px;
  margin: 1rem 0;
}
.SinglePaymentDetails_logo__iKikS {
  width: 100%;
}

.SinglePaymentDetails_addressContainer__wU1R\\+ {
  /* border: 1px solid red; */
  margin: 1rem 0;
}

.SinglePaymentDetails_addressList__nVGRp {
  list-style: none;
  font-size: 0.9rem;
}

.SinglePaymentDetails_invoiceNumberContainer__DOIUa {
  margin: 1rem 0;
}

.SinglePaymentDetails_paymentDetails__cjhzE {
  /* border: 1px solid red; */
}

.SinglePaymentDetails_paymentDetails__cjhzE div {
  max-width: 400px;
  padding: 0.5rem 0;

  display: flex;
  justify-content: space-between;
}

.SinglePaymentDetails_dateContainer__rXhif {
  border-top: 1px solid black;
}
.SinglePaymentDetails_amount__58X5w {
  /* border-bottom: 1px solid black; */
}
.SinglePaymentDetails_total__SuYv1 {
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  font-weight: bold;
}

.SinglePaymentDetails_miscDetails__ckfWw {
  margin: 2rem 0;
}
.SinglePaymentDetails_miscDetails__ckfWw p {
  margin-bottom: 0.5rem;
}`, "",{"version":3,"sources":["webpack://./src/components/UserDashboard/Payments/SinglePaymentDetails/SinglePaymentDetails.module.css"],"names":[],"mappings":"AAAA;EACE,2BAA2B;EAC3B,aAAa;AACf;;AAEA;EACE,YAAY;EACZ,cAAc;AAChB;AACA;EACE,WAAW;AACb;;AAEA;EACE,2BAA2B;EAC3B,cAAc;AAChB;;AAEA;EACE,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;EACE,gBAAgB;EAChB,iBAAiB;;EAEjB,aAAa;EACb,8BAA8B;AAChC;;AAEA;EACE,2BAA2B;AAC7B;AACA;EACE,oCAAoC;AACtC;AACA;EACE,2BAA2B;EAC3B,8BAA8B;EAC9B,iBAAiB;AACnB;;AAEA;EACE,cAAc;AAChB;AACA;EACE,qBAAqB;AACvB","sourcesContent":[".SinglePaymentDetails {\n  /* border: 1px solid red; */\n  padding: 2rem;\n}\n\n.logoContainer {\n  width: 100px;\n  margin: 1rem 0;\n}\n.logo {\n  width: 100%;\n}\n\n.addressContainer {\n  /* border: 1px solid red; */\n  margin: 1rem 0;\n}\n\n.addressList {\n  list-style: none;\n  font-size: 0.9rem;\n}\n\n.invoiceNumberContainer {\n  margin: 1rem 0;\n}\n\n.paymentDetails {\n  /* border: 1px solid red; */\n}\n\n.paymentDetails div {\n  max-width: 400px;\n  padding: 0.5rem 0;\n\n  display: flex;\n  justify-content: space-between;\n}\n\n.dateContainer {\n  border-top: 1px solid black;\n}\n.amount {\n  /* border-bottom: 1px solid black; */\n}\n.total {\n  border-top: 1px solid black;\n  border-bottom: 1px solid black;\n  font-weight: bold;\n}\n\n.miscDetails {\n  margin: 2rem 0;\n}\n.miscDetails p {\n  margin-bottom: 0.5rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"SinglePaymentDetails": `SinglePaymentDetails_SinglePaymentDetails__wCG1u`,
	"logoContainer": `SinglePaymentDetails_logoContainer__7h33C`,
	"logo": `SinglePaymentDetails_logo__iKikS`,
	"addressContainer": `SinglePaymentDetails_addressContainer__wU1R+`,
	"addressList": `SinglePaymentDetails_addressList__nVGRp`,
	"invoiceNumberContainer": `SinglePaymentDetails_invoiceNumberContainer__DOIUa`,
	"paymentDetails": `SinglePaymentDetails_paymentDetails__cjhzE`,
	"dateContainer": `SinglePaymentDetails_dateContainer__rXhif`,
	"amount": `SinglePaymentDetails_amount__58X5w`,
	"total": `SinglePaymentDetails_total__SuYv1`,
	"miscDetails": `SinglePaymentDetails_miscDetails__ckfWw`
};
export default ___CSS_LOADER_EXPORT___;
