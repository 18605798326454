import React from "react";
import Switch from "@mui/material/Switch";
import { Tooltip } from "@mui/material";

const Toggle = ({ callback, checked, tooltipText = "" }) => {
  return (
    <Tooltip title={tooltipText}>
      <Switch
        checked={!checked}
        onChange={callback}
        inputProps={{ "aria-label": "controlled" }}
      />
    </Tooltip>
  );
};

export default Toggle;
