// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AdvisoryDetailsTable_AdvisoryDetailsTable__oZ5Mq {
  margin: 2rem;
}

.AdvisoryDetailsTable_tableContainer__kRA4Q {
  width: 100%;
  max-width: 1600px;
  border-collapse: collapse;
  border: 1px solid #ccc;
  /* background-color: red; */
}

.AdvisoryDetailsTable_tableContainer__kRA4Q th,
.AdvisoryDetailsTable_tableContainer__kRA4Q td {
  padding: 8px;
  text-align: left;
  border: 1px solid #ccc;
}

.AdvisoryDetailsTable_tableContainer__kRA4Q th {
  background-color: #f2f2f2;
}

.AdvisoryDetailsTable_tableBodyContainer__ETr1s tr:nth-child(even) {
  background-color: #f2f2f2;
}

.AdvisoryDetailsTable_tableBodyContainer__ETr1s tr.AdvisoryDetailsTable_statusClosed__bwzBA {
  /* background-color: #a9a9a9; */
  background-color: #e2e2e2;
  /* color: #fff; */
}

.AdvisoryDetailsTable_tableBodyContainer__ETr1s tr:hover {
  /* background-color: #e2e2e2; */
  background-color: #a9a9a9;
  color: #111;
}

.AdvisoryDetailsTable_colorCircleContainer__JEI7K {
  /* border: 1px solid red; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.AdvisoryDetailsTable_editBtn__aBJxH {
  padding: 0.2rem 0.6rem;
  text-transform: uppercase;
  cursor: pointer;
}
.AdvisoryDetailsTable_editBtn__aBJxH:disabled,
.AdvisoryDetailsTable_editBtn__aBJxH[disabled] {
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
  cursor: not-allowed;
}
`, "",{"version":3,"sources":["webpack://./src/components/AdvisorDashboard/AdvisorHome/AdvisoryDetailsTable/AdvisoryDetailsTable.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd;;AAEA;EACE,WAAW;EACX,iBAAiB;EACjB,yBAAyB;EACzB,sBAAsB;EACtB,2BAA2B;AAC7B;;AAEA;;EAEE,YAAY;EACZ,gBAAgB;EAChB,sBAAsB;AACxB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,+BAA+B;EAC/B,yBAAyB;EACzB,iBAAiB;AACnB;;AAEA;EACE,+BAA+B;EAC/B,yBAAyB;EACzB,WAAW;AACb;;AAEA;EACE,2BAA2B;EAC3B,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,sBAAsB;EACtB,yBAAyB;EACzB,eAAe;AACjB;AACA;;EAEE,yBAAyB;EACzB,yBAAyB;EACzB,cAAc;EACd,mBAAmB;AACrB","sourcesContent":[".AdvisoryDetailsTable {\n  margin: 2rem;\n}\n\n.tableContainer {\n  width: 100%;\n  max-width: 1600px;\n  border-collapse: collapse;\n  border: 1px solid #ccc;\n  /* background-color: red; */\n}\n\n.tableContainer th,\n.tableContainer td {\n  padding: 8px;\n  text-align: left;\n  border: 1px solid #ccc;\n}\n\n.tableContainer th {\n  background-color: #f2f2f2;\n}\n\n.tableBodyContainer tr:nth-child(even) {\n  background-color: #f2f2f2;\n}\n\n.tableBodyContainer tr.statusClosed {\n  /* background-color: #a9a9a9; */\n  background-color: #e2e2e2;\n  /* color: #fff; */\n}\n\n.tableBodyContainer tr:hover {\n  /* background-color: #e2e2e2; */\n  background-color: #a9a9a9;\n  color: #111;\n}\n\n.colorCircleContainer {\n  /* border: 1px solid red; */\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.editBtn {\n  padding: 0.2rem 0.6rem;\n  text-transform: uppercase;\n  cursor: pointer;\n}\n.editBtn:disabled,\n.editBtn[disabled] {\n  border: 1px solid #999999;\n  background-color: #cccccc;\n  color: #666666;\n  cursor: not-allowed;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"AdvisoryDetailsTable": `AdvisoryDetailsTable_AdvisoryDetailsTable__oZ5Mq`,
	"tableContainer": `AdvisoryDetailsTable_tableContainer__kRA4Q`,
	"tableBodyContainer": `AdvisoryDetailsTable_tableBodyContainer__ETr1s`,
	"statusClosed": `AdvisoryDetailsTable_statusClosed__bwzBA`,
	"colorCircleContainer": `AdvisoryDetailsTable_colorCircleContainer__JEI7K`,
	"editBtn": `AdvisoryDetailsTable_editBtn__aBJxH`
};
export default ___CSS_LOADER_EXPORT___;
