import React from "react";
import EditIcon from "@mui/icons-material/Edit";
import styles from "./TopUpsTable.module.css";
import ReactHotToaster from "../../../common/ReactHotToaster";
import ColorSignalCircle from "../../../common/ColorSignalCircle";

const TopUpsTable = ({ topUpsData = [], setOpenTopUpForm, setActiveTopUp }) => {
  // const [openSubscriptionForm, setOpenSubscriptionForm] = useState(false);

  return (
    <div className={styles.outerContainer}>
      <h1 className={styles.tableTitle}>List of Top Ups</h1>
      <table className={styles.tableContainer}>
        <thead>
          <tr>
            <th>Title</th>
            <th>Code</th>
            <th>Description</th>
            <th>Features</th>
            <th>Quantity</th>
            <th>Price</th>

            {/* <th>Created On</th> */}
            <th>Active</th>
            <th>Actions</th>
          </tr>
        </thead>

        <tbody className={styles.tableBodyContainer}>
          {topUpsData.map((item, index) => {
            // const createdDate = new Date(item.created_at).toDateString();

            return (
              <tr key={item.id}>
                <td>{item.title}</td>
                <td>{item.topup_code}</td>
                <td>{item.description}</td>
                <td>{item.topup_description}</td>
                <td>{item.quantity}</td>
                <td>{item.price}</td>
                {/* <td>{`${item.interval} ${item.interval_unit}`}</td> */}
                {/* <td>{createdDate}</td> */}
                <td>
                  {item.active ? (
                    <ColorSignalCircle bgColor="green" />
                  ) : (
                    <ColorSignalCircle bgColor="red" />
                  )}
                </td>
                <td>
                  <div className={styles.actionBtnContainer}>
                    <button
                      className={styles.btn}
                      onClick={() => {
                        setOpenTopUpForm(true);
                        setActiveTopUp(item);
                      }}
                    >
                      <EditIcon />
                    </button>
                    {/* <button onClick={() => {}} >delete</button> */}
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>

      {/* REACT HOT TOAST */}
      <ReactHotToaster />
    </div>
  );
};

export default TopUpsTable;
