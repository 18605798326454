import React, { useState } from "react";

import styles from "./TinyMCEContentTable.module.css";
import MuiModal from "../../../muiComps/MuiModal";
import TinyMCEEditor from "../TinyMCEEditor";
// import { useMutation } from "@tanstack/react-query";
import { axiosConfig } from "../../../../axios/axiosConfig";
import toast from "react-hot-toast";
import ReactHotToaster from "../../../common/ReactHotToaster";
import ColorSignalCircle from "../../../common/ColorSignalCircle";



const TinyMCEContentTable = ({ tableRowData, userID, setOpenTinyEditor, setPopupContentList, editorRef }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [fetchedContent, setfetchedContent] = useState({});
    
  // delete content handler
  const deleteContent = async (content_id) => {
    try {
      const res = await axiosConfig({
        method: "delete",
        url: `appdashboard/popup_message?id=${content_id}`,
        data: {},
      });

      if (res.status === 200 || res.statusText === 'OK') {
        toast.success("Item deleted successfully!");

        setPopupContentList(prevState => {
          return prevState.filter(item => item.id !== content_id);
        })
      }
    } catch (error) {
      toast.error(error?.response?.data?.response || "Something went wrong");
    }
  }

  return (
    <div className="container">
      <h1>Content Table</h1>
      <table className={styles.tableContainer}>
        <thead>
          <tr>
            <th>Title</th>
            <th>Screen</th>
            <th>Publish Date</th>
            <th>Status</th>
            <th>Actions</th>
          </tr>
        </thead>

        <tbody className={styles.tableBodyContainer}>
          {tableRowData.map((item, index) => {
            console.log("item: ", item)
            const formattedDate = new Date(item.created_at).toDateString();

            return (
              <tr key={item.id}>
                <td>{item.title}</td>
                <td>{item.screen_name}</td>
                <td>{formattedDate}</td>
                <td>{item.active ? <ColorSignalCircle bgColor="green" /> : <ColorSignalCircle bgColor="red" /> }</td>
                <td>
                  <div className={styles.actionBtnContainer}>
                    <button
                      onClick={() => {
                        setOpenTinyEditor(false);
                        setfetchedContent(item); // for sending this content to tinymCe editor
                        setIsEditing(true);
                      }}
                    >
                      view/edit
                    </button>
                    <button onClick={() => deleteContent(item.id)} >delete</button>
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>

      {/* TinyMCE Modal Popup */}
      <MuiModal open={isEditing} setOpen={setIsEditing}>
        <TinyMCEEditor
          selectedScreen={fetchedContent.screen_name}
          screenContent={fetchedContent.screen_content}
          activeContent={fetchedContent}
          userID={userID}
          crudType="edit"
          editorRef={editorRef}
          setOpen={setIsEditing}
          setPopupContentList={setPopupContentList}
        />
      </MuiModal>

      {/* REACT HOT TOAST */}
      <ReactHotToaster />
    </div>
  );
};

export default TinyMCEContentTable;




// 
