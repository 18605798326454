import React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
// import CloseIcon from "@mui/icons-material/Close";

export default function BasicTextFields({
  value,
  label,
  textFieldType,
  fieldKey,
  callback,
  multiline,
  fullwidth,
  onEnter,
  hasClear,
  autoFocus,
  onKeyDown,
  error,
  helperText,
}) {
  // const handleOnEnter = (e) => {
  //   if (onEnter) {
  //     if (e.keyCode === 13) {
  //       onEnter(e.target.value);
  //       e.preventDefault();
  //     }
  //   }
  // };

  return (
    <Box
      component="form"
      sx={{
        "& > :not(style)": { m: 0, width: fullwidth ? "100%" : "25ch" },
        display: "flex",
      }}
      noValidate
      autoComplete="off"
    >
      <TextField
        sx={{ backgroundColor: "#fff", borderRadius: 1 }}
        type={textFieldType}
        error={error}
        helperText={helperText}
        // id="outlined-size-small"
        label={label}
        size="small"
        multiline={multiline}
        fullWidth
        autoFocus={autoFocus}
        rows={4}
        variant="outlined"
        value={value}
        onChange={(event) => callback(fieldKey, event.target.value, event)}
        // onKeyDown={handleOnEnter}
        onKeyDown={onKeyDown}
      />
      {/* {hasClear
      && <CloseIcon sx={{margin: 0, width: "20px"}}/>} */}
    </Box>
  );
}
