// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .tableContainer th,
.tableBodyContainer td {
  margin: 0;
  padding: 0;
  border: 1px solid #ccc;
} */

/* module.css */

.TinyMCEContentTable_tableContainer__lyox9 {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #ccc;
}

.TinyMCEContentTable_tableContainer__lyox9 th,
.TinyMCEContentTable_tableContainer__lyox9 td {
  padding: 8px;
  text-align: left;
  border: 1px solid #ccc;
}

.TinyMCEContentTable_tableContainer__lyox9 th {
  background-color: #f2f2f2;
}

.TinyMCEContentTable_tableBodyContainer__8xW6E tr:nth-child(even) {
  background-color: #f2f2f2;
}

.TinyMCEContentTable_actionBtnContainer__gTgLK button {
  margin-right: 5px;
  padding: 5px 10px;
  gap: 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  cursor: pointer;
}

.TinyMCEContentTable_actionBtnContainer__gTgLK button:hover {
  background-color: #0056b3;
}
`, "",{"version":3,"sources":["webpack://./src/components/AdminDashboard/CreateMarqueeForm/TinyMCEContentTable/TinyMCEContentTable.module.css"],"names":[],"mappings":"AAAA;;;;;GAKG;;AAEH,eAAe;;AAEf;EACE,WAAW;EACX,yBAAyB;EACzB,sBAAsB;AACxB;;AAEA;;EAEE,YAAY;EACZ,gBAAgB;EAChB,sBAAsB;AACxB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,iBAAiB;EACjB,iBAAiB;EACjB,SAAS;EACT,yBAAyB;EACzB,WAAW;EACX,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":["/* .tableContainer th,\n.tableBodyContainer td {\n  margin: 0;\n  padding: 0;\n  border: 1px solid #ccc;\n} */\n\n/* module.css */\n\n.tableContainer {\n  width: 100%;\n  border-collapse: collapse;\n  border: 1px solid #ccc;\n}\n\n.tableContainer th,\n.tableContainer td {\n  padding: 8px;\n  text-align: left;\n  border: 1px solid #ccc;\n}\n\n.tableContainer th {\n  background-color: #f2f2f2;\n}\n\n.tableBodyContainer tr:nth-child(even) {\n  background-color: #f2f2f2;\n}\n\n.actionBtnContainer button {\n  margin-right: 5px;\n  padding: 5px 10px;\n  gap: 10px;\n  background-color: #007bff;\n  color: #fff;\n  border: none;\n  cursor: pointer;\n}\n\n.actionBtnContainer button:hover {\n  background-color: #0056b3;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tableContainer": `TinyMCEContentTable_tableContainer__lyox9`,
	"tableBodyContainer": `TinyMCEContentTable_tableBodyContainer__8xW6E`,
	"actionBtnContainer": `TinyMCEContentTable_actionBtnContainer__gTgLK`
};
export default ___CSS_LOADER_EXPORT___;
