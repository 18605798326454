// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.MainTable_tinyMCEContainer__dBCnz {
  max-width: 1300px;
  display: flex;
  flex-wrap: wrap;
  /* border: 1px solid red; */
}

.MainTable_tinyMCEEditor__omsDg {
  max-width: 800px;
}

.MainTable_tinyMCEPreviewContainer__auaCk {
  margin: 2rem;
  border: 1px solid #ccc;
  width: 400px;
  height: 600px;
}

.MainTable_tinyMCEPreviewContainer__auaCk h2 {
  margin: 1rem;
  text-align: center;
}

.MainTable_btnContainer__8d\\+-R {
  margin: 1rem;
  display: flex;
  gap: 1rem;
}
.MainTable_btnContainer__8d\\+-R button {
  outline: none;
  border: none;
  padding: 0.2rem 0.5rem;
  border-radius: 0.2rem;
  text-transform: uppercase;
  cursor: pointer;
}

.MainTable_clearBtn__zKzbk {
  background-color: orangered;
  color: #fff;
}
.MainTable_publishBtn__ShifT {
  background-color: blue;
  color: #fff;
}

.MainTable_clearBtn__zKzbk:hover {
  background-color: #111;
}
.MainTable_publishBtn__ShifT:hover {
  background-color: #111;
}`, "",{"version":3,"sources":["webpack://./src/components/AdminDashboard/HomeScreenContent/MainTable/MainTable.module.css"],"names":[],"mappings":";;AAEA;EACE,iBAAiB;EACjB,aAAa;EACb,eAAe;EACf,2BAA2B;AAC7B;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,YAAY;EACZ,sBAAsB;EACtB,YAAY;EACZ,aAAa;AACf;;AAEA;EACE,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,SAAS;AACX;AACA;EACE,aAAa;EACb,YAAY;EACZ,sBAAsB;EACtB,qBAAqB;EACrB,yBAAyB;EACzB,eAAe;AACjB;;AAEA;EACE,2BAA2B;EAC3B,WAAW;AACb;AACA;EACE,sBAAsB;EACtB,WAAW;AACb;;AAEA;EACE,sBAAsB;AACxB;AACA;EACE,sBAAsB;AACxB","sourcesContent":["\n\n.tinyMCEContainer {\n  max-width: 1300px;\n  display: flex;\n  flex-wrap: wrap;\n  /* border: 1px solid red; */\n}\n\n.tinyMCEEditor {\n  max-width: 800px;\n}\n\n.tinyMCEPreviewContainer {\n  margin: 2rem;\n  border: 1px solid #ccc;\n  width: 400px;\n  height: 600px;\n}\n\n.tinyMCEPreviewContainer h2 {\n  margin: 1rem;\n  text-align: center;\n}\n\n.btnContainer {\n  margin: 1rem;\n  display: flex;\n  gap: 1rem;\n}\n.btnContainer button {\n  outline: none;\n  border: none;\n  padding: 0.2rem 0.5rem;\n  border-radius: 0.2rem;\n  text-transform: uppercase;\n  cursor: pointer;\n}\n\n.clearBtn {\n  background-color: orangered;\n  color: #fff;\n}\n.publishBtn {\n  background-color: blue;\n  color: #fff;\n}\n\n.clearBtn:hover {\n  background-color: #111;\n}\n.publishBtn:hover {\n  background-color: #111;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tinyMCEContainer": `MainTable_tinyMCEContainer__dBCnz`,
	"tinyMCEEditor": `MainTable_tinyMCEEditor__omsDg`,
	"tinyMCEPreviewContainer": `MainTable_tinyMCEPreviewContainer__auaCk`,
	"btnContainer": `MainTable_btnContainer__8d+-R`,
	"clearBtn": `MainTable_clearBtn__zKzbk`,
	"publishBtn": `MainTable_publishBtn__ShifT`
};
export default ___CSS_LOADER_EXPORT___;
