import React, { useState } from "react";
import styles from "./HomeScreenContent.module.css";
import MainTable from "./MainTable";
import Gainers from "./Gainers";
import Losers from "./Losers";
import Trending from "./Trending";

import Banner from "./Banner";
import UserCallback from "./UserCallback";

const tabsArray = [
  {
    id: 1,
    title: "Banner",
    value: "banner",
  },
  {
    id: 2,
    title: "Main Table",
    value: "main-table",
  },
  {
    id: 3,
    title: "Callback",
    value: "callback",
  },
  // {
  //   id: 4,
  //   title: "gainers",
  //   value: "gainers",
  // },
  // {
  //   id: 5,
  //   title: "Losers",
  //   value: "losers",
  // },
  // {
  //   id: 6,
  //   title: "Trending",
  //   value: "trending",
  // },
];

const HomeScreenContent = () => {
  const [activeTab, setActiveTab] = useState("banner");

  const handleTabClick = (tabValue) => {
    setActiveTab(tabValue);
  };

  return (
    <div style={{ margin: "15px" }}>
      <div style={{ margin: "15px", marginLeft: "30px" }}>
        <h2>Home Screen Content</h2>
      </div>

      {/* TABS CONTAINER */}
      <div
        style={{ margin: "15px", marginLeft: "30px" }}
        className={styles.tabsContainer}
      >
        {tabsArray.map((tab) => {
          return (
            <HomeTab
              key={tab.id}
              tabInfo={tab}
              handleTabClick={handleTabClick}
            />
          );
        })}
      </div>

      {/* MAIN TAB CONTENT DISPLAY */}
      <div>
        {activeTab === "main-table" && <MainTable />}
        {activeTab === "banner" && <Banner />}
        {activeTab === "callback" && <UserCallback />}
        {activeTab === "gainers" && <Gainers />}
        {activeTab === "losers" && <Losers />}
        {activeTab === "trending" && <Trending />}
      </div>
    </div>
  );
};

export default HomeScreenContent;

const HomeTab = ({ tabInfo, handleTabClick }) => {
  return (
    <div
      className={styles.tabItem}
      onClick={() => handleTabClick(tabInfo.value)}
    >
      {tabInfo.title}
    </div>
  );
};
