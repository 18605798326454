/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import Button from "../Button/ScreenerQueryButtons";
import AutoComplete from "../autoComplete/AutoComplete";
import Toggle from "../toggle/Toggle";
import TextFields from "../TextField/TextField";

import {
  optionsShowingInputField,
  optionsShowingMultipleFields,
  timeFrameShowingIntraday,
  indicatorWithoutLatestTextField,
  indicatorsWithGapBetweenOerator,
} from "../../utils/Macd";

import {
  measureRootOptions,
  offsetOptions,
  operatorOptions,
  intraDayTimeFrame,
  patternsList,
} from "../../utils/screener-dropdown-options";

import DuplicateIcon from "../../assets/duplicate.png";
import copyIcon from "../../assets/copy.png";
import closeIcon from "../../assets/close.png";
import EmaFields from "./EmaFields";
import NtimeFrameModal from "./NtimeFrameModal";
import { Box, TextField } from "@mui/material";
import ReactHotToaster from "../common/ReactHotToaster";

const NewScan = ({
  handleFormData,
  screenerPayload_DataObj,
  handleTextField,
  handleDuplicateData,
  handleDeleteForm,
  id,
  handleEmaFields,
  handleIntradayTimeframe,
  handleComparisonGapbetween,
  segment,
  setDisplayFilterData,
}) => {
  const [screenerFormData, setScreenerFormData] = useState(
    screenerPayload_DataObj
  );
  const [disabled, setDisabled] = useState(false);

  const handleDuplicate = () => {
    handleDuplicateData(id);
  };

  const handleDisable = () => {
    setDisabled((value) => !value);
  };

  const handleDelete = () => {
    handleDeleteForm(id);
  };

  const handleOperatorSign = () => {
    let operatorSign;

    switch (screenerFormData.comparison_operator?.operator) {
      case "equal":
        operatorSign = "=";
        break;
      case "less_than":
        operatorSign = "<";
        break;
      case "greater_than":
        operatorSign = ">";
        break;
      case "gap_between":
        operatorSign = "gap-between";
        break;
      default:
        operatorSign = "";
    }
    return operatorSign;
  };

  const handleCopy = () => {
    const copyIndicator1 =
      screenerFormData.measure1?.indicator || screenerFormData.measure1;
    const copyIndicator2 =
      screenerFormData.measure2?.indicator || screenerFormData.measure2;

    const operator = handleOperatorSign();

    const copyTimeFrame1 = screenerFormData.timeframe1?.timeframe_offset;
    const copyTimeFrame2 = screenerFormData.timeframe2?.timeframe_offset;

    const copyIndicator1Ndays = screenerFormData.measure1?.ndays;
    const copyIndicator2Ndays = screenerFormData.measure2?.ndays;

    const screenerCopyToClipboard = `${copyTimeFrame1} ${copyIndicator1}${
      copyIndicator1Ndays ? ` (${copyIndicator1Ndays})` : ""
    } ${operator} ${copyTimeFrame2} ${copyIndicator2}${
      copyIndicator2Ndays ? ` (${copyIndicator2Ndays})` : ""
    })`;

    // const toastOptions = { position: "top-right" };

    screenerCopyToClipboard
      ? toast.success("Copied!")
      : toast.error("Nothing to copy!");

    navigator.clipboard.writeText(screenerCopyToClipboard);
  };

  useEffect(() => {
    handleFormData(disabled, "disabled", id);
  }, [disabled]);

  useEffect(() => {
    setScreenerFormData(screenerPayload_DataObj);
  }, [screenerPayload_DataObj]);

  // FILTERING OFFSET OPTIONS, if SEGMENT is 'crypto', then '10-min' interval is removed;
  const filterOffsetOptions = () => {
    if (segment === "crypto") {
      return offsetOptions.filter((option) => option.value !== "10-min");
    }

    return offsetOptions;
  };

  const filteredOffsetOptions = filterOffsetOptions();

  // HANDLE CHANGE FUNCTION FOR GAP BETWEEN, ADDED TEXTFIELD
  const gapBetweenHandleChange = (e) => {
    const inputString = e.target.value.trim();

    // Error checking
    if (!inputString.includes("-") || inputString.length > 5) {
      return;
    }
    const strArray = inputString.split("-");

    setScreenerFormData((prev) => {
      return {
        ...prev,
        comparison_operator: { ...prev.comparison_operator, range: strArray },
      };
    });
  };

  // set the displayfilterdata array whenever screenerFormData changes.
  useEffect(() => {
    setDisplayFilterData((prev) => {
      const newDisplayFilterData = prev.map((obj, index) => {
        if (index === id) {
          return screenerFormData;
        }
        return obj;
      });

      return newDisplayFilterData;
    });
  }, [screenerFormData]);

  return (
    <div
      style={{
        display: "flex",
        margin: "10px",
        borderRadius: "5px",
        width: "90%",
        justifyContent: "space-between",
        padding: "10px",
      }}
    >
      <div
        style={{
          display: "flex",
          // flexWrap: 'wrap',
          gap: "0.5rem",
        }}
      >
        {/* Intraday timing List (15 min, 1hour, etc.) */}
        {timeFrameShowingIntraday.indexOf(
          screenerFormData.timeframe1?.timeframe_offset
        ) !== -1 && (
          <AutoComplete
            handleDropdownValue={(value, key) =>
              handleIntradayTimeframe(value, key, id)
            }
            options={intraDayTimeFrame}
            label="Select a Intraday-offset"
            value="timeframe1"
            currentValue={screenerFormData.timeframe1.intraday}
            disabled={disabled}
            defaultValue="0"
          />
        )}

        {/* SELECT AN OFFSET, ie daily, weekly, monthly candles */}
        {/* indicatorWithoutLatestTextField = ["number"]; */}
        {screenerFormData.measure1 &&
          indicatorWithoutLatestTextField.indexOf(
            screenerFormData.measure1.indicator
          ) === -1 && (
            <AutoComplete
              handleDropdownValue={(value, key) =>
                handleFormData(value, key, id)
              }
              options={filteredOffsetOptions}
              // options={offsetOptions}
              label="Select an offset"
              value="timeframe1"
              currentValue={
                screenerFormData.timeframe1?.timeframe_offset ||
                screenerFormData.timeframe1
              }
              disabled={disabled}
              defaultValue="latest"
              textFieldWidth={180}
            />
          )}

        {/* SELECT MEASURE 1 */}
        {/* handleDropdownValue: 1st arg is option selected like 'max', 'min', 'high', 'low', etc.  */}
        {/* handleDropdownValue: 2nd argument is the type of comp, e.g. 'measure1', 'timeframe1'  */}
        <AutoComplete
          handleDropdownValue={(selectedOption, type) =>
            handleFormData(selectedOption, type, id)
          }
          options={measureRootOptions}
          label="Select a measure"
          value="measure1"
          autoopen
          currentValue={
            screenerFormData.measure1?.indicator ?? screenerFormData.measure1
          }
          disabled={disabled}
          textFieldWidth={180}
        />

        {/* if 'ema' is selected under measure autocomplete, the below component(1 mui autocomplete, 1 textfield) become visible */}
        {optionsShowingMultipleFields.indexOf(
          screenerFormData.measure1?.indicator
        ) !== -1 && (
          <div style={{ margin: "0px 10px" }}>
            <EmaFields
              handleEmaFieldValues={(emamsr, emadays) =>
                handleEmaFields(
                  emamsr,
                  emadays,
                  screenerFormData.measure1?.indicator,
                  id,
                  "measure1"
                )
              }
              values={screenerFormData["measure1"]}
            />
          </div>
        )}

        {/* TEXTFIELD INPUT FOR MEASURE 1 */}
        {optionsShowingInputField.indexOf(
          screenerFormData.measure1?.indicator
        ) !== -1 && (
          <TextFields
            handleTextFieldValue={(value) =>
              handleTextField(
                value,
                screenerFormData.measure1?.indicator,
                id,
                "measure1"
              )
            }
            value={screenerFormData["measure1"].ndays}
            defaultValue={
              measureRootOptions.find(
                (option) =>
                  option.value === screenerFormData.measure1?.indicator
              ).defaultValue
            }
            onChangeApplyDefaultValue={screenerFormData.measure1?.indicator}
            // optionsShowingInputField={optionsShowingInputField}
          />
        )}

        {/* SELECT AN OPERATION AUTOCOMPLETE */}
        {(screenerFormData.measure2 || screenerFormData.measure1) &&
          screenerFormData.measure1 !== "pattern" && (
            <AutoComplete
              handleDropdownValue={(value, key) =>
                handleFormData(value, key, id)
              }
              options={
                indicatorsWithGapBetweenOerator.indexOf(
                  screenerFormData.measure1.indicator
                ) !== -1
                  ? [
                      ...operatorOptions,
                      {
                        label: "Gap between",
                        value: "gap_between",
                      },
                    ]
                  : operatorOptions
              }
              label="Select an operation"
              value="comparison_operator"
              autoopen
              currentValue={screenerFormData.comparison_operator?.operator}
              disabled={disabled}
              textFieldColor="rgb(153,27,27)"
              textFieldWidth={200}
            />
          )}

        {/* IMPORTANT: PATTERN IS UNDER COMPARISON_OPERATOR IN PAYLOAD */}
        {/* PATTERNS LIST AUTOCOMPLETE */}
        {screenerFormData.measure1 === "pattern" && (
          <AutoComplete
            handleDropdownValue={(value, key) => handleFormData(value, key, id)}
            options={patternsList}
            label="Select a Pattern"
            // value="comparison_operator"
            value="comparison_operator"
            autoopen
            currentValue={screenerFormData.comparison_operator?.operator}
            disabled={disabled}
            textFieldColor="rgb(153,27,27)"
          />
        )}

        {screenerFormData.comparison_operator?.operator === "gap_between" && (
          <Box
            sx={{
              "& > :not(style)": { m: 1, width: "25ch" },
            }}
            noValidate
            autoComplete="off"
          >
            <TextField
              id="outlined-basic"
              label="Select Range"
              variant="outlined"
              helperText="Format: 3-15, 3-20"
              // type="number"
              inputProps={{
                inputMode: "numeric",
                pattern: "[0-9]*",
              }}
              onChange={gapBetweenHandleChange}
            />
          </Box>
          // <AutoComplete
          //   handleDropdownValue={(value, key) =>
          //     handleComparisonGapbetween(value, key, id)
          //   }
          //   options={[
          //     { label: "3-15", value: "3-15" },
          //     { label: "3-20", value: "3-20" },
          //     { label: "3-25", value: "3-25" },
          //   ]}
          //   label="Select Range"
          //   value="comparison_operator"
          //   currentValue={screenerFormData.comparison_operator?.range?.join(
          //     "-"
          //   )}
          //   disabled={disabled}
          // />
        )}

        {timeFrameShowingIntraday.indexOf(
          screenerFormData.timeframe2?.timeframe_offset
        ) !== -1 && (
          <AutoComplete
            handleDropdownValue={(value, key) =>
              handleIntradayTimeframe(value, key, id)
            }
            options={intraDayTimeFrame}
            label="Select a Intraday-offset"
            value="timeframe2"
            currentValue={screenerFormData.timeframe2.intraday}
            disabled={disabled}
            defaultValue="0"
          />
        )}

        {/* ALL INDICATORS / OPTIONS without 'LATEST' FIELD */}
        {screenerFormData.measure2 &&
          indicatorWithoutLatestTextField.indexOf(
            screenerFormData.measure2.indicator
          ) === -1 && (
            <AutoComplete
              handleDropdownValue={(value, key) =>
                handleFormData(value, key, id)
              }
              options={filteredOffsetOptions}
              // options={offsetOptions}
              label="Select an offset"
              value="timeframe2"
              currentValue={
                screenerFormData.timeframe2?.timeframe_offset ||
                screenerFormData.timeframe2
              }
              disabled={disabled}
              defaultValue="latest"
              textFieldWidth={180}
            />
          )}

        {/* SELECT A MEASURE 2 */}
        {/* IF PATTERN IS SELECTED, DONT OPEN MEASURE 2 AUTOCOMPLETE LIST */}
        {screenerFormData.comparison_operator &&
          screenerFormData.measure1 !== "pattern" && (
            <AutoComplete
              handleDropdownValue={(value, key) =>
                handleFormData(value, key, id)
              }
              options={measureRootOptions}
              label="Select a measure"
              value="measure2"
              autoopen
              currentValue={
                screenerFormData.measure2?.indicator ??
                screenerFormData.measure2
              }
              disabled={disabled}
              textFieldWidth={180}
            />
          )}

        {/* ALL OPTIONS/INDICATORS WITH MULTIPLE INPUT FILES, eg. "ema": 1 Autocomplete & Number Input */}
        {optionsShowingMultipleFields.indexOf(
          screenerFormData.measure2?.indicator
        ) !== -1 && (
          <div style={{ margin: "0px 10px" }}>
            <EmaFields
              handleEmaFieldValues={(emamsr, emadays) =>
                handleEmaFields(
                  emamsr,
                  emadays,
                  screenerFormData.measure2?.indicator,
                  id,
                  "measure2"
                )
              }
              values={screenerFormData["measure2"]}
            />
          </div>
        )}

        {/* OPTIONS/INDICATORS WITH A SINGLE INPUT FIELD */}
        {optionsShowingInputField.indexOf(
          screenerFormData.measure2?.indicator
        ) !== -1 && (
          <TextFields
            handleTextFieldValue={(value) =>
              handleTextField(
                value,
                screenerFormData.measure2?.indicator,
                id,
                "measure2"
              )
            }
            value={screenerFormData["measure2"].ndays}
            defaultValue={
              measureRootOptions.find(
                (option) =>
                  option.value === screenerFormData.measure2?.indicator
              )?.defaultValue
            }
            onChangeApplyDefaultValue={screenerFormData.measure2?.indicator}
            // optionsShowingInputField={optionsShowingInputField}
          />
        )}

        {screenerFormData.timeframe1?.timeframe_offset.split("-")[0] ===
          "n" && (
          <NtimeFrameModal
            isOpen={
              screenerFormData.timeframe1?.timeframe_offset.split("-")[0] ===
              "n"
                ? true
                : false
            }
            handleValidate={(value, key) =>
              handleFormData(value, "n-timeframe1", id)
            }
            onChangeApplyDefaultValue={
              screenerFormData.timeframe1?.timeframe_offset
            }
          />
        )}

        {screenerFormData.timeframe2?.timeframe_offset.split("-")[0] ===
          "n" && (
          <NtimeFrameModal
            isOpen={
              screenerFormData.timeframe2?.timeframe_offset.split("-")[0] ===
              "n"
                ? true
                : false
            }
            handleValidate={(value, key) =>
              handleFormData(value, "n-timeframe2", id)
            }
            onChangeApplyDefaultValue={
              screenerFormData.timeframe2?.timeframe_offset
            }
          />
        )}

        {/* COMPARISON OPERATOR 2 AND TEXT FIELD VISIBLE WHEN OFFSET 2 IS 'SINCE' */}
        {screenerFormData?.timeframe2?.timeframe_offset === "since" && (
          <>
            <AutoComplete
              handleDropdownValue={(value, key) =>
                handleFormData(value, key, id)
              }
              options={
                indicatorsWithGapBetweenOerator.indexOf(
                  screenerFormData.measure2.indicator
                ) !== -1
                  ? [
                      ...operatorOptions,
                      {
                        label: "Gap between",
                        value: "gap_between",
                      },
                    ]
                  : operatorOptions
              }
              label="Select an operation"
              value="comparison_operator2"
              autoopen
              currentValue={screenerFormData.comparison_operator2?.operator}
              disabled={disabled}
              textFieldColor="rgb(153,27,27)"
              // textFieldWidth={120}
            />

            <TextFields
              handleTextFieldValue={(value) =>
                handleTextField(
                  value,
                  screenerFormData.measure2?.indicator,
                  id,
                  "measure2"
                )
              }
              value={screenerFormData["measure2"].indicator_value}
              defaultValue="666"
              // defaultValue={
              //   measureRootOptions.find(
              //     (option) =>
              //       option.value === screenerFormData.measure2?.indicator
              //   ).defaultValue
              // }
              onChangeApplyDefaultValue={screenerFormData.measure2?.indicator}
            />
          </>
        )}
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Button
          tooltipText="Copy query to clipboard"
          callback={() => handleCopy()}
          title={copyIcon}
        />
        <Button
          tooltipText="Make duplicate query below"
          callback={() => handleDuplicate()}
          title={DuplicateIcon}
        />
        <Toggle
          tooltipText="Disable this query"
          callback={() => handleDisable()}
          checked={disabled}
        />
        <Button
          tooltipText="Delete query"
          callback={() => handleDelete()}
          title={closeIcon}
        />
      </div>

      {/* REACT TOAST */}
      <ReactHotToaster />
    </div>
  );
};

export default NewScan;
