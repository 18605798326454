// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.HtmlSelectInputFilter_number_Input_Control__Y6y-n,
.HtmlSelectInputFilter_select_Input_Control__ogGsM,
.HtmlSelectInputFilter_html_btn__9UFZ\\+,
.HtmlSelectInputFilter_previous_pattern_select_Input__eNRox {
  text-align: center;
  /* height: 20px; */
  width: 40x;
  border-radius: 4px;
  outline: none;
  /* border: none; */
  border: 1px solid #ccc;
  padding: 2px;
}

.HtmlSelectInputFilter_html_btn__9UFZ\\+ {
  cursor: pointer;
  /* background-color: red; */
  color: white;
}

.HtmlSelectInputFilter_number_Input_Control__Y6y-n {
  padding: 0;
  width: 35px;
  font-size: 14px;
  /* border: 1px solid; */
}`, "",{"version":3,"sources":["webpack://./src/components/Tables/HtmlSelectInputFilter/HtmlSelectInputFilter.module.css"],"names":[],"mappings":"AAAA;;;;EAIE,kBAAkB;EAClB,kBAAkB;EAClB,UAAU;EACV,kBAAkB;EAClB,aAAa;EACb,kBAAkB;EAClB,sBAAsB;EACtB,YAAY;AACd;;AAEA;EACE,eAAe;EACf,2BAA2B;EAC3B,YAAY;AACd;;AAEA;EACE,UAAU;EACV,WAAW;EACX,eAAe;EACf,uBAAuB;AACzB","sourcesContent":[".number_Input_Control,\n.select_Input_Control,\n.html_btn,\n.previous_pattern_select_Input {\n  text-align: center;\n  /* height: 20px; */\n  width: 40x;\n  border-radius: 4px;\n  outline: none;\n  /* border: none; */\n  border: 1px solid #ccc;\n  padding: 2px;\n}\n\n.html_btn {\n  cursor: pointer;\n  /* background-color: red; */\n  color: white;\n}\n\n.number_Input_Control {\n  padding: 0;\n  width: 35px;\n  font-size: 14px;\n  /* border: 1px solid; */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"number_Input_Control": `HtmlSelectInputFilter_number_Input_Control__Y6y-n`,
	"select_Input_Control": `HtmlSelectInputFilter_select_Input_Control__ogGsM`,
	"html_btn": `HtmlSelectInputFilter_html_btn__9UFZ+`,
	"previous_pattern_select_Input": `HtmlSelectInputFilter_previous_pattern_select_Input__eNRox`
};
export default ___CSS_LOADER_EXPORT___;
