import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import CommonWatchlistForm from "../CommonWatchlistForm";

import axiosConfig from "../../../axios/axiosConfig";
import { Box, Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { toast } from "react-hot-toast";
import ReactHotToaster from "../../common/ReactHotToaster";
import { fetchWatchlist } from "../watchlist_utils";

const EditWatchlistForm = () => {
  const {
    user: { userDetails },
  } = useSelector((store) => store.auth);
  const USERID = userDetails.id;

  const { watchlistId } = useParams();
  const navigate = useNavigate();
  // const location = useLocation();
  // const { name, description } = location?.state?.userWatchlistDetails;

  const [dashboardName, setDashboardName] = useState("");
  const [dashboardDesc, setDashboardDesc] = useState("");

  //  FETCHING WATCHLIST DETAILS
  const userWatchlistQuery = useQuery({
    queryKey: ["user-single-watchlist", watchlistId],
    queryFn: () => fetchWatchlist(watchlistId, USERID),
    onSuccess: (data) => {
      // setUserWatchlist(data.watchlist_data);
      setDashboardName(data.watchlist_data.name);
      setDashboardDesc(data.watchlist_data.description);
    },
    onError: (error) => {
      toast.error(error.response.data.response || "Something went wrong");
    },
  });

  const updateWatchlist = async () => {
    try {
      const res = await axiosConfig({
        method: "patch",
        url: `/watchlist/userwatchlist?watchlist_id=${watchlistId}&edit=true`,
        data: {
          name: dashboardName,
          description: dashboardDesc,
        },
      });

      if (res.status === 200 || res.statusText === "OK") {
        const watchlistId = res.data.response.id;
        navigate(`/watchlist/${watchlistId}`);
      }
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.response || "Something went wrong");
    }
  };

  return (
    <Box sx={{ p: 4 }}>
      <Typography variant="h5" sx={{ mb: 2 }}>
        Edit watchlist name and description
      </Typography>

      <CommonWatchlistForm
        dashboardName={dashboardName}
        setDashboardName={setDashboardName}
        dashboardDesc={dashboardDesc}
        setDashboardDesc={setDashboardDesc}
        onClickHandler={updateWatchlist}
        // userWatchlistDetails={location?.state?.userWatchlistDetails}
      />

      {/* REACT HOT TOAST */}
      <ReactHotToaster />
    </Box>
  );
};

export default EditWatchlistForm;
