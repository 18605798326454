import { useQuery } from "@tanstack/react-query";
import toast from "react-hot-toast";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { formatDate, getUserPayments } from "../utils";
import MuiLinearProgressLoader from "../../muiComps/MuiLinearProgressLoader";
// import MuiDenseTable from "../../muiComps/MuiDenseTable";
import { Box, Typography } from "@mui/material";
import ReactHotToaster from "../../common/ReactHotToaster";
// import { razorpayCheckout } from "../../../utils/razorpay/newRazorpayUtils";
import PaymentHistoryTable from "./PaymentHistoryTable";

const columns = [
  { id: "payment_id", label: "Payment ID", minWidth: 50 },
  { id: "amount", label: "Amount", minWidth: 50 },
  // {
  //   id: "plan_type",
  //   label: "Plan Type",
  //   // minWidth: 80,
  //   align: "right",
  //   // format: (value) => value.toLocaleString('en-US'),
  // },
  {
    id: "payment_date",
    label: "Payment Date",
    // minWidth: 100,
    align: "right",
    // format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: "payment_status",
    label: "Payment Status",
    // minWidth: 80,
    align: "right",
    // format: (value) => value.toFixed(2),
  },
  {
    id: "payment_mode",
    label: "Payment Mode",
    minWidth: 80,
    align: "center",
  },
];

const UserPayments = () => {
  const {
    user: { userDetails },
  } = useSelector((store) => store.auth);

  const [userPaymentData, setUserPaymentData] = useState([]);
  const rows = userPaymentData?.map((item) => {
    return {
      id: item.id,
      payment_id: item.payment_id,
      amount: item.amount,
      product: item.product,
      payment_date: formatDate(item.payment_date),
      payment_status: item.payment_status,
      payment_mode: item.payment_mode,
      // plan_type: item.plan_type,
    };
  });

  // console.log("userPaymentData: ", userPaymentData)

  // FETCH USER Payments (all transactions) USING useQUERY
  const userPaymentsQuery = useQuery({
    queryKey: ["user-payments"],
    queryFn: () => getUserPayments(userDetails.id),
    onSuccess: (data) => {
      setUserPaymentData(data.response);
    },
    onError: (error) => {
      toast.error(error?.response?.data?.response || "Something went wrong");
    },
  });

  return (
    <Box sx={{ m: 4 }}>
      {/* <h2>My Payments and Transactions</h2> */}
      <Typography variant="h4" sx={{ mt: 8, mb: 4 }}>
        My Payments and Transactions
      </Typography>

      {/* USER PAYMENTS TABLE */}
      {userPaymentsQuery.isLoading ? (
        <MuiLinearProgressLoader />
      ) : (
        // <MuiDenseTable rowData={rows} columnHeadings={columns} />
        <PaymentHistoryTable tableRowData={rows} />
      )}

      {/* REACT TOAST */}
      <ReactHotToaster />
    </Box>
  );
};

export default UserPayments;
